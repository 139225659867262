import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JobCollectingDataSpinner } from 'src/components/shared/components/JobState/Tags/JobCollectingDataSpinner/JobCollectingDataSpinner';
import PermissionsCheck from 'src/components/shared/components/Permissions/Permissions';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import FlashIcon from 'src/themes/icons/FlashIcon';
import { useRetrainMultipleJobs } from 'src/data-access/job/useRetrainMultipleJobs';
import { permissions } from 'src/components/shared/static/ComponentsPermissions';
import { EJobTrainingState } from 'src/components/shared/models/job/EJobTrainingState';
import { fetchJobInfo } from 'src/data-access';
import { getJsonConvert } from 'src/components/shared/utilities/json-convert';
import { Stack, Button } from '@mui/material';

type RetrainButtonUnifiedType = {
  jobInfos: JobInfoResponse[];
};

function RetrainButtonUnified({ jobInfos }: RetrainButtonUnifiedType) {
  const { t } = useTranslation(['retrain', 'feedback']);
  const jobIds = jobInfos.map((jobInfo: JobInfoResponse) => jobInfo.info?.id).filter((jobId) => !!jobId) as number[];
  const jobRetrainMutation = useRetrainMultipleJobs();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [checkNumber, setCheckNumber] = useState<number>(0);
  const [internalJobInfos, setInternalJobInfos] = useState<JobInfoResponse[]>([]);
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const refetchJobs = useCallback(() => {
    Promise.all(jobIds.map(async (id) => await fetchJobInfo(id, jsonConvert))).then((jInfos) => {
      setInternalJobInfos(jInfos);
      setCheckNumber((newValue) => newValue + 1);
    });
  }, [jobIds, jsonConvert]);

  const recheckRetrainingStatus = useCallback(() => {
    const jobsToCheck = internalJobInfos.length == 0 ? jobInfos : internalJobInfos;
    const inProgressJobs = jobsToCheck.filter(
      (jobInfo: JobInfoResponse) =>
        jobInfo.info?.status?.retrain_state == EJobTrainingState.STARTED.toString() ||
        jobInfo.info?.status?.buckets_model_update_in_progress
    );
    if (inProgressJobs.length > 0) {
      // Redo check if at least one job is still in progress
      setInProgress(true);
      setTimeout(() => {
        refetchJobs();
      }, 3000);
    } else {
      setInProgress(false);
    }
  }, [jobInfos, internalJobInfos, refetchJobs]);

  useEffect(() => {
    recheckRetrainingStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkNumber]);

  const handleRetrainJob = useCallback(() => {
    setInProgress(true);
    jobRetrainMutation
      .mutateAsync(jobIds)
      .catch(() => {
        setInProgress(false);
      })
      .finally(() => {
        refetchJobs();
      });
  }, [jobIds, jobRetrainMutation, setInProgress, refetchJobs]);

  const retrainButtonClassNames = useMemo(() => {
    if (inProgress) {
      return 'retrainButtonProgress';
    }
    return 'retrainButton';
  }, [inProgress]);

  const retrainButtonIcon = useMemo(() => {
    if (inProgress) {
      return <JobCollectingDataSpinner />;
    }
    return <FlashIcon fill="var(--utilitarian-color-orange-600)" style={{ color: 'var(--utilitarian-color-orange-600)' }} />;
  }, [inProgress]);

  return (
    <span>
      <PermissionsCheck permissions={[permissions.jobAction]} action={permissions.action.disabledAction}>
        <Stack>
          <Button
            className={retrainButtonClassNames}
            variant="tertiary"
            size="small"
            onClick={handleRetrainJob}
            disabled={inProgress}
            endIcon={retrainButtonIcon}
            data-test-id="retrain-button"
          >
            {t('feedback:trainSelected')}
          </Button>
        </Stack>
      </PermissionsCheck>
    </span>
  );
}

export default RetrainButtonUnified;

import { ArcannaTable } from '../../../../../shared/components';
import EmptyTableState from './EmptyTableState';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

export type DecisionPointsTableProps = {
  loadingTable: boolean;
  // eslint-disable-next-line
  tableData: any;
  // eslint-disable-next-line
  columns: any;
};
export default function DecisionPointsTable({ loadingTable, tableData, columns }: DecisionPointsTableProps) {
  const { t } = useTranslation(['common']);
  if (loadingTable) {
    return (
      <Spin>
        <EmptyTableState title={t('noCustomFieldsTitle')} subtitle={t('noCustomFieldsSubtitle')} />
      </Spin>
    );
  }

  return (
    <>
      {tableData?.length ? (
        <ArcannaTable
          showSorterTooltip={false}
          dataSource={tableData}
          columns={columns}
          pagination={false}
          bordered={false}
          arcannaSize={'small'}
        />
      ) : (
        <EmptyTableState title={t('noDecisionPointsTitle')} subtitle={t('noDecisionPointsSubtitle')} />
      )}
    </>
  );
}

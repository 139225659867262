import { Stack, Tooltip, useTheme } from '@mui/material';
import { EIcon, Icon } from '@arcanna/generic';
import { useTranslation } from 'react-i18next';

function EmptyStringTooltip() {
  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <Tooltip title={t('emptyStringTooltip')} placement="left" arrow>
      <Stack
        component="span"
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={0.5}
        color={theme.palette.secondary[400]}
      >
        <span>&quot;&quot;</span>
        <Icon name={EIcon.AlertTriangle} fontSize="small" />
      </Stack>
    </Tooltip>
  );
}

export default EmptyStringTooltip;

import { Box, Button, DrawerProps, Typography, useTheme } from '@mui/material';
import StyledRightDrawer from './StyledDrawer.styles';
import { ReactElement, isValidElement } from 'react';
import { Icon, EIcon } from '@arcanna/generic';

type TRightDrawerProps = DrawerProps & {
  header: ReactElement | string;
  width?: number | string;
  type?: 'dark' | 'light';
};

function Drawer({ children, header, width = '60vw', type = 'dark', hideBackdrop = true, ...otherProps }: TRightDrawerProps) {
  const theme = useTheme();

  return (
    <StyledRightDrawer hideBackdrop={hideBackdrop} type={type} {...otherProps} width={width}>
      <Box className="drawerHeader">
        <Button
          color="secondary"
          variant={type === 'dark' ? 'tertiary' : 'contained'}
          size="small"
          className="closeButton"
          // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin...
          onClick={(event) => otherProps.onClose({ event }, 'backdropClick')}
        >
          <Icon name={EIcon.Close} htmlColor={theme.palette.secondary[400]} fontSize="small" />
        </Button>
        {isValidElement(header) ? header : <Typography>{header}</Typography>}
      </Box>
      <Box className="drawerBody" position="relative">
        {children}
      </Box>
    </StyledRightDrawer>
  );
}

export default Drawer;

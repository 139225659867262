import { Stack, StackProps, styled } from '@mui/material';
import { TABLE_SIZES } from './components/TableSizeSelector/TableSizeSelector.constants';

const StyledTable = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'tableSize' && propName !== 'isLoading';
  }
})<StackProps & { tableSize: string; isLoading: boolean }>(({ tableSize, isLoading, theme }) => ({
  overflowY: 'auto',
  marginBottom: 1,
  maxHeight: '62vh',
  position: 'relative',

  ...(isLoading
    ? {
        border: `1px solid ${theme.palette.secondary[700]}`
      }
    : {}),

  ...(tableSize === TABLE_SIZES.medium
    ? {
        '.MuiTableCell-root': {
          padding: '10px 8px'
        }
      }
    : {}),
  ...(tableSize === TABLE_SIZES.large
    ? {
        '.MuiTableCell-root': {
          padding: '10px 8px',
          height: '62px'
        }
      }
    : {}),

  '.MuiTableCell-root.MuiTableCell-head': {
    padding: '0 8px 0 4px',
    height: '40px'
  }
}));

export default StyledTable;

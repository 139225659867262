import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import ArcannaChip from '../../ArcannaChip';
import { formatDateTime } from '../../../utilities/date';

type JobInvalidTagProps = {
  message: string;
  invalidTimestamp?: Date;
};

export function JobInvalidTag({ message, invalidTimestamp }: JobInvalidTagProps) {
  const { t } = useTranslation(['job']);

  return (
    <div className="flex flex-align-center" style={{ gap: 8 }}>
      <ArcannaChip color="red">
        {/* @ts-expect-error TS(2345): Argument of type 'Date | undefined' is not assigna... */}
        {t('job:invalidState.tagText')} {formatDateTime(invalidTimestamp)}
      </ArcannaChip>
      {message ? (
        <Tooltip title={message}>
          <WarningFilled className="color-nb-400" style={{ fontSize: 20 }} />
        </Tooltip>
      ) : null}
    </div>
  );
}

import { EIcon, Icon } from '@arcanna/generic';
import { Button, ButtonProps } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  showErrorNotification,
  showInfoNotification,
  showWarningNotification
} from 'src/components/shared/utilities/notification';

type CopyToClipboardButtonProps = {
  buttonTitle?: string;
  value: string | null | undefined;
  buttonProps?: ButtonProps;
};

function CopyToClipboardButton({ buttonTitle, value, buttonProps }: CopyToClipboardButtonProps) {
  const { t } = useTranslation(['common']);

  const handleClick = async () => {
    if (!value)
      return showWarningNotification(
        t('common:copyToClipboard.notification.warning.title'),
        t('common:copyToClipboard.notification.warning.subtitle')
      );
    try {
      await navigator.clipboard.writeText(value);
      showInfoNotification(
        t('common:copyToClipboard.notification.info.title'),
        t('common:copyToClipboard.notification.info.subtitle')
      );
    } catch (error) {
      showErrorNotification(
        t('common:copyToClipboard.notification.error.title'),
        t('common:copyToClipboard.notification.error.subtitle')
      );
    }
  };

  return (
    <Button
      color="secondary"
      variant="contained"
      endIcon={<Icon name={EIcon.Copy} />}
      onClick={handleClick}
      size="small"
      {...buttonProps}
    >
      {buttonTitle || t('common:copyToClipboard.copyPlaceholder', { placeholder: t('common:copyToClipboard.value') })}
    </Button>
  );
}

export default memo(CopyToClipboardButton);

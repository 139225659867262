import { EmptyFolderIcon } from '../../../../../../themes/icons';
import { Typography } from 'antd';
import styles from './styles.module.css';

export default function EmptyTableState({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <div className={styles.container}>
      <EmptyFolderIcon />
      <Typography.Text className={styles.title}>{title}</Typography.Text>
      <Typography.Text className={styles.subtitle}>{subtitle}</Typography.Text>
    </div>
  );
}

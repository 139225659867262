import FeedbackBulkContextProvider from '@arcanna/pages/Feedback/FeedbackBulkContext/FeedbackBulkContextProvider';
import { useLabelContext } from '@arcanna/pages/Feedback/FeedbackContext/useLabelContext';
import FeedbackFlowFooter from '@arcanna/pages/Feedback/FeedbackTable/components/FeedbackFlowFooter';
import { useFeedbackUnifiedContext } from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedProvider/FeedbackUnifiedProvider';
import StyledFeedbackUnifiedFooter from '@arcanna/pages/FeedbackUnified/StyledFeedbackUnifiedFooter.styles';

function FeedbackUnifiedTableFooter({
  selectedBuckets,
  allLabelsOnChangeCancel,
  allLabelsOnChangeConfirm
}: {
  selectedBuckets: string[];
  allLabelsOnChangeCancel: () => void;
  allLabelsOnChangeConfirm: () => void;
}) {
  const { editLabelOnSelected, setEditLabelOnSelected, prepareConfirm, setPrepareConfirm, prepareClear, setPrepareClear } =
    useLabelContext((context) => context);

  const setReloadFeedbackData = useFeedbackUnifiedContext((context) => context.setReloadFeedbackData);
  const state = useFeedbackUnifiedContext((context) => context.state);

  return (
    <FeedbackBulkContextProvider
      selectedLabel={editLabelOnSelected}
      prepareConfirm={prepareConfirm}
      prepareClear={prepareClear}
      feedbackBulkContextValue={{
        totalBucketsCount: state.tableData.length,
        selectedBuckets: selectedBuckets,
        bucketsRows: state.tableData,
        setEditLabelOnSelected: setEditLabelOnSelected,
        handleCancelBulkFeedback: allLabelsOnChangeCancel,
        handleCustomOnSave: allLabelsOnChangeConfirm,
        triggerBucketsReload: () => setReloadFeedbackData(true),
        setPrepareConfirm: setPrepareConfirm,
        setPrepareClear: setPrepareClear
      }}
    >
      <StyledFeedbackUnifiedFooter>
        <FeedbackFlowFooter
          enabled={selectedBuckets.length > 0}
          jobInfos={state.jobInfos}
          prepareConfirm={prepareConfirm}
          prepareClear={prepareClear}
        />
      </StyledFeedbackUnifiedFooter>
    </FeedbackBulkContextProvider>
  );
}

export default FeedbackUnifiedTableFooter;

import { createContext, useContext, useState } from 'react';
import { UserRoleNamesRecord } from '../../../shared/models/user/UserRoleNamesRecord';
import { UserSummaryRecord } from '../../../shared/models/user/UserSummaryRecord';
import { UserUpdateRecord } from '../../../shared/models/user/UserUpdateRecord';

// eslint-disable-next-line
export const UserContext = createContext<UserContextType>(null as any);

export type UserStateType = {
  id: number;
  loading: boolean;
  reloadUserRoles: boolean;
  reloadUsers: boolean;
  userRoleNames: UserRoleNamesRecord[];
  tableData: UserSummaryRecord[];
  initialTableData: UserSummaryRecord[];
  searchValue: string;
  rolesToBeUpdated: UserUpdateRecord[];
  columnToEdit: string;
  changedColumnRole: string;
  confirmSave: boolean;
  confirmDelete: boolean;
  selectedUserForDelete: string;
};

export type UserContextType = {
  state: UserStateType;
  setLoading: (loading: boolean) => void;
  setReloadUserRoles: (reloadUserRoles: boolean) => void;
  setReloadUsers: (reloadUsers: boolean) => void;
  setUserRoleNames: (userRoleNames: UserRoleNamesRecord[]) => void;
  setTableData: (tableData: UserSummaryRecord[]) => void;
  setInitialTableData: (tableData: UserSummaryRecord[]) => void;
  setSearchValue: (searchValue: string) => void;
  setRolesToBeUpdated: (records: UserUpdateRecord[]) => void;
  setColumnToEdit: (columnToEdit: string) => void;
  setChangedColumnRole: (changedColumnRole: string) => void;
  setConfirmSave: (confirmSave: boolean) => void;
  setConfirmDelete: (confirmDelete: boolean) => void;
  setSelectedUserForDelete: (selectedUserForDelete: string) => void;
};

// eslint-disable-next-line
export function UserProvider(props: any) {
  const [state, setState] = useState<UserStateType>({
    id: Math.random(),
    loading: false,
    reloadUserRoles: true,
    reloadUsers: true,
    userRoleNames: [],
    tableData: [],
    initialTableData: [],
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    searchValue: null,
    rolesToBeUpdated: [],
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    columnToEdit: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    changedColumnRole: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'boolean'.
    confirmSave: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'boolean'.
    confirmDelete: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    selectedUserForDelete: null
  });

  const setLoading = (loading: boolean) => {
    setState((current: UserStateType) => ({
      ...current,
      loading
    }));
  };

  const setReloadUserRoles = (reloadUserRoles: boolean) => {
    setState((current: UserStateType) => ({
      ...current,
      reloadUserRoles
    }));
  };

  const setReloadUsers = (reloadUsers: boolean) => {
    setState((current: UserStateType) => ({
      ...current,
      reloadUsers
    }));
  };

  const setUserRoleNames = (userRoleNames: UserRoleNamesRecord[]) => {
    setState((current: UserStateType) => ({
      ...current,
      userRoleNames
    }));
  };

  const setTableData = (tableData: UserSummaryRecord[]) => {
    setState((current: UserStateType) => ({
      ...current,
      tableData
    }));
  };

  const setInitialTableData = (initialTableData: UserSummaryRecord[]) => {
    setState((current: UserStateType) => ({
      ...current,
      initialTableData
    }));
  };

  const setSearchValue = (searchValue: string) => {
    setState((current: UserStateType) => ({
      ...current,
      searchValue
    }));
  };

  const setRolesToBeUpdated = (rolesToBeUpdated: UserUpdateRecord[]) => {
    setState((current: UserStateType) => ({
      ...current,
      rolesToBeUpdated
    }));
  };

  const setColumnToEdit = (columnToEdit: string) => {
    setState((current: UserStateType) => ({
      ...current,
      columnToEdit
    }));
  };

  const setChangedColumnRole = (changedColumnRole: string) => {
    setState((current: UserStateType) => ({
      ...current,
      changedColumnRole
    }));
  };

  const setConfirmSave = (confirmSave: boolean) => {
    setState((current: UserStateType) => ({
      ...current,
      confirmSave
    }));
  };

  const setConfirmDelete = (confirmDelete: boolean) => {
    setState((current: UserStateType) => ({
      ...current,
      confirmDelete
    }));
  };

  const setSelectedUserForDelete = (selectedUserForDelete: string) => {
    setState((current: UserStateType) => ({
      ...current,
      selectedUserForDelete
    }));
  };

  const value: UserContextType = {
    state,
    setLoading,
    setReloadUserRoles,
    setReloadUsers,
    setUserRoleNames,
    setTableData,
    setInitialTableData,
    setSearchValue,
    setRolesToBeUpdated,
    setColumnToEdit,
    setChangedColumnRole,
    setConfirmSave,
    setConfirmDelete,
    setSelectedUserForDelete
  };

  const { children } = props;
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUserContext(): UserContextType {
  return useContext(UserContext);
}

import { getStatusMessage, ModalStepIcon } from './ModalSteps.utils';
import { EStepStatus, TStep } from './ModalSteps.types';
import ModalStepsFooterError from './components/ModalStepsFooter/ModalStepsFooterError';
import ModalStepsFooterSuccess from './components/ModalStepsFooter/ModalStepsFooterSuccess';
import { Modal } from '@arcanna/generic';
import { Step, StepContent, StepLabel, Stepper, Typography, useTheme } from '@mui/material';

type TModalStepsProps = {
  zIndex?: number;
  onContinue?: () => void;
  onConfirm?: () => void;
  onClose: () => void;
  numberOfEvents?: number;
  isModalOpen: boolean;
  title: string;
  steps: TStep[];
  currentStep: number;
  footerConfig: {
    successConfig: {
      successMessage: string;
      successDescription?: string;
      confirmButtonText?: string;
      continueButtonText?: string;
    };
    errorConfig: {
      errorMessage: string;
      errorDescription?: string;
      closeButtonText: string;
    };
  };
};

function ModalSteps(props: TModalStepsProps) {
  const { palette } = useTheme();

  const getFooter = () => {
    const areAllStepsSuccess = props.steps.every((step) => step.status === EStepStatus.Success);
    const areAnyStepsError = props.steps.some((step) => step.status === EStepStatus.Error);

    if (areAnyStepsError) {
      return (
        <ModalStepsFooterError
          errorMessage={props.footerConfig.errorConfig.errorMessage}
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
          errorDescription={props.footerConfig.errorConfig.errorDescription}
          onClose={props.onClose}
          closeButtonText={props.footerConfig.errorConfig.closeButtonText}
        />
      );
    }

    if (areAllStepsSuccess) {
      return (
        <ModalStepsFooterSuccess
          numberOfEvents={props.numberOfEvents}
          successMessage={props.footerConfig.successConfig.successMessage}
          successDescription={props.footerConfig.successConfig.successDescription}
          onConfirm={props.onConfirm}
          confirmButtonText={props.footerConfig.successConfig.confirmButtonText}
          onContinue={props.onContinue}
          continueButtonText={props.footerConfig.successConfig.continueButtonText}
          onClose={props.onClose}
        />
      );
    }
    return <></>;
  };

  return props.isModalOpen ? (
    <Modal
      titleNode={
        <Typography variant="h4" color={palette.secondary[100]}>
          {props.title}
        </Typography>
      }
      open={props.isModalOpen}
      onClose={() => {
        const lastStep = props.steps[props.steps.length - 1].status;
        if (![EStepStatus.Error, EStepStatus.Success].includes(lastStep)) {
          return;
        }
        props.onClose();
      }}
      width={520}
      footer={getFooter()}
    >
      <div>
        <Stepper activeStep={props.currentStep} orientation="vertical">
          {props.steps.map((step, index) => {
            return (
              <Step key={index} active={true} completed={step.status !== EStepStatus.Validating}>
                <StepLabel error={step.status === EStepStatus.Error} StepIconComponent={ModalStepIcon}>
                  <Typography variant={'body2'}>{step.title}</Typography>
                </StepLabel>
                <StepContent>
                  <Typography variant={'subtitle1'} color={palette.secondary[300]}>
                    {getStatusMessage(step)}
                  </Typography>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </Modal>
  ) : null;
}

export default ModalSteps;

import { UseFormReturn, FormProvider as Form } from 'react-hook-form';
import { ReactNode } from 'react';

type TFormProviderProps<T> = {
  children: ReactNode;
  methods: UseFormReturn<T>;
  onSubmit?: VoidFunction;
};

function FormProvider<T extends object | unknown>({ children, onSubmit, methods }: TFormProviderProps<T>) {
  return (
    // @ts-expect-error TS(2322): Type '{ children: Element; watch: UseFormWatch<T>;...
    <Form {...methods}>
      <form onSubmit={onSubmit} noValidate>
        {children}
      </form>
    </Form>
  );
}

export default FormProvider;

import { AutocompleteRenderGetTagProps, Chip, Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Icon, EIcon, ComboBox, TComboBoxProps, TOption } from '@arcanna/generic';

type TRHFComboBoxProps<T> = {
  name: string;
  options: T[];
  label: string;
  required?: boolean;
  tooltipText?: string;
  placeholder?: string;
  disabled?: boolean;
} & Pick<TComboBoxProps<T>, 'renderInput' | 'renderOptionLabel'>;

function RHFComboBox<T extends TOption>({
  name,
  options,
  label,
  required,
  tooltipText,
  placeholder,
  disabled,
  ...otherProps
}: TRHFComboBoxProps<T>) {
  const {
    formState: { isSubmitting },
    control,
    setValue
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <ComboBox<T>
          options={options}
          label={label}
          required={required}
          tooltipText={tooltipText}
          placeholder={placeholder}
          disabled={isSubmitting || disabled}
          error={error}
          {...field}
          value={options.filter((option) => (field.value ?? []).includes(option.value))}
          onChange={(_, newValue) =>
            setValue(
              name,
              newValue.map((value) => value.value),
              { shouldValidate: true }
            )
          }
          renderTags={(selectedOptions: TOption[], getTagProps: AutocompleteRenderGetTagProps) => (
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              gap={0.5}
              sx={{ height: '100%', maxHeight: '104px', overflowY: 'auto' }}
            >
              {selectedOptions.map((option, index) => (
                <Chip
                  key={option.value}
                  label={option.label}
                  onDelete={(event) => getTagProps({ index }).onDelete(event)}
                  deleteIcon={<Icon name={EIcon.ChipClose} />}
                  sx={{ height: '100%' }}
                />
              ))}
            </Stack>
          )}
          {...otherProps}
        />
      )}
    />
  );
}

export default RHFComboBox;

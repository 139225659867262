import { JsonObject, JsonProperty } from 'json2typescript';
import { EnvVariableRecord } from '@arcanna/models/CodeBlock';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('CodeBlockSaveRequest')
export class CodeBlockSaveRequest extends RequestCommon {
  @JsonProperty('custom_code_id', String)
  codeBlockId: string | undefined;

  @JsonProperty('source_code', String)
  sourceCode: string;

  @JsonProperty('enabled', Boolean)
  enabled: boolean;

  @JsonProperty('job_id', Number)
  jobId: number;

  @JsonProperty('input_test', String)
  inputTest: string;

  @JsonProperty('integration_type_id', Number)
  integrationTypeId: number;

  @JsonProperty('env_variables', [EnvVariableRecord])
  envVariables: EnvVariableRecord[];

  @JsonProperty('integration_title', String)
  integrationTitle: string;

  @JsonProperty('description', String)
  description: string | null;
}

type TConstructorParams = {
  codeBlockId: CodeBlockSaveRequest['codeBlockId'];
  sourceCode: CodeBlockSaveRequest['sourceCode'];
  enabled: CodeBlockSaveRequest['enabled'];
  jobId: CodeBlockSaveRequest['jobId'];
  inputTest: CodeBlockSaveRequest['inputTest'];
  integrationTypeId: CodeBlockSaveRequest['integrationTypeId'];
  envVariables: CodeBlockSaveRequest['envVariables'];
  integrationTitle: CodeBlockSaveRequest['integrationTitle'];
  description: CodeBlockSaveRequest['description'];
};

export function constructCodeBlockSaveRequest({
  codeBlockId,
  sourceCode,
  enabled,
  jobId,
  inputTest,
  integrationTypeId,
  envVariables,
  integrationTitle,
  description
}: TConstructorParams): CodeBlockSaveRequest {
  const instance = new CodeBlockSaveRequest();
  instance.codeBlockId = codeBlockId;
  instance.sourceCode = sourceCode;
  instance.enabled = enabled;
  instance.jobId = jobId;
  instance.inputTest = inputTest;
  instance.integrationTypeId = integrationTypeId;
  instance.envVariables = envVariables;
  instance.integrationTitle = integrationTitle;
  instance.description = description;
  return instance;
}

import { useEffect, useMemo } from 'react';
import { FeedbackBatchBucketRow } from 'src/components/shared/models/feedback/FeedbackBatchBucketRow';
import { useFeedbackBucketsUnifiedBatch } from 'src/data-access/feedback/useFeedbackBucketsUnifiedBatch';
import { useFeedbackUnifiedContext } from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedProvider/FeedbackUnifiedProvider';
import { getColumnValue } from 'src/components/shared/utilities/table';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useFeedbackUnifiedFilterFields } from 'src/data-access/feedback/useFeedbackUnifiedFilterFields';
import { getTransformedAdvancedFilters } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.utils';
import { config } from 'src/config';
import { computeRowIdFromBucketIdAndJobId } from '@arcanna/pages/Feedback/FeedbackTable/helpers/FeedbackTableRowId';

function useFeedbackUnifiedBuckets() {
  const tableSlice = useFeedbackUnifiedContext((context) => context.tableSlice);
  const filtersSlice = useFeedbackUnifiedContext((context) => context.filtersSlice);
  const state = useFeedbackUnifiedContext((context) => context.state);
  const setLoading = useFeedbackUnifiedContext((context) => context.setLoading);
  const advancedFilters = useFeedbackUnifiedContext((context) => context.advancedFilters);
  const setReloadFeedbackData = useFeedbackUnifiedContext((context) => context.setReloadFeedbackData);
  const setFiltersFields = useFeedbackUnifiedContext((context) => context.setFiltersFields);
  const setTableData = useFeedbackUnifiedContext((context) => context.setTableData);

  const { ids } = useParams<{ ids: string }>();
  const jobIds = useMemo(() => ids.split(',').map((id: string) => Number(id)), [ids]);
  const queryClient = useQueryClient();
  const feedbackFiltersQuery = useFeedbackUnifiedFilterFields(jobIds);

  const feedbackBatchUnifiedQuery = useFeedbackBucketsUnifiedBatch({
    jobIds: jobIds,
    includeAllJobs: false,
    tablePageSize: tableSlice.paginationState.pageSize,
    tablePage: (tableSlice.paginationState.pageIndex - 1) * tableSlice.paginationState.pageSize,
    startDate: filtersSlice.startDate,
    endDate: filtersSlice.endDate,
    excludeProcessed: false,
    includeProcessed: false,
    tableSort: state.tableSort,
    advancedFilters: getTransformedAdvancedFilters(advancedFilters.activeFilters),
    quickFilters: state.quickFilters,
    decisionFilters: state.decisionFilters,
    consensusFilters: state.consensusFilters
  });

  useEffect(() => {
    if (feedbackBatchUnifiedQuery.isError) {
      showErrorNotification('Batch retrieval error', '', feedbackBatchUnifiedQuery.data?.request?.reason);
      setLoading(false);
    }
  }, [feedbackBatchUnifiedQuery.isError, feedbackBatchUnifiedQuery.data, setLoading]);

  useEffect(() => {
    if (state.reloadFeedbackData) {
      setReloadFeedbackData(false);
      queryClient.resetQueries([config.api.feedbackBucketsUnifiedBatch]);
    }
  }, [setReloadFeedbackData, queryClient, state.reloadFeedbackData]);

  useEffect(() => {
    if (feedbackBatchUnifiedQuery.data) {
      const batchResponse = feedbackBatchUnifiedQuery.data;
      const rows: FeedbackBatchBucketRow[] = [];
      batchResponse?.rows?.forEach((value: FeedbackBatchBucketRow) =>
        rows.push({
          ...value,
          row_id: computeRowIdFromBucketIdAndJobId(getColumnValue(value, 'id'), getColumnValue(value, 'job_id')),
          enabled: getColumnValue(value, 'enabled')
        })
      );
      setTableData(rows);
      tableSlice.setBatchBucketDynamicColumns(batchResponse.dynamic_columns || []);
      tableSlice.setTotalRecordsCount(batchResponse.total || 0);
      tableSlice.setIsBucketsTableLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackBatchUnifiedQuery.data]);

  useEffect(() => {
    if (feedbackFiltersQuery.data) {
      setFiltersFields(feedbackFiltersQuery.data.fields_list || []);
    }
  }, [feedbackFiltersQuery.data, setFiltersFields]);
}

export default useFeedbackUnifiedBuckets;

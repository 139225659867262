import moment from 'moment';
import { TAdditionalFilter } from '@arcanna/components';
import { FeedbackTimeSelectorOption } from 'src/components/pages/Main/Jobs/Feedback/Flow/FeedbackFlow.hook';
import _ from 'lodash';
import { TOption } from '@arcanna/generic';

export const convertDateToISOString = (date: Date | null): string | null => (date ? date.toISOString() : null);

export const mapSingleFilterValue = (
  value: string,
  title: string,
  onDelete: (value: string) => void
): TAdditionalFilter | undefined =>
  value
    ? {
        label: `${title}: ${value}`,
        onDelete: () => onDelete(value)
      }
    : undefined;

export const createValueOptions = (buckets: { doc_count: number; key: string }[] = []) => {
  const recommendedValueList: TOption[] = [];

  if (buckets !== null) {
    buckets.forEach((item: { doc_count: number; key: string; key_as_string?: string }) => {
      const optionValue = item.key_as_string || item.key.toString();
      const label = `${optionValue} (${item.doc_count})`;

      if (!_.isEmpty(optionValue)) {
        recommendedValueList.push({ value: optionValue, label });
      }
    });
  }

  return recommendedValueList;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getBucketsFromAggs = (aggs: any, valueField: string) => {
  let buckets = [];
  if (valueField in aggs) {
    buckets = aggs[valueField].buckets;
  }
  return buckets;
};

export const feedbackTimeSelectorRecord: Record<
  FeedbackTimeSelectorOption,
  {
    dateFrom: Date | null;
    dateTo: Date | null;
  }
> = {
  [FeedbackTimeSelectorOption.ALL_TIME]: {
    dateFrom: null,
    dateTo: moment().toDate()
  },
  [FeedbackTimeSelectorOption.LAST_30_DAYS]: {
    dateFrom: moment(Date.now() - 30 * 24 * 3600 * 1000).toDate(),
    dateTo: moment().toDate()
  },
  [FeedbackTimeSelectorOption.LAST_7_DAYS]: {
    dateFrom: moment(Date.now() - 7 * 24 * 3600 * 1000).toDate(),
    dateTo: moment().toDate()
  },
  [FeedbackTimeSelectorOption.LAST_24_HOURS]: {
    dateFrom: moment(Date.now() - 24 * 3600 * 1000).toDate(),
    dateTo: moment().toDate()
  }
};

import BucketDrawer from '@arcanna/pages/Feedback/BucketDrawer';
import BucketDrawerContextProvider from '@arcanna/pages/Feedback/BucketDrawer/context/BucketDrawerContextProvider';
import { FeedbackBatchBucketColumn } from 'src/components/shared/models/feedback/FeedbackBatchBucketColumn';
import { useFeedbackUnifiedContext } from '../FeedbackUnifiedProvider/FeedbackUnifiedProvider';
import { extractBucketIdFromRowId } from '@arcanna/pages/Feedback/FeedbackTable/helpers/FeedbackTableRowId';

function FeedbackUnifiedBucketDrawer() {
  const filtersFields = useFeedbackUnifiedContext((context) => context.state.filtersFields);
  const advancedFilters = useFeedbackUnifiedContext((context) => context.advancedFilters);
  const setReloadFeedbackData = useFeedbackUnifiedContext((context) => context.setReloadFeedbackData);
  const expandedBucketIndex = useFeedbackUnifiedContext((context) => context.state.expandedBucketIndex);
  const bucketRowsCount = useFeedbackUnifiedContext((context) => context.state.tableData.length);
  const setExpandedBucketByIndex = useFeedbackUnifiedContext((context) => context.setExpandedBucketByIndex);
  const expandedBucketRow = useFeedbackUnifiedContext((context) => context.state.expandedBucketRow);
  const closeExpandedBucketRow = useFeedbackUnifiedContext((context) => context.closeExpandedBucketRow);

  return (
    <BucketDrawerContextProvider
      bucketContextValue={{
        filterFields: filtersFields,
        advancedFilters: advancedFilters,
        triggerBucketsReload: () => setReloadFeedbackData(true),
        expandedBucketIndex: expandedBucketIndex,
        bucketRowsCount,
        setExpandedBucketByIndex: setExpandedBucketByIndex
      }}
    >
      <BucketDrawer
        jobId={Number(
          expandedBucketRow?.columns?.find((columnItem: FeedbackBatchBucketColumn) => columnItem.name === 'job_id')?.value
        )}
        bucketId={extractBucketIdFromRowId(expandedBucketRow?.row_id || '')}
        shortBucketId={
          expandedBucketRow?.columns?.find((columnItem: FeedbackBatchBucketColumn) => columnItem.name === 'bucket_id')?.value ||
          ''
        }
        expandedBucketRowIndex={expandedBucketIndex}
        handleDrawerClose={closeExpandedBucketRow}
      />
    </BucketDrawerContextProvider>
  );
}

export default FeedbackUnifiedBucketDrawer;

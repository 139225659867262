import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { UserKeyRecord } from './UserKeyRecord';

@JsonObject('UserKeyListResponse')
export class UserKeyListResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('entries', [UserKeyRecord])
  entries?: UserKeyRecord[] = [];
}

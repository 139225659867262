import { JsonObject, JsonProperty } from 'json2typescript';
import { EnvVariableRecord } from '@arcanna/models/CodeBlock';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('CodeBlockRunRequest')
export class CodeBlockRunRequest extends RequestCommon {
  @JsonProperty('source_code', String)
  sourceCode: string;

  @JsonProperty('input_test', String)
  inputTest: string;

  @JsonProperty('integration_type_id', Number)
  integrationTypeId: number;

  @JsonProperty('env_variables', [EnvVariableRecord])
  envVariables: EnvVariableRecord[];
}

type TConstructorParams = {
  sourceCode: CodeBlockRunRequest['sourceCode'];
  inputTest: CodeBlockRunRequest['inputTest'];
  integrationTypeId: CodeBlockRunRequest['integrationTypeId'];
  envVariables: CodeBlockRunRequest['envVariables'];
};

export function constructCodeBlockRunRequest({
  sourceCode,
  inputTest,
  integrationTypeId,
  envVariables
}: TConstructorParams): CodeBlockRunRequest {
  const instance = new CodeBlockRunRequest();
  instance.sourceCode = sourceCode;
  instance.inputTest = inputTest;
  instance.integrationTypeId = integrationTypeId;
  instance.envVariables = envVariables;
  return instance;
}

import ArcannaDecisionTimeseries from './components/ArcannaDecisionTimeseries';
import ConsensusMetrics from './components/ConsensusMetrics';

type DecisionPointsBodyProps = {
  jobId: number;
  bucketId: string;
  shortBucketId: string;
};

function DecisionPointsBody({ jobId, bucketId, shortBucketId }: DecisionPointsBodyProps) {
  return (
    <>
      <ConsensusMetrics jobId={jobId} bucketId={bucketId} />
      <ArcannaDecisionTimeseries jobId={jobId} shortBucketId={shortBucketId} />
    </>
  );
}

export default DecisionPointsBody;

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { CustomLabel } from '@arcanna/components';
import { CustomLabel as CustomLabelData } from 'src/components/shared/models';
import { FeedbackBucketSimilarityRecord } from 'src/components/shared/models/feedback/FeedbackBucketSimilarityResponse';
import addEllipsisOnLongText from 'src/components/shared/utilities/addEllipsisOnLongText';
import { noDecisionResult } from 'src/components/pages/Main/Jobs/helper';

type SimilarBucketsSelectProps = {
  customLabels: CustomLabelData[];
  records?: FeedbackBucketSimilarityRecord[];
  activeIndex: number;
  onChange: (activeIndex: number) => void;
};

function SimilarBucketsSelect({ customLabels, records, activeIndex, onChange }: SimilarBucketsSelectProps) {
  const { t } = useTranslation(['feedback']);

  const handleOnChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const selectedBucketId = event.target.value;
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const newIndex = records.findIndex((record) => record.bucketId == selectedBucketId);
      onChange(newIndex);
    },
    [onChange, records]
  );

  const customLabelsInfo = useMemo(() => {
    if (records == null || customLabels == null) {
      return null;
    }
    return records.map((record) => {
      const customLabel = customLabels.find((label) => label.id == record.arcannaDecision);
      if (customLabel !== null) {
        return {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          id: customLabel.id,
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          name: customLabel.name,
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          hexColor: customLabel.hexColor
        };
      }
      return noDecisionResult;
    });
  }, [customLabels, records]);

  const formatSimilarityPercentage = function (subunitaryPercentage: number) {
    return (subunitaryPercentage * 100).toFixed(2) + '% ' + t('feedback:bucketExpand.similar');
  };

  return (
    <Box>
      {records != null && (
        <Select
          value={records[activeIndex].bucketId}
          onChange={handleOnChange}
          renderValue={() => formatSimilarityPercentage(records[activeIndex].similarityPercentage)}
          sx={{
            minWidth: '366px',
            width: '100%'
          }}
        >
          {records?.map((record, index) => (
            <MenuItem className="MuiMenuItem-root--smaller" key={record.bucketId} value={record.bucketId}>
              <Stack direction="row" alignItems="center" gap="8px">
                <Typography variant="subtitle2">{formatSimilarityPercentage(record.similarityPercentage)}</Typography>
                <CustomLabel
                  fontSize="12px"
                  // @ts-expect-error TS(2531): Object is possibly 'null'.
                  id={customLabelsInfo[index].id}
                  // @ts-expect-error TS(2531): Object is possibly 'null'.
                  name={addEllipsisOnLongText(customLabelsInfo[index].name, 10, 'end')}
                  // @ts-expect-error TS(2531): Object is possibly 'null'.
                  hexColor={customLabelsInfo[index].hexColor}
                />
                <Typography
                  variant="subtitle2"
                  sx={{
                    width: '136px',
                    flexGrow: 1,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {record.bucketId}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      )}
    </Box>
  );
}
export default SimilarBucketsSelect;

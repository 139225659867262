/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { FeedbackBatchBucketRow } from 'src/components/shared/models/feedback/FeedbackBatchBucketRow';
import { FilterValue } from 'antd/lib/table/interface';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import { FeedbackQuickFilters } from 'src/components/shared/models/feedback/FeedbackQuickFilters';
import { FeedbackUnifiedFiltersValuesRequest } from 'src/components/shared/models/feedback/FeedbackUnifiedFiltersValuesRequest';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import { TOption } from '@arcanna/generic';
import { AdvancedFilters, TAdditionalFilter, TFilterItem, TUseAdvancedFilters } from '@arcanna/components';
import { useFetch } from 'src/components/shared/hooks/useFetch';
import { getBackendEndpoint } from 'src/components/shared/utilities/api';
import { getJsonConvert } from 'src/components/shared/utilities/json-convert';
import { getTransformedAdvancedFilters } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.utils';
import { FeedbackFiltersValuesResponse } from 'src/components/shared/models/feedback/FeedbackFiltersValuesResponse';
import { useTranslation } from 'react-i18next';
import { ARCANNA_NO_DECISION, ARCANNA_NO_DECISION_DISPLAY_VALUE } from 'src/components/pages/JobEventExplorer/utils';
import { config } from 'src/config';
import { FeedbackBucketState } from 'src/constants';
import { CustomLabel } from 'src/components/shared/models';
import { TFeedbackContext } from '@arcanna/pages/Feedback/FeedbackContext/FeedbackContext.type';
import { initialFeedbackContext } from '@arcanna/pages/Feedback/FeedbackContext/FeedbackContext';
import useFeedbackFlowFilters from 'src/components/pages/Main/Jobs/Feedback/Flow/hooks/useFeedbackFlowFilters';
import { BatchTableSort } from 'src/components/shared/models/event-explorer/EventExplorerBatchRequest';
import { FeedbackAdvancedFilterRecord } from 'src/components/shared/models/feedback/FeedbackAdvancedFilterRecord';
import {
  getBucketsFromAggs,
  createValueOptions,
  mapSingleFilterValue
} from 'src/_srcMUI/pages/Feedback/FeedbackContext/FeedbackContext.utils';
import { BucketFeedbackRow, splitBucketRowsByJobId } from 'src/_srcMUI/pages/Feedback/FeedbackTable/helpers/helpers';
import CustomLabelByJob from '@arcanna/pages/Feedback/FeedbackTable/models/CustomLabelByJob';
import { ContextSelector, createContext, useContextSelector } from '@fluentui/react-context-selector';
import { useFeedbackContext } from '@arcanna/pages/Feedback/FeedbackContext/useFeedbackContext';

// eslint-disable-next-line
export const FeedbackUnifiedContext = createContext<TFeedbackUnifiedContext & TFeedbackContext>(null as any);

export type TFeedbackUnifiedState = {
  jobIds: number[];
  jobInfos: JobInfoResponse[];
  jobCustomLabels: CustomLabelByJob[];
  loading: boolean;
  readonly: boolean;
  startDate: Date | null;
  endDate: Date | null;
  excludeProcessed: boolean;
  includeProcessed: boolean;
  quickFilters: FeedbackQuickFilters;
  decisionFilters: string[];
  consensusFilters: string[];
  tableData: FeedbackBatchBucketRow[];
  tablePage: number;
  tablePageSize: number;
  tableTotalSize: number;
  reloadFeedbackData: boolean;
  selectedRowKeys: string[];
  selectedRows: FeedbackBatchBucketRow[];
  selectedFeedback: FeedbackBatchBucketRow[];
  tableSort: BatchTableSort;
  filtersFields: FeedbackFiltersFieldsRecord[];
  editLabelOnSelected: CustomLabelByJob | null;
  editLabels: Map<string, BucketFeedbackRow>;
  editLabelsCount: number;
  editAll: boolean;
  expandedBucketRow: FeedbackBatchBucketRow | undefined;
  expandedBucketIndex: number | undefined;
  prepareConfirm: boolean;
  prepareClear: boolean;
  // eslint-disable-next-line
  advancedFilters: any[];
} & TFeedbackContext;

export type TFeedbackUnifiedContext = {
  state: TFeedbackUnifiedState;
  setJobIds: (ids: number[]) => void;
  setJobInfos: (jobInfos: JobInfoResponse[]) => void;
  setJobCustomLabels: (customLabels: CustomLabel[]) => void;
  setLoading: (loading: boolean) => void;
  setDates: (startDate: Date, endDate: Date) => void;
  setTableData: (tableData: FeedbackBatchBucketRow[]) => void;
  setTablePage: (tablePage: number) => void;
  setTablePageSize: (tablePageSize: number) => void;
  setTableTotalSize: (tableTotalSize: number) => void;
  setReloadFeedbackData: (reloadFeedbackData: boolean) => void;
  setSelectedRowKeys: (selectedRowKeys: string[]) => void;
  setSelectedRows: (selectedRows: FeedbackBatchBucketRow[]) => void;
  setSelectedFeedback: (selectedFeedback: FeedbackBatchBucketRow[]) => void;
  setTableSort: (tableSort: BatchTableSort) => void;
  setTableFilters: (tableFilter: Record<string, FilterValue | null>) => void;
  setFiltersFields: (filtersFields: FeedbackFiltersFieldsRecord[]) => void;
  setEditLabelOnSelected: (editLabel: CustomLabelByJob | null) => void;
  setEditAll: (editAll: boolean) => void;
  setExpandedBucketRow: (expandedBucketRow: FeedbackBatchBucketRow | undefined, exandedBucketIndex: number | undefined) => void;
  advancedFilters: TUseAdvancedFilters;
  additionalFilters: TAdditionalFilter[];
  setQuickFilters: (quickFilters: FeedbackQuickFilters) => void;
  clearQuickFilters: () => void;
  setDecisionFilters: (decisionFilters: string[]) => void;
  clearDecisionFilters: () => void;
  setConsensusFilters: (consensusFilters: string[]) => void;
  clearConsensusFilters: () => void;
  clearAdditionalFilters: () => void;
  setExpandedBucketByIndex: (bucketDataIndex: number | undefined) => void;
  setPrepareConfirm: (prepareConfirm: boolean) => void;
  setPrepareClear: (prepareClear: boolean) => void;
  closeExpandedBucketRow: () => void;
};

const feedbackUnifiedInitialState: TFeedbackUnifiedState = {
  jobIds: [],
  jobInfos: [],
  jobCustomLabels: [],
  loading: false,
  readonly: false,
  startDate: null,
  endDate: null,
  excludeProcessed: false,
  includeProcessed: false,
  quickFilters: new FeedbackQuickFilters(),
  decisionFilters: [],
  consensusFilters: [],
  tableData: [],
  tablePage: 1,
  tablePageSize: 10,
  tableTotalSize: 0,
  reloadFeedbackData: true,
  selectedFeedback: [],
  selectedRowKeys: [],
  selectedRows: [],
  tableSort: {
    column: 'timestamp',
    order: 'desc'
  },
  filtersFields: [],
  editLabelOnSelected: null,
  editLabels: new Map<string, BucketFeedbackRow>(),
  editLabelsCount: 0,
  editAll: false,
  expandedBucketRow: undefined,
  expandedBucketIndex: undefined,
  prepareConfirm: false,
  prepareClear: false,
  advancedFilters: [],
  ...initialFeedbackContext
};

type FeedbackUnifiedProviderProps = {
  children: ReactNode;
};

function FeedbackUnifiedProvider({ children }: FeedbackUnifiedProviderProps) {
  const sharedContext = useFeedbackContext((context) => context);
  const { getFeedbackFiltersFromQueryParams } = useFeedbackFlowFilters();
  const [state, setState] = useState<TFeedbackUnifiedState>({
    ...feedbackUnifiedInitialState,
    ...getFeedbackFiltersFromQueryParams(feedbackUnifiedInitialState)
  });

  const { t } = useTranslation();
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { post: postFiltersValues } = useFetch({
    path: getBackendEndpoint(config.api.unified.filterValues),
    initialResponseData: null,
    load: false
  });

  const setJobIds = useCallback((jobIds: number[]) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      jobIds
    }));
  }, []);

  const setJobInfos = useCallback((jobInfos: JobInfoResponse[]) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      jobInfos
    }));
  }, []);

  const setJobCustomLabels = useCallback((jobCustomLabels: CustomLabel[]) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      jobCustomLabels
    }));
  }, []);

  const setLoading = useCallback((loading: boolean) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      loading
    }));
  }, []);

  const setHoveredRowId = useCallback((hoveredRowId: number) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      hoveredRowId
    }));
  }, []);

  const setDates = useCallback((startDate: Date, endDate: Date) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      startDate,
      endDate
    }));
  }, []);

  const getEndDateAsISO = useCallback(() => (state.endDate !== null ? state.endDate.toISOString() : null), [state.endDate]);

  const getStartDateAsISO = useCallback(
    () => (state.startDate !== null ? state.startDate.toISOString() : null),
    [state.startDate]
  );

  const setTableData = useCallback((tableData: FeedbackBatchBucketRow[]) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      tableData,
      loading: false
    }));
  }, []);

  const setTablePage = useCallback((tablePage: number) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      tablePage
    }));
  }, []);

  const setTablePageSize = useCallback((tablePageSize: number) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      tablePageSize
    }));
  }, []);

  const setTableTotalSize = useCallback((tableTotalSize: number) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      tableTotalSize
    }));
  }, []);

  const setReloadFeedbackData = useCallback((reloadFeedbackData: boolean) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      reloadFeedbackData
    }));
  }, []);

  const setSelectedRowKeys = useCallback((selectedRowKeys: string[]) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      selectedRowKeys
    }));
  }, []);

  const setSelectedRows = useCallback((selectedRows: FeedbackBatchBucketRow[]) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      selectedRows
    }));
  }, []);

  const setSelectedFeedback = useCallback(
    (selectedFeedback: FeedbackBatchBucketRow[]) => {
      const newEditLabels = new Map<string, BucketFeedbackRow>(state.editLabels);

      for (const key of state.editLabels.keys()) {
        const index = selectedFeedback.find((feedback: FeedbackBatchBucketRow) => feedback.row_id === key);
        if (index === undefined) {
          newEditLabels.delete(key);
        }
      }
      const editLabelsCount = newEditLabels.size;

      setState((current: TFeedbackUnifiedState) => ({
        ...current,
        selectedFeedback,
        editLabels: newEditLabels,
        editLabelsCount: editLabelsCount
      }));
    },
    [state.editLabels]
  );

  const setTableSort = useCallback((tableSort: BatchTableSort) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      tableSort
    }));
  }, []);

  const setTableFilters = useCallback((tableFilters: Record<string, FilterValue | null>) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      tableFilters
    }));
  }, []);

  const setFiltersFields = useCallback((filtersFields: FeedbackFiltersFieldsRecord[]) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      filtersFields
    }));
  }, []);

  const setEditLabels = useCallback(
    (editLabel: CustomLabelByJob | null, sourceData: FeedbackBatchBucketRow[]) => {
      const newEditLabels = splitBucketRowsByJobId(state.editLabels, editLabel?.id || '', sourceData);
      const editLabelsCount = newEditLabels.size;
      setState((current: TFeedbackUnifiedState) => ({
        ...current,
        editLabelOnSelected: editLabel,
        editLabels: newEditLabels,
        editLabelsCount,
        editAll: false
      }));
    },
    [state.editLabels]
  );

  const setEditLabelOnSelected = useCallback(
    (editLabel: CustomLabelByJob | null) => {
      setEditLabels(editLabel, state.selectedFeedback);
    },
    [setEditLabels, state.selectedFeedback]
  );

  const setEditAll = useCallback((editAll: boolean) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      editAll
    }));
  }, []);

  const setExpandedBucketRow = useCallback(
    (expandedBucketRow: FeedbackBatchBucketRow | undefined, expandedBucketIndex: number | undefined) => {
      setState((current: TFeedbackUnifiedState) => ({
        ...current,
        expandedBucketIndex,
        expandedBucketRow
      }));
    },
    []
  );

  const setExpandedBucketByIndex = useCallback(
    (bucketDataIndex: number | undefined) => {
      if ((!bucketDataIndex && bucketDataIndex !== 0) || bucketDataIndex > state.tableData.length - 1 || bucketDataIndex < 0) {
        setExpandedBucketRow(undefined, undefined);
      } else {
        setExpandedBucketRow(state.tableData[bucketDataIndex], bucketDataIndex);
      }
    },
    [setExpandedBucketRow, state.tableData]
  );

  const getValueOptions = useCallback(
    (fieldValue: string, activeFilters: TFilterItem[]): Promise<TOption[]> => {
      if (!fieldValue || state.jobIds == null || state.jobIds.length == 0) {
        return new Promise((resolve) => resolve([]));
      }

      return postFiltersValues(
        new FeedbackUnifiedFiltersValuesRequest(
          state.jobIds,
          fieldValue,
          getStartDateAsISO(),
          getEndDateAsISO(),
          state.excludeProcessed,
          state.includeProcessed,
          getTransformedAdvancedFilters(activeFilters) as FeedbackAdvancedFilterRecord[],
          state.quickFilters,
          state.decisionFilters,
          state.consensusFilters
        )
      ).then((res) => {
        const filtersValuesResponse = jsonConvert.deserializeObject(res.data.resource, FeedbackFiltersValuesResponse);
        const buckets = getBucketsFromAggs(filtersValuesResponse.recommended_values, fieldValue);

        return createValueOptions(buckets) ?? [];
      }) as Promise<TOption[]>;
    },
    [
      state.jobIds,
      state.quickFilters,
      state.decisionFilters,
      state.consensusFilters,
      getEndDateAsISO,
      getStartDateAsISO,
      postFiltersValues,
      jsonConvert,
      state.excludeProcessed,
      state.includeProcessed
    ]
  );

  const onFiltersChange = useCallback(() => {
    setTablePage(1);
  }, [setTablePage]);

  const advancedFilters = AdvancedFilters.useAdvancedFilters({
    fields: state.filtersFields,
    getValueOptions,
    initialFilters: state.advancedFilters,
    onFiltersChange: onFiltersChange
  });

  const setQuickFilters = useCallback((quickFilters: FeedbackQuickFilters) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      quickFilters,
      reloadFeedbackData: true,
      tablePage: feedbackUnifiedInitialState.tablePage
    }));
  }, []);

  const clearQuickFilters = useCallback(() => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      quickFilters: new FeedbackQuickFilters(),
      reloadFeedbackData: true,
      tablePage: feedbackUnifiedInitialState.tablePage
    }));
  }, []);

  const setDecisionFilters = useCallback((decisionFilters: string[]) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      decisionFilters,
      reloadFeedbackData: true,
      tablePage: feedbackUnifiedInitialState.tablePage
    }));
  }, []);

  const clearDecisionFilters = useCallback(() => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      decisionFilters: [],
      reloadFeedbackData: true,
      tablePage: feedbackUnifiedInitialState.tablePage
    }));
  }, []);

  const setConsensusFilters = useCallback((consensusFilters: string[]) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      consensusFilters,
      reloadFeedbackData: true,
      tablePage: feedbackUnifiedInitialState.tablePage
    }));
  }, []);

  const clearConsensusFilters = useCallback(() => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      decisionFilters: [],
      reloadFeedbackData: true,
      tablePage: feedbackUnifiedInitialState.tablePage
    }));
  }, []);

  const clearAdditionalFilters = useCallback(() => {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
    setQuickFilters(new FeedbackQuickFilters(null, null, null));
    setDecisionFilters([]);
    setConsensusFilters([]);
  }, [setQuickFilters, setConsensusFilters, setDecisionFilters]);

  // @ts-ignore
  const additionalFilters: TAdditionalFilter[] = useMemo(() => {
    const requiringAttentionFilters =
      state.quickFilters.requiring_attention &&
      state.quickFilters.requiring_attention.map((attention) => {
        return mapSingleFilterValue(t(`feedback:quickFilters:${attention}`), t('feedback:quickFilters:requiring_attention'), () =>
          setQuickFilters(
            new FeedbackQuickFilters(
              state.quickFilters.requiring_attention?.filter((contextStateAttention) => contextStateAttention != attention),
              state.quickFilters.feedback,
              state.quickFilters.training_status
            )
          )
        );
      });
    const feedbackFilters =
      state.quickFilters.feedback &&
      mapSingleFilterValue(t(`feedback:quickFilters:${state.quickFilters.feedback}`), t('feedback:quickFilters:feedback'), () =>
        setQuickFilters(
          // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
          new FeedbackQuickFilters(state.quickFilters.requiring_attention, null, state.quickFilters.training_status)
        )
      );
    const trainingStatusFilters =
      state.quickFilters.training_status &&
      mapSingleFilterValue(
        t(`feedback:quickFilters:${state.quickFilters.training_status}`),
        t('feedback:quickFilters:training_status'),
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
        () => setQuickFilters(new FeedbackQuickFilters(state.quickFilters.requiring_attention, state.quickFilters.feedback, null))
      );

    const decisionFilters = state.decisionFilters.map((filterValue) => {
      const label = state.jobCustomLabels?.find((customLabel) => customLabel.id === filterValue)?.name || filterValue;

      return mapSingleFilterValue(
        filterValue === ARCANNA_NO_DECISION ? ARCANNA_NO_DECISION_DISPLAY_VALUE : label,
        t('common:arcannaDecision'),
        () => setDecisionFilters(state.decisionFilters.filter((item) => item !== filterValue))
      );
    });

    const consensusFilters = state.consensusFilters.map((filterValue) => {
      const label = state.jobCustomLabels?.find((customLabel) => customLabel.id === filterValue)?.name || filterValue;

      return mapSingleFilterValue(
        filterValue === FeedbackBucketState.Undecided ? t('feedback:bucketExpand:undecided') : label,
        t('job:jobDashboardFlow:consensus'),
        () => setConsensusFilters(state.consensusFilters.filter((item) => item !== filterValue))
      );
    });

    const filters = [feedbackFilters, trainingStatusFilters, ...decisionFilters, ...consensusFilters].filter(Boolean);

    if (requiringAttentionFilters) {
      filters.push(...requiringAttentionFilters);
    }
    return filters;
  }, [
    t,
    state.jobCustomLabels,
    state.decisionFilters,
    state.consensusFilters,
    state.quickFilters,
    setQuickFilters,
    setConsensusFilters,
    setDecisionFilters
  ]);

  const setPrepareConfirm = useCallback((prepareConfirm: boolean) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      prepareConfirm
    }));
  }, []);

  const setPrepareClear = useCallback((prepareClear: boolean) => {
    setState((current: TFeedbackUnifiedState) => ({
      ...current,
      prepareClear
    }));
  }, []);

  const closeExpandedBucketRow = useCallback(() => {
    setExpandedBucketRow(undefined, undefined);
  }, [setExpandedBucketRow]);

  const value: TFeedbackUnifiedContext & TFeedbackContext = useMemo(
    () => ({
      ...sharedContext,
      state,
      setLoading,
      setJobIds,
      setJobInfos,
      setJobCustomLabels,
      setHoveredRowId,
      setDates,
      setTableData,
      setTablePage,
      setTablePageSize,
      setTableTotalSize,
      setReloadFeedbackData,
      setSelectedRowKeys,
      setSelectedRows,
      setSelectedFeedback,
      setTableSort,
      setTableFilters,
      setFiltersFields,
      setEditLabelOnSelected,
      setEditAll,
      setExpandedBucketRow,
      advancedFilters,
      additionalFilters,
      setQuickFilters,
      clearQuickFilters,
      setDecisionFilters,
      clearDecisionFilters,
      setConsensusFilters,
      clearConsensusFilters,
      clearAdditionalFilters,
      setExpandedBucketByIndex,
      setPrepareConfirm,
      setPrepareClear,
      closeExpandedBucketRow
    }),
    [
      sharedContext,
      additionalFilters,
      advancedFilters,
      clearAdditionalFilters,
      clearConsensusFilters,
      clearDecisionFilters,
      clearQuickFilters,
      closeExpandedBucketRow,
      setConsensusFilters,
      setDates,
      setDecisionFilters,
      setEditAll,
      setEditLabelOnSelected,
      setExpandedBucketByIndex,
      setExpandedBucketRow,
      setFiltersFields,
      setHoveredRowId,
      setJobCustomLabels,
      setJobIds,
      setJobInfos,
      setLoading,
      setPrepareClear,
      setPrepareConfirm,
      setQuickFilters,
      setReloadFeedbackData,
      setSelectedFeedback,
      setSelectedRowKeys,
      setSelectedRows,
      setTableData,
      setTableFilters,
      setTablePage,
      setTablePageSize,
      setTableSort,
      setTableTotalSize,
      state
    ]
  );

  return <FeedbackUnifiedContext.Provider value={value}>{children}</FeedbackUnifiedContext.Provider>;
}

export function useFeedbackUnifiedContext<T>(selector: ContextSelector<TFeedbackUnifiedContext & TFeedbackContext, T>) {
  return useContextSelector(FeedbackUnifiedContext, selector);
}

export default FeedbackUnifiedProvider;

import { AdvancedFilters } from '../../../../components/Filters';
import _ from 'lodash';

export const getDisplayedValue = (columnValue: string | undefined) => {
  if (_.isNil(columnValue)) {
    return 'n/a';
  }

  if (!columnValue) {
    return <AdvancedFilters.EmptyStringTooltip />;
  }

  return columnValue;
};

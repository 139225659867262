import useActiveDecisionPoints from './useActiveDecisionPoints';
import { useFeedbackBucketDetails } from '../../../../../../data-access';
import { useEffect, useState } from 'react';
import { ArcannaTableCell } from '../../../../../shared/components';
import { TFilterItem, TUseAdvancedFilters, AdvancedFilters } from '@arcanna/components';
import styles from './styles.module.css';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import { BucketDecisionPoint } from 'src/components/shared/models/feedback/FeedbackBucketDetailsResponse';

const ONLY_DP_NAME = [
  {
    key: 'feature_name',
    title: 'Name',
    dataIndex: 'feature_name'
  }
];

type useDecisionPointsTableDataProps = {
  jobId: number;
  bucketId?: string;
  showValues?: boolean;
  searchValue?: string;
  filterFields: FeedbackFiltersFieldsRecord[];
  advancedFilters: TUseAdvancedFilters;
};

export default function useDecisionPointsTableData({
  jobId,
  bucketId,
  showValues = false,
  searchValue,
  filterFields,
  advancedFilters
}: useDecisionPointsTableDataProps) {
  const { activeDecisionPoints, loading } = useActiveDecisionPoints({ jobId });
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
  const bucketDetailsQuery = useFeedbackBucketDetails(jobId, bucketId);
  const [tableData, setTableData] = useState<BucketDecisionPoint[]>([]);
  // eslint-disable-next-line
  const [columns, setColumns] = useState<any[]>([]);
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(
    function filterTableBySearchValue() {
      setTableData(
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        bucketDetailsQuery.data?.bucket.decisionPoints.filter(
          (e) =>
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            e?.featureName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            e?.value?.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    },
    [searchValue, bucketDetailsQuery.data]
  );

  useEffect(() => {
    if (showValues) {
      setTableData(bucketDetailsQuery.data?.bucket.decisionPoints ?? []);
      setLoadingTable(false);
      setColumns([
        {
          key: 'featureName',
          title: 'Name',
          dataIndex: 'featureName',
          width: 264
        },
        {
          key: 'value',
          title: 'Value',
          render: (record: { featureName?: string; value?: string }) => {
            return (
              <ArcannaTableCell
                filterFields={filterFields}
                displayFilters={false}
                value={record.value}
                columnName={record.featureName}
                className={styles.valueTableColumn}
                appliedAdvancedFilters={advancedFilters.activeFilters}
                onAddFilter={(filterItem: TFilterItem) => {
                  const cellSource = filterFields.find((filterField) => filterField.name === record.featureName);

                  cellSource &&
                    cellSource.source &&
                    advancedFilters.addAdvancedFilter({
                      ...filterItem,
                      label: cellSource.name,
                      field: cellSource.source,
                      ...(AdvancedFilters.getIsOperatorWithValue(filterItem.operator)
                        ? {
                            value: record.value
                          }
                        : {}),
                      status: 'active'
                    });
                }}
              >
                {record.value}
              </ArcannaTableCell>
            );
          }
        }
      ]);
    }

    if (!showValues) {
      setTableData(activeDecisionPoints);
      setColumns(ONLY_DP_NAME);
      setLoadingTable(false);
    }

    if (loading || bucketDetailsQuery.isLoading) {
      setLoadingTable(true);
    }
    // eslint-disable-next-line
  }, [bucketDetailsQuery.data, activeDecisionPoints, bucketDetailsQuery.isLoading, loading, filterFields]);

  return {
    tableData,
    columns,
    loadingTable
  };
}

import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserSummaryRecord')
export class UserSummaryRecord {
  @JsonProperty('id', String)
  id: string | undefined;

  @JsonProperty('username', String)
  username: string | undefined;

  @JsonProperty('display_name', String)
  display_name: string | undefined;

  @JsonProperty('email', String)
  email: string | undefined;

  @JsonProperty('roles', [String])
  roles: string[] = [];

  @JsonProperty('external', Boolean)
  external?: boolean = false;
}

import { useEffect, useState } from 'react';
import { useFeedbackBucketDetails, useJobInfo } from '../../../../../../../data-access';

export function useConsensusPercent(jobId: number, bucketId: string) {
  const [percent, setPercent] = useState(0);

  const feedbackBucketDetailsQuery = useFeedbackBucketDetails(jobId, bucketId);
  const jobInfoQuery = useJobInfo(jobId);

  useEffect(() => {
    if (feedbackBucketDetailsQuery.data && jobInfoQuery.data) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      let percentLabelMap: Record<string, number> = jobInfoQuery.data.info.advancedSettings.customLabels.reduce(
        (result, customLabel) => {
          return {
            ...result,
            [customLabel.id]: 0
          };
        },
        {}
      );
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const userLabels = feedbackBucketDetailsQuery.data?.bucket.userLabels.entries;

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      userLabels.forEach((userLabel) => {
        const label = userLabel.label;
        percentLabelMap = {
          ...percentLabelMap,
          // @ts-expect-error TS(2464): A computed property name must be of type 'string',...
          [label]: percentLabelMap[label] + 1
        };

        const max = Math.max(...Object.values(percentLabelMap));
        const sum = Object.values(percentLabelMap).reduce((result, value) => result + value, 0);

        setPercent((max / sum) * 100);
      });
    }
  }, [feedbackBucketDetailsQuery.data, jobInfoQuery.data]);

  return { percent };
}

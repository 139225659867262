export const REQUIRING_ATTENTION_FILTERS: ReadonlyArray<string> = [
  'consensus_changed_from_last_retrain',
  'consensus_flipping',
  'low_consensus_score',
  'outlier',
  'low_confidence_score',
  'undecided_consensus'
];

export const FEEDBACK_FILTERS: ReadonlyArray<string> = [
  'buckets_with_my_feedback',
  'buckets_without_my_feedback',
  'buckets_with_any_feedback',
  'buckets_without_any_feedback',
  'buckets_without_full_consensus'
];

export const TRAINING_STATUS_FILTERS: ReadonlyArray<string> = ['in_model', 'new'];

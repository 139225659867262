import { JsonObject, JsonProperty } from 'json2typescript';
import { CodeBlockSaveRequest, EnvVariableRecord } from '@arcanna/models/CodeBlock';
import { ResponseCommon } from '@arcanna/models/utils';

@JsonObject('CodeBlockGetResponse')
export class CodeBlockGetResponse extends ResponseCommon {
  @JsonProperty('source_code', String)
  sourceCode: string;

  @JsonProperty('env_variables', [EnvVariableRecord])
  envVariables: EnvVariableRecord[];

  @JsonProperty('input_test', String)
  inputTest: string;

  @JsonProperty('enabled', Boolean)
  enabled: boolean;

  @JsonProperty('integration_title', String)
  integrationTitle: string;

  @JsonProperty('integration_type_id', Number)
  integrationTypeId: number;

  @JsonProperty('description', String)
  description: string | null;
}

type TConstructorParams = {
  sourceCode: CodeBlockSaveRequest['sourceCode'];
  envVariables: EnvVariableRecord[];
  inputTest: CodeBlockSaveRequest['inputTest'];
  enabled: CodeBlockSaveRequest['enabled'];
  integrationTitle: CodeBlockSaveRequest['integrationTitle'];
  integrationTypeId: CodeBlockSaveRequest['integrationTypeId'];
  description: CodeBlockSaveRequest['description'];
};

export function constructCodeBlockGetResponse({
  sourceCode,
  envVariables,
  inputTest,
  enabled,
  integrationTitle,
  integrationTypeId,
  description
}: TConstructorParams): CodeBlockGetResponse {
  const instance = new CodeBlockGetResponse();
  instance.sourceCode = sourceCode;
  instance.envVariables = envVariables;
  instance.inputTest = inputTest;
  instance.enabled = enabled;
  instance.integrationTitle = integrationTitle;
  instance.integrationTypeId = integrationTypeId;
  instance.description = description;
  return instance;
}

import { Button, Stack, Tooltip } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PermissionsCheck from 'src/components/shared/components/Permissions/Permissions';
import { useFeedbackBulkContext } from '../../../../../FeedbackBulkContext/useFeedbackBulkContext';
import CustomLabelByJob from '../../../../../FeedbackTable/models/CustomLabelByJob';

type TSaveCancelAreaProps = {
  editLabelsCount: number;
  selectedLabel: CustomLabelByJob | null;
  prepareConfirm: boolean;
  prepareClear: boolean;
  onFinish: () => void;
};

function SaveCancelArea({ editLabelsCount, selectedLabel, prepareConfirm, prepareClear, onFinish }: TSaveCancelAreaProps) {
  const { t } = useTranslation(['common', 'feedback']);
  const { handleCancelBulkFeedback, handleSaveBulkFeedback: handleConfirmBulkFeedback } = useFeedbackBulkContext();

  const handleSave = () => {
    handleConfirmBulkFeedback();
    onFinish();
  };

  const handleCancel = () => {
    handleCancelBulkFeedback();
    onFinish();
  };

  const isSaveCancelDisabled = useMemo(
    () => (editLabelsCount === 0 || (editLabelsCount > 0 && selectedLabel == null)) && !prepareConfirm && !prepareClear,
    [editLabelsCount, selectedLabel, prepareConfirm, prepareClear]
  );

  // eslint-disable-next-line
  const handleKeyDown = (event: KeyboardEvent) => {
    const confirmKey = (event.ctrlKey && event.key === 's') || event.key === 'Enter';
    const cancelKey = event.ctrlKey && event.key === 'x';

    if (!isSaveCancelDisabled && confirmKey) {
      handleSave();
      event.preventDefault();
    } else if (!isSaveCancelDisabled && cancelKey) {
      handleCancel();
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown, false);
  }, [handleKeyDown]);

  return (
    <Stack direction="row" gap="8px" alignItems="center">
      <PermissionsCheck testId="save">
        <Tooltip title={t('feedback:ctrlS')} placement="top">
          <Button
            disabled={isSaveCancelDisabled}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSave}
            data-test-id="save-feedback-button"
          >
            <span>{t('common:savePlaceholder', { placeholder: editLabelsCount })}</span>
          </Button>
        </Tooltip>
      </PermissionsCheck>

      <PermissionsCheck testId={'cancel'}>
        <Tooltip title={t('feedback:ctrlX')} placement="top">
          <Button
            variant="outlined"
            disabled={isSaveCancelDisabled}
            color="secondary"
            size="small"
            onClick={handleCancel}
            data-test-id="cancel-feedback-button"
          >
            {t('common:cancel')}
          </Button>
        </Tooltip>
      </PermissionsCheck>
    </Stack>
  );
}

export default SaveCancelArea;

import { withTransaction } from '@elastic/apm-rum-react';
import { UserKeyProvider } from './UserKey.context';
import { UserKey } from './UserKey';

// eslint-disable-next-line
function UserWrapper(props: any) {
  return (
    <UserKeyProvider>
      <UserKey {...props} />
    </UserKeyProvider>
  );
}
export default withTransaction('UserWrapper', 'component')(UserWrapper);

import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import { EmptyState, Spinner } from '@arcanna/generic';
import { CustomLabel } from '@arcanna/components';
import useDecisionPointsTableData from 'src/components/pages/Main/Jobs/components/DecisionPointsTable/useDecisionPointsTableData';
import { useJobInfo, useFeedbackBucketDetails } from 'src/data-access';
import { useFeedbackBucketSimilarity } from 'src/data-access/feedback/useFeedbackBucketSimilarity';
import { noDecisionResult } from 'src/components/pages/Main/Jobs/helper';
import SimilarBucketsSelect from './components/SimilarBucketsSelect';
import StyledAIExplainabilityBody from './StyledAIExplainabilityBody.styles';
import { useBucketDrawerContext } from '../../context/useBucketDrawerContext';

type AIExplainabilityBodyProps = {
  jobId: number;
  bucketId: string;
  shortBucketId: string;
};

function AIExplainabilityBody({ jobId, bucketId, shortBucketId }: AIExplainabilityBodyProps) {
  const { t } = useTranslation(['feedback']);
  const theme = useTheme();

  const { filterFields, advancedFilters } = useBucketDrawerContext();

  const jobInfoQuery = useJobInfo(jobId);
  const jobCustomLabels = useMemo(() => jobInfoQuery.data?.info?.advancedSettings.customLabels ?? [], [jobInfoQuery.data]);
  const feedbackBucketDetailsQuery = useFeedbackBucketDetails(jobId, bucketId);
  const [differentValues, setDifferentValues] = useState<Array<boolean>>([]);
  const [displayedDifferentValues, setDisplayedDifferentValues] = useState<Array<boolean>>([]);
  const [displayDifferences, setDisplayDifferences] = useState<boolean>(false);

  const { tableData, loadingTable } = useDecisionPointsTableData({
    jobId,
    bucketId,
    showValues: true,
    searchValue: '',
    filterFields,
    advancedFilters
  });

  const feedbackBucketSimilarityQuery = useFeedbackBucketSimilarity(jobId, bucketId);
  const [activeSimilarityIndex, setActiveSimilarityIndex] = useState(0);

  const currentBucketLabel = useMemo(
    () => jobCustomLabels.find((label) => label.id == feedbackBucketDetailsQuery.data?.bucket.result) || noDecisionResult,
    [feedbackBucketDetailsQuery.data, jobCustomLabels]
  );

  const similarityRecords = useMemo(() => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (feedbackBucketSimilarityQuery.data?.topHits.length > 0) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      return feedbackBucketSimilarityQuery.data.topHits;
    }
    return null;
  }, [feedbackBucketSimilarityQuery.data]);

  const currentSimilarityRecord = useMemo(() => {
    if (similarityRecords != null && similarityRecords.length > 0 && tableData != null) {
      const currentDifferentValue = tableData.map((entry) => {
        const similarFeatureValue = similarityRecords[activeSimilarityIndex].alert.find(
          // eslint-disable-next-line
          (nameValuePair) => nameValuePair.name == (entry as any).featureName
        )?.value;
        // eslint-disable-next-line
        return (entry as any).value != similarFeatureValue;
      });
      setDifferentValues(currentDifferentValue);
      setDisplayedDifferentValues(currentDifferentValue);
      setTimeout(() => setDisplayedDifferentValues(tableData.map(() => false)), 1000);
      return similarityRecords[activeSimilarityIndex];
    }
    return null;
  }, [activeSimilarityIndex, similarityRecords, tableData]);

  const currentSimilarityLabel = useMemo(
    () => jobCustomLabels.find((label) => label.id == currentSimilarityRecord?.arcannaDecision),
    [currentSimilarityRecord?.arcannaDecision, jobCustomLabels]
  );

  const isLoadingContent =
    loadingTable || jobInfoQuery.isLoading || feedbackBucketSimilarityQuery.isLoading || feedbackBucketDetailsQuery.isLoading;

  const isEmptyContent =
    feedbackBucketSimilarityQuery.isError ||
    feedbackBucketSimilarityQuery.data == null ||
    feedbackBucketSimilarityQuery.data.topHits.length == 0 ||
    jobInfoQuery.isError ||
    feedbackBucketDetailsQuery.isError;

  const toggleDisplayDifferences = useCallback(() => {
    if (!displayDifferences) {
      // transition from toggled off -> on
      setDisplayedDifferentValues(differentValues);
      setDisplayDifferences(true);
    } else {
      // transition from toggle on -> off
      setDisplayedDifferentValues([]);
      setDisplayDifferences(false);
    }
  }, [displayDifferences, differentValues, setDisplayedDifferentValues, setDisplayDifferences]);

  return (
    <>
      {isLoadingContent ? (
        <Spinner isOverlay />
      ) : (
        <>
          {isEmptyContent ? (
            <EmptyState
              title={t('feedback:bucketExpand.noSimilarHitsTitle')}
              subtitle={t('feedback:bucketExpand.noSimilarHitsSubtitle')}
              isCard
            />
          ) : (
            <StyledAIExplainabilityBody>
              <TableContainer sx={{ maxHeight: '525px', overflowY: 'auto' }}>
                <Table size="small" stickyHeader>
                  <TableHead className="MuiTableHead-zebra">
                    <TableRow>
                      <TableCell sx={{ height: '0px' }}>
                        <Stack direction="column" sx={{ height: '100%', justifyContent: 'end', paddingBottom: '24px' }}>
                          <Stack direction="row" gap="8px">
                            <Switch size="small" checked={displayDifferences} onClick={toggleDisplayDifferences} />
                            <Typography variant="subtitle2">{t('feedback:bucketExpand.hightlightDifferences')}</Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell className="tableHeaderCell">
                        <Stack direction="column">
                          <Typography variant="subtitle1" className="currentBucketHeader">
                            {t('feedback:bucketExpand.currentBucket')}
                          </Typography>
                          <Box className="currentBucketLabelWrapper">
                            {currentBucketLabel != null && (
                              <CustomLabel
                                id={currentBucketLabel.id}
                                name={currentBucketLabel.name}
                                hexColor={currentBucketLabel.hexColor}
                                className="customLabelHeader"
                              />
                            )}
                          </Box>
                        </Stack>
                      </TableCell>
                      <TableCell className="tableHeaderCell">
                        <Stack direction="column">
                          <SimilarBucketsSelect
                            customLabels={jobCustomLabels}
                            // @ts-expect-error TS(2322): Type 'FeedbackBucketSimilarityRecord[] | null' is ...
                            records={similarityRecords}
                            activeIndex={activeSimilarityIndex}
                            onChange={setActiveSimilarityIndex}
                          />
                          <Box className="similarBucketLabelWrapper">
                            {currentSimilarityLabel != null && (
                              <CustomLabel
                                id={currentSimilarityLabel.id}
                                name={currentSimilarityLabel.name}
                                hexColor={currentSimilarityLabel.hexColor}
                                className="customLabelHeader"
                              />
                            )}
                          </Box>
                        </Stack>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="featureName" sx={{ top: '113px' }}>
                        <Typography variant="subtitle2" className="featureName">
                          {t('feedback:bucketId')}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ top: '113px' }}>
                        <Typography variant="subtitle2" className="featureValue">
                          {shortBucketId}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ top: '113px' }}>
                        <Typography variant="subtitle2" className="featureValue">
                          {currentSimilarityRecord?.bucketId}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="MuiTableBody-zebra">
                    {tableData.map((entry, index) => {
                      return (
                        <TableRow
                          key={index}
                          style={
                            displayedDifferentValues[index]
                              ? {
                                  backgroundColor: alpha(theme.palette.info.light, 0.3)
                                }
                              : {}
                          }
                        >
                          <TableCell>
                            <Typography variant="subtitle2" className="featureName">
                              {/* eslint-disable-next-line */}
                              {(entry as any).featureName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2" className="featureValue">
                              {/* eslint-disable-next-line */}
                              {(entry as any).value}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2" className="featureValue">
                              {
                                currentSimilarityRecord?.alert.find(
                                  // eslint-disable-next-line
                                  (nameValuePair) => nameValuePair.name == (entry as any).featureName
                                )?.value
                              }
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledAIExplainabilityBody>
          )}
        </>
      )}
    </>
  );
}

export default AIExplainabilityBody;

import { withTransaction } from '@elastic/apm-rum-react';
import { UserProvider } from './User.context';
import { UserAdd } from './UserAdd';

// eslint-disable-next-line
function UserAddWrapper(props: any) {
  return (
    <UserProvider>
      <UserAdd {...props} />
    </UserProvider>
  );
}
export default withTransaction('UserAddWrapper', 'component')(UserAddWrapper);

import { ReactNode, useMemo, useState } from 'react';
import { LabelContext } from './LabelContext';
import CustomLabelByJob from '../FeedbackTable/models/CustomLabelByJob';

type TFeedbackProviderProps = {
  children: ReactNode;
};

function LabelContextProvider({ children }: TFeedbackProviderProps) {
  const [editLabelOnSelected, setEditLabelOnSelected] = useState<CustomLabelByJob | null>(null);
  const [prepareConfirm, setPrepareConfirm] = useState<boolean>(false);
  const [prepareClear, setPrepareClear] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      editLabelOnSelected,
      setEditLabelOnSelected,
      prepareConfirm,
      setPrepareConfirm,
      prepareClear,
      setPrepareClear
    }),
    [editLabelOnSelected, setEditLabelOnSelected, prepareConfirm, setPrepareConfirm, prepareClear, setPrepareClear]
  );

  return <LabelContext.Provider value={value}>{children}</LabelContext.Provider>;
}

export default LabelContextProvider;

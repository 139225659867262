import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('JobRetrainMultipleResponse')
export class JobRetrainMultipleResponse {
  @JsonProperty('details', String)
  details: string;

  @JsonProperty('failed_job_ids', [String])
  failed_job_ids: string[] = [];

  @JsonProperty('failed_job_titles', [String])
  failed_job_titles: string[] = [];

  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;
}

import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';
import { JobActivityLogResponse } from '../../components/shared/models/job/JobActivityLogResponse';

type UseJobActivityLog = {
  jobId: number;
};
export function useJobActivityLog({ jobId }: UseJobActivityLog) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const requestPayload = new CommonRequest();

  const url = config.api.jobActivityLog.replace(':id', `${jobId}`);

  return useQuery(
    [url],
    () =>
      axios
        .post<{ resource: JobActivityLogResponse }>(url, requestPayload)
        .then((responseData: AxiosResponse<{ resource: JobActivityLogResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, JobActivityLogResponse)
        ),
    {
      enabled: Boolean(jobId)
    }
  );
}

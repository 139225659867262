import { JsonObject, JsonProperty } from 'json2typescript';
import { FeedbackCommonFiltersValuesRequest } from 'src/components/shared/models/feedback/FeedbackCommonFiltersValuesRequest';
import { FeedbackAdvancedFilterRecord } from './FeedbackAdvancedFilterRecord';
import { FeedbackQuickFilters } from './FeedbackQuickFilters';

@JsonObject('FeedbackUnifiedFiltersValuesRequest')
export class FeedbackUnifiedFiltersValuesRequest extends FeedbackCommonFiltersValuesRequest {
  @JsonProperty('job_ids', [Number])
  job_ids?: number[];

  constructor(
    job_ids: number[],
    field_name: string,
    start_date: string | null,
    end_date: string | null,
    exclude_processed: boolean,
    include_processed: boolean,
    filters: FeedbackAdvancedFilterRecord[],
    quick_filters: FeedbackQuickFilters,
    decision_filters: string[],
    consensus_filters: string[]
  ) {
    super(
      field_name,
      start_date,
      end_date,
      exclude_processed,
      include_processed,
      filters,
      quick_filters,
      decision_filters,
      consensus_filters
    );
    this.job_ids = job_ids;
  }
}

import ArcannaChip from '../../ArcannaChip';
import { useTranslation } from 'react-i18next';
import { JobCollectingDataSpinner } from './JobCollectingDataSpinner/JobCollectingDataSpinner';
import { useCalculateCloneProgress } from '../../../../../data-access/job/useCalculateCloneProgress';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { config } from '../../../../../config';

export default function JobCloningTag({ jobId }: { jobId: number }) {
  const { t } = useTranslation(['job']);

  const cloneStatus = useCalculateCloneProgress({ jobId });
  const queryClient = useQueryClient();

  useEffect(
    function refreshJobInfoOnCloningFinished() {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (cloneStatus.data?.progress >= 100) {
        queryClient.invalidateQueries([config.api.jobsSummary]);
      }
    },
    // eslint-disable-next-line
    [cloneStatus.data?.progress]
  );

  return (
    <ArcannaChip color={'light-blue'}>
      <div className="flex flex-align-center" style={{ gap: 8 }}>
        <JobCollectingDataSpinner />
        {t('job:jobState.cloningStatus')}{' '}
        {cloneStatus.data?.progress !== undefined ? Math.round(cloneStatus.data?.progress) + '%' : null}
      </div>
    </ArcannaChip>
  );
}

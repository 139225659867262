import { Row, Col, Popover, Spin } from 'antd';
import styles from './styles.module.css';
import { ChevronDownIcon, ChevronUpIcon } from '../../../../../../../themes/icons';
import { DeleteArrowIcon } from '../../../../../../../themes/icons/DeleteArrowIcon';
import { useTranslation } from 'react-i18next';
import { FiltersCheckboxGroup } from './FiltersCheckboxGroup';
import { useFilters, Filters } from './useFilters.hook';
import { Button } from '@mui/material';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';

type FeedbackFlowConsensusDecisionFiltersProps = {
  decisionFilters: string[];
  consensusFilters: string[];
  setDecisionFilters: (decisionFilters: string[]) => void;
  setConsensusFilters: (consensusFilters: string[]) => void;
  jobInfos: JobInfoResponse[];
};

export default function FeedbackFlowConsensusDecisionFilters({
  decisionFilters,
  consensusFilters,
  setDecisionFilters,
  setConsensusFilters,
  jobInfos
}: FeedbackFlowConsensusDecisionFiltersProps) {
  const { t } = useTranslation(['job', 'feedback', 'common']);
  const {
    open,
    setOpen,
    getGroupOptions,
    onChangeDecision,
    onChangeConsensus,
    onCheckAllDecision,
    onCheckAllConsensus,
    handleClearAll
  } = useFilters({ jobInfos, setDecisionFilters, setConsensusFilters });

  const decisionGroupOptions = getGroupOptions(Filters.DECISION);
  const totalDecisionFiltersCount = decisionGroupOptions.length;
  const appliedDecisionFiltersCount = decisionFilters.length;
  const decisionAllChecked = totalDecisionFiltersCount === appliedDecisionFiltersCount;
  const decisionIndeterminate = 0 < appliedDecisionFiltersCount && appliedDecisionFiltersCount < totalDecisionFiltersCount;

  const consensusGroupOptions = getGroupOptions(Filters.CONSENSUS);
  const totalConsensusFiltersCount = consensusGroupOptions.length;
  const appliedConsensusFiltersCount = consensusFilters.length;
  const consensusAllChecked = totalConsensusFiltersCount === appliedConsensusFiltersCount;
  const consensusIndeterminate = 0 < appliedConsensusFiltersCount && appliedConsensusFiltersCount < totalConsensusFiltersCount;

  const content = (
    <Spin spinning={jobInfos.length == 0} size={'small'}>
      <div className={styles.container}>
        <Row>
          <Col className={styles.decisionFilters}>
            <FiltersCheckboxGroup
              checkAllTitle={t('common:arcannaDecision')}
              handleCheckAll={onCheckAllDecision}
              checkedAllState={decisionAllChecked}
              handleCheckGroup={onChangeDecision}
              checkboxGroupOptions={decisionGroupOptions}
              checkedGroupList={decisionFilters}
              indeterminate={decisionIndeterminate}
            />
          </Col>
          <Col className={styles.consensusFilters}>
            <FiltersCheckboxGroup
              checkAllTitle={t('job:jobDashboardFlow:consensus')}
              handleCheckAll={onCheckAllConsensus}
              checkedAllState={consensusAllChecked}
              handleCheckGroup={onChangeConsensus}
              checkboxGroupOptions={consensusGroupOptions}
              checkedGroupList={consensusFilters}
              indeterminate={consensusIndeterminate}
            />
          </Col>
        </Row>
        {decisionFilters.length || consensusFilters.length > 0 ? (
          <Row className={styles.deleteFilters}>
            <Col>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearAll}
                data-test-id="clear-all-quick-filters"
                endIcon={<DeleteArrowIcon stroke={styles.deleteArrow} />}
                size="small"
              >
                {t('feedback:quickFilters:clearAllQFButton')}
              </Button>
            </Col>
          </Row>
        ) : null}
      </div>
    </Spin>
  );

  const countAppliedFilters = decisionFilters.length + consensusFilters.length;

  return (
    <Popover
      content={content}
      trigger="click"
      open={open}
      onOpenChange={() => {
        setOpen(!open);
      }}
    >
      <Button
        variant={open ? 'containedHover' : 'contained'}
        color="secondary"
        size="small"
        endIcon={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
      >
        {t('feedback:consensusDecisionFilters:title')} ({countAppliedFilters})
      </Button>
    </Popover>
  );
}

import { createContext, useCallback, useContext, useState } from 'react';
import { UserKeyRecord } from '../../../shared/models/user-key/UserKeyRecord';
import { UserKeyCreateResponse } from '../../../shared/models/user-key/UserKeyCreateResponse';

// eslint-disable-next-line
export const UserKeyContext = createContext<UserKeyContextType>(null as any);

export type UserKeyStateType = {
  id: number;
  loading: boolean;
  reloadUserKeyData: boolean;
  tableData: UserKeyRecord[];
  initialTableData: UserKeyRecord[];
  userKeyCreateResponse: UserKeyCreateResponse;
};

export type UserKeyContextType = {
  state: UserKeyStateType;
  setLoading: (loading: boolean) => void;
  setReloadUserKeyData: (reloadUserKeyData: boolean) => void;
  setTableData: (tableData: UserKeyRecord[]) => void;
  setInitialTableData: (tableData: UserKeyRecord[]) => void;
  setUserKeyCreateResponse: (userKeyCreateResponse: UserKeyCreateResponse) => void;
};

// eslint-disable-next-line
export function UserKeyProvider(props: any) {
  const [state, setState] = useState<UserKeyStateType>({
    id: Math.random(),
    loading: false,
    reloadUserKeyData: true,
    tableData: [],
    initialTableData: [],
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'UserKeyCrea...
    userKeyCreateResponse: null
  });

  const setLoading = useCallback((loading: boolean) => {
    setState((current: UserKeyStateType) => ({
      ...current,
      loading
    }));
  }, []);

  const setReloadUserKeyData = useCallback((reloadUserKeyData: boolean) => {
    setState((current: UserKeyStateType) => ({
      ...current,
      reloadUserKeyData
    }));
  }, []);

  const setTableData = useCallback((tableData: UserKeyRecord[]) => {
    setState((current: UserKeyStateType) => ({
      ...current,
      tableData
    }));
  }, []);

  const setInitialTableData = useCallback((initialTableData: UserKeyRecord[]) => {
    setState((current: UserKeyStateType) => ({
      ...current,
      initialTableData
    }));
  }, []);

  const setUserKeyCreateResponse = useCallback((userKeyCreateResponse: UserKeyCreateResponse) => {
    setState((current: UserKeyStateType) => ({
      ...current,
      userKeyCreateResponse
    }));
  }, []);

  const value: UserKeyContextType = {
    state,
    setLoading,
    setReloadUserKeyData,
    setTableData,
    setInitialTableData,
    setUserKeyCreateResponse
  };

  const { children } = props;
  return <UserKeyContext.Provider value={value}>{children}</UserKeyContext.Provider>;
}

export function useUserKeyContext(): UserKeyContextType {
  return useContext(UserKeyContext);
}

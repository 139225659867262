/**
 * If the REACT_APP_BACKEND_URL variable is 'null'
 * then the backend URL is current url + '/api' suffix
 */
let backendUrl = window._env_.REACT_APP_BACKEND_URL;

if (!window._env_.REACT_APP_BACKEND_URL) {
  backendUrl = window.location.origin;
}

// Transform secondary url to list
let backendSecondaryUrlsArray: string[] = [];
const backendSecondaryUrls = window._env_.REACT_APP_BACKEND_SECONDARY_URLS;
if (backendSecondaryUrls != null) {
  backendSecondaryUrlsArray = backendSecondaryUrls.split(',');
}

if (window._env_.REACT_APP_BACKEND_URL_SUFFIX) {
  backendUrl += window._env_.REACT_APP_BACKEND_URL_SUFFIX;
}

for (let i = 0; i < backendSecondaryUrlsArray.length; i++) {
  backendSecondaryUrlsArray[i] = backendSecondaryUrlsArray[i] + window._env_.REACT_APP_BACKEND_URL_SUFFIX;
}
// Add default url to secondary list as well
backendSecondaryUrlsArray.push(backendUrl);

const restApiToken = window._env_.REACT_APP_REST_API_TOKEN ? window._env_.REACT_APP_REST_API_TOKEN : 'api_token';
const monitoringApiKey = window._env_.REACT_APP_MONITORING_API_KEY
  ? window._env_.REACT_APP_MONITORING_API_KEY
  : 'monitoring_api_key';

const defaultConfig = {
  backendUrl: localStorage.getItem('backendUrl') || backendUrl,
  backendSecondaryUrlsArray,
  apmActive: JSON.parse(window._env_.REACT_APP_APM_ACTIVE),
  apmServerUrl: window._env_.REACT_APP_APM_SERVER_URL,
  apmServiceName: window._env_.REACT_APP_APM_SERVICE_NAME,
  openStreetMapService: window._env_.REACT_APP_OPEN_STREETMAP_SERVICE,
  restApiToken,
  monitoringApiKey,
  i18n: {
    lng: 'en',
    fallbackLng: 'en',
    whitelist: ['en', 'ro']
  },
  api: {
    userAuth: '/user/auth',
    userAuthAd: '/user/auth-ad',
    categoryList: '/category/list',
    clustersList: '/clusters',
    clustersIdsSuggestions: '/clusters/suggestions',
    clustersFilterValues: '/clusters/filters/values',
    contextEnrichmentSave: '/context-enrichment/:jobId/save',
    contextEnrichmentFetch: '/context-enrichment/:jobId/fetch',
    contextEnrichmentRequestDetails: '/context-enrichment/:jobId/request-details',
    contextEnrichmentById: '/context-enrichment/:jobId/:enrichmentId',
    contextEnrichmentList: '/context-enrichment/:jobId/list',
    contextEnrichmentLinearise: 'context-enrichment/linearise-response',
    contextEnrichmentUpdateStatus: '/context-enrichment/:jobId/update-status',
    jobsSummary: '/job/summary',
    jobsSummaryByCategory: '/job/summary/:categoryId',
    jobClone: '/job/:jobId/clone',
    eventInfo: '/event-explorer/event-info/:eventId',
    eventExplorerBucketInfo: '/event-explorer/bucket-info/:bucketId',
    feedbackStatisticsUsers: '/feedback/statistics/users',
    feedbackUsers: '/feedback/users',
    feedbackStatisticsBucketsCount: '/feedback/statistics/buckets-count',
    feedbackStatisticsEventsBreakdown: '/feedback/statistics/events-breakdown',
    feedbackStatisticsBucketsStatesBreakdown: '/feedback/statistics/buckets-states-breakdown',
    feedbackStatisticsConsensus: '/feedback/statistics/consensus',
    feedbackStatisticsProgress: '/feedback/statistics/progress',
    feedbackStatisticsModelAccuracy: '/feedback/statistics/model-accuracy',
    feedbackStatisticsKbIncrease: '/feedback/statistics/kb-increase',
    feedbackStatisticsLabelsUsed: '/feedback/statistics/labels-used',
    feedbackStatisticsMislabeled: '/feedback/statistics/mislabeled',
    feedbackStatisticsComputeMetrics: '/feedback/statistics/compute-metrics',
    feedbackBucketsBatch: '/feedback/buckets/batch',
    feedbackBucketsAlerts: '/feedback/buckets/alerts',
    feedbackBucketDetails: '/feedback/buckets/:bucketId',
    feedbackBucketsUnifiedBatch: '/feedback/buckets-unified/batch',
    feedbackBucketsOverwrite: '/feedback/buckets/overwrite',
    feedbackBucketSimilarity: '/feedback/buckets/:bucketId/similarity',
    feedbackBucketsUpdate: '/feedback/buckets/update',
    feedbackFiltersFields: '/feedback/filters/fields',
    generateBucketSummary: '/llm/summarization/bucket/:bucketId',
    clearFeedback: 'feedback/buckets/clear-feedback',
    integrationList: '/integration/list/:integrationId',
    integrationDelete: '/integration/delete/:integrationId',
    integrationAdd: '/integration/create',
    integrationUpdate: '/integration/update/:integrationId',
    integrationCheck: '/integration/check',
    integrationSubcategoryList: '/integration/subcategory/list/:subcategoryId',
    integrations: '/integration/summary',
    integrationSummary: '/integration/summary/:parameterType',
    integrationSummaryInput: '/integration/summary/input',
    integrationParametersOptions: '/integration/parameters/options',
    jobAlertsBatch: '/job/alerts/batch',
    jobAlertsBatchByAlertType: '/job/alerts/batch/:alertsType',
    jobAlertsStructure: '/job/alerts/structure',
    jobGraphStats: '/job/graph-stats',
    jobInfo: '/job/info/:id',
    jobJourney: '/job/info-journey/:id',
    jobHistory: '/job/:id/alert/:alertId/history',
    jobAlertsTimeseries: '/job/alerts/timeseries',
    bucketAlertsTimeseries: '/feedback/statistics/events-stats',
    jobGeneralStats: '/job/general-stats',
    jobRawAlert: '/job/:id/alert/:alertId/raw',
    jobGenericAlertsBatch: '/job/generic-alerts/batch',
    jobChangeStatus: '/job/:action',
    jobStatus: '/job/status/:id',
    jobRetrain: '/job/retrain',
    jobRetrainMultiple: '/job/retrain-multiple',
    isJobRetrainAvailable: '/job/retrain/enabled',
    jobUpdate: '/job/update/:id',
    jobIntegrationUpdateOnJob: '/job/update/:id/integration',
    jobCleaningResults: '/job/cleaning-results',
    jobIndicators: '/job/indicators',
    jobFeatureSelectionUnifiedUpdate: '/job/feature-selection/bulk/update',
    jobFeatureSelectionUnifiedSummary: '/job/feature-selection/group/summary',
    jobFeatureSelectionSummary: '/job/feature-selection/summary',
    jobFeatureSelectionUpdate: '/job/feature-selection/update',
    fieldInsights: '/job/feature-selection/stats',
    jobAlertRandom: '/job/:jobId/alert/random',
    jobColumnsSelectionSummary: '/job/column-selection/summary',
    jobColumnsSelectionUpdate: '/job/column-selection/update',
    licenseInfo: '/platform/license',
    platformSettingsInfo: '/platform/settings',
    platformSettingsUpdate: '/platform/settings/update',
    platformSettingsTelemetryUpdate: 'platform/settings/telemetry-opt-in',
    licenseLoad: '/platform/license/load',
    subcategoryList: '/subcategory/list',
    userKeyCreate: '/user-key/create',
    userKeyList: '/user-key/list',
    actionAggregations: '/action/aggregations',
    actionCategories: '/action/categories',
    actionList: '/action/list',
    componentAvailability: '/component/availability',
    bucketsRequiringFeedback: '/feedback/statistics/feedback-required',
    currentFeedbackBreakdown: '/feedback/statistics/current-feedback-breakdown',
    customFieldOperators: '/job/custom-field/operators',
    customFieldNextSequence: '/job/custom-field/next-sequence-number',
    createCustomField: '/job/custom-field/create',
    updateCustomField: '/job/custom-field/update',
    summaryCustomField: '/job/custom-field/summary',
    jobOverviewSplitStats: '/job/alerts/split-stats',
    confusionMatrix: 'feedback/statistics/confusion-matrix',
    staticRuleList: '/static-rule/list',
    updateStaticRule: '/static-rule/update',
    deleteStaticRule: '/static-rule/delete/:id',
    eventExplorerStructure: '/event-explorer/structure',
    makeAWish: '/make-a-wish/',
    roleNames: '/user/role_names',
    startJob: '/job/start',
    stopJob: '/job/stop',
    deleteJob: '/job/delete',
    authCheckKeepAlive: '/user/auth_check/keepalive',
    jobCreate: '/job/create',
    jobTestFirstBatch: '/job/test-input',
    jobMetricsUpdate: '/job/time-units/update',
    overallStatisticsDecisions: '/impact-dashboard/overall-statistics/decisions/:jobId',
    overallStatisticsPipeline: '/impact-dashboard/overall-statistics/pipeline/:jobId',
    overallStatisticsTimeMetrics: '/impact-dashboard/overall-statistics/time-metrics/:jobId',
    jobStatisticsTopJobs: '/impact-dashboard/job-statistics/top-jobs',
    jobStatisticsAcumulativeTimeReduced: '/impact-dashboard/job-statistics/accumulative-time-reduced',
    jobStatisticsFatigueRisk: '/impact-dashboard/job-statistics/fatigue-risk',
    jobStatisticsModelAccuracy: '/impact-dashboard/job-statistics/model-accuracy',
    platformSummary: '/platform/summary',
    eventExplorerFiltersFields: '/event-explorer/filters/fields',
    eventExplorerFiltersValues: '/event-explorer/filters/values',
    eventExplorerQuickFilters: '/event-explorer/quick-filters',
    eventExplorerBatch: '/event-explorer/batch',
    eventsFeedback: '/events/feedback',
    eventsUpdateCluster: '/events/update-cluster',
    jobActivityLog: '/job/:id/activity-log',
    jobAllRetrainHistory: '/model-history/:jobId',
    jobModel: '/model-history/:jobId/:modelId',
    jobExportModelKB: '/model-history/:jobId/:modelId/export_kb',
    jobModelDownload: '/model-history/:jobId/:modelId/download',
    jobRollbackModel: '/model-history/:jobId/:modelId/rollback',
    jobRetrainMetrics: '/model-history/:jobId/metrics',
    migrationStatus: '/migration/status',
    jobFeedbackFilterFields: '/feedback/filters/fields',
    unified: {
      filterFields: '/feedback/filters/unified/fields',
      filterValues: '/feedback/filters/unified/values'
    },
    flows: {
      codeBlock: {
        get: '/code-exec',
        delete: '/code-exec',
        save: '/code-exec/save',
        run: '/code-exec/test'
      }
    },
    job: {
      updateSettings: (jobId: number) => `job/update-settings/${jobId}`
    }
  },
  routes: {
    redirect: '/redirect',
    homepage: '/',
    homepageDashboard: '/home-dashboard',
    monitoring: '/monitoring',
    monitoringDrilldown: '/monitoring/:componentType/:id',
    platformManagement: '/platform-management',
    impactDashboard: '/impact-dashboard',
    charts: '/usecases/charts/summary',
    chartViewer: '/usecases/charts/view/:category/:chartId',
    useCases: '/usecases',
    exportCsv: '/csv-exports',
    reporting: '/reporting',
    reportingEditor: '/csv-export-edit/:id',
    reportingAdd: '/csv-export-add/:type',
    management: '/management',
    userAdd: '/user-add',
    users: '/users',
    userKey: '/user-key',
    userKeyCreate: '/create-user-key',
    jobsAll: '/jobs',
    integrations: '/integrations',
    integrationsRevamp: '/integrations-revamp',
    integrationAdd: '/integrations/new/:id',
    integrationBrowse: '/integrations/browse',
    integrationEdit: '/integrations/:id/edit',
    feedback: '/jobs/feedback/:id',
    eventExplorerColumnsSelection: '/jobs/event-explorer-columns-selection/:id',
    eventExplorer: '/jobs/event-explorer/:id',
    flowsWrapper: '/jobs-flows',
    flows: '/jobs-flows/:id',
    flowsOld: '/jobs/flows-old/:id',
    flowsComponents: {
      codeBlock: {
        add: ({ jobId }: { jobId: string | number }) => `/jobs-flows/${jobId}/code-block/add`,
        edit: ({ jobId, codeBlockId }: { jobId: string | number; codeBlockId: string | number }) =>
          `/jobs-flows/${jobId}/code-block/edit/${codeBlockId}`
      },
      contextEnrichment: {
        add: ({ jobId }: { jobId: string | number }) => `/jobs-flows/${jobId}/context-enrichment/add`,
        edit: ({ jobId, contextName, contextId }: { jobId: string | number; contextName: string; contextId: string }) =>
          `/jobs-flows/${jobId}/context-enrichment/edit/${contextName}/${contextId}`
      }
    },
    feedbackFlow: '/jobs/feedback-flow/:id',
    feedbackUnified: '/jobs/feedback-unified/:ids',
    staticRulesJob: '/jobs/static-rule/:id',
    columnsSelection: '/jobs/columns-selection/:id',
    dashboardJob: '/jobs/dashboard/:id',
    jobOverView: '/jobs/overview/:id',
    jobOverViewRCA: '/jobs/overview-rca/:id',
    retrainJob: '/jobs/retrain/:id',
    editJob: '/jobs/edit/:id',
    jobClusters: '/jobs/clusters/:id',
    featureSelectionJob: '/jobs/decision-points/:id',
    decisionPointsUnified: '/jobs/decision-points-unified/:ids',
    jobCreate: '/jobs/create',
    login: '/login',
    changePassword: '/change-password',
    authenticationOidc: '/oidc-auth',
    termsAndConditions: '/terms/END USER LICENSE AGREEMENT - Siscale.pdf',
    csv: '/csv',
    csvEditor: '/csv/editor/:id',
    kibana: '/kibana',
    components: '/components',
    page404: '/404',
    documentationSecondary: '/guides/InfraAI Access Guide.pdf',
    support: 'https://www.arcanna.ai/support',
    pricing: 'https://www.arcanna.ai/pricing',
    unavailable: '/unavailable'
  },
  defaultDateTimeFormat: 'DD/MM/YYYY HH:mm',
  defaultEventsCount: 1000,
  defaultLanguageId: 1,
  requestTimeout: 20000,
  branding: {
    folder: window._env_.REACT_APP_BRANDING_FOLDER || 'siscale'
  },
  docs: {
    home: 'https://docs.arcanna.ai',
    contextEnrichment: 'https://docs.arcanna.ai/docs/user-guide/context-enrichment',
    contextEnrichmentGather: 'https://docs.arcanna.ai/docs/user-guide/context-enrichment/jinja-templates',
    // TODO: Update this URL
    codeBlock: 'https://docs.arcanna.ai',
    upgradeGuide: 'https://docs.arcanna.ai/docs/admin-guide/upgrade-guide'
  }
};

export const config = defaultConfig;

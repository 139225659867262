import { Controller, useFormContext } from 'react-hook-form';
import { Select, TOption } from '@arcanna/generic';

type TRHFSelectProps<T> = {
  name: string;
  options: T[];
  isTextInputIncluded?: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  dataTestId?: string;
  tooltipText?: string;
  isLoading?: boolean;
  shouldUnregister?: boolean;
  onChange?: () => void;
  renderOptionLabel?: (option: string | T) => React.ReactNode;
};

function RHFSelect<T extends TOption>({
  name,
  label,
  disabled,
  required = false,
  options,
  placeholder = '',
  isTextInputIncluded = false,
  dataTestId = undefined,
  tooltipText,
  isLoading,
  shouldUnregister,
  onChange,
  renderOptionLabel
}: TRHFSelectProps<T>) {
  const {
    control,
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState: { error } }) => (
        <Select<T>
          {...field}
          textFieldProps={{
            error: !!error,
            helperText: error?.message
          }}
          options={options}
          isTextInputIncluded={isTextInputIncluded}
          onChange={(...props) => {
            field.onChange(...props);
            onChange?.();
          }}
          required={required}
          tooltipText={tooltipText}
          placeholder={placeholder}
          label={label}
          dataTestId={dataTestId}
          disabled={disabled || isSubmitting}
          isLoading={isLoading}
          renderOptionLabel={renderOptionLabel}
        />
      )}
    />
  );
}

export default RHFSelect;

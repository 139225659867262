import Fuse from 'fuse.js';
import { FeedbackBatchBucketRow } from '../../../../../shared/models/feedback/FeedbackBatchBucketRow';

function getSearchOptions() {
  return {
    keys: ['title'],
    distance: 100,
    threshold: 0.1,
    isCaseSensitive: false
  };
}

export function search(items: FeedbackBatchBucketRow[], searchedTexts: string[]) {
  const fuseSearch = new Fuse(items, getSearchOptions());
  return fuseSearch.search(searchedTexts.join(' ')).map((result) => result.item);
}

export function getMappedSortDirection(direction: string) {
  switch (direction) {
    case 'ascend':
      return 'asc';
    case 'descend':
      return 'desc';
    default:
      return 'asc';
  }
}

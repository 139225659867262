import { Box, styled, BoxProps } from '@mui/material';

const StyledLabelCircle = styled(Box)<BoxProps>(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '4px',
  '.root': {
    width: '16px',
    height: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px'
  },
  '.point': {
    width: '8px',
    height: '8px',
    display: 'flex',
    borderRadius: '6px'
  }
}));

export default StyledLabelCircle;

import { FeedbackBatchBucketColumn } from 'src/components/shared/models/feedback/FeedbackBatchBucketColumn';
import BucketDrawer from '../BucketDrawer';
import BucketDrawerContextProvider from '../BucketDrawer/context/BucketDrawerContextProvider';
import { useFeedbackContext } from '../FeedbackContext/useFeedbackContext';
import { useMemo } from 'react';

function FeedbackDrawer() {
  const filterFields = useFeedbackContext((context) => context.filtersSlice.filterFields);
  const advancedFilters = useFeedbackContext((context) => context.filtersSlice.advancedFilters);
  const triggerBucketsReload = useFeedbackContext((context) => context.tableSlice.triggerBucketsReload);
  const expandedBucket = useFeedbackContext((context) => context.expandedBucketSlice.expandedBucket);
  const batchBucketRows = useFeedbackContext((context) => context.tableSlice.batchBucketRows);
  const setExpandedBucketByIndex = useFeedbackContext((context) => context.expandedBucketSlice.setExpandedBucketByIndex);
  const jobId = useFeedbackContext((context) => context.jobInfoSlice.jobId);
  const clearExpandedBucket = useFeedbackContext((context) => context.expandedBucketSlice.clearExpandedBucket);

  const bucketId = useMemo(
    () => expandedBucket.data?.columns?.find((columnItem: FeedbackBatchBucketColumn) => columnItem.name === 'id')?.value,
    [expandedBucket.data?.columns]
  );

  const shortBucketId = useMemo(
    () => expandedBucket.data?.columns?.find((columnItem: FeedbackBatchBucketColumn) => columnItem.name === 'bucket_id')?.value,
    [expandedBucket.data?.columns]
  );

  const bucketContextValue = useMemo(
    () => ({
      filterFields: filterFields,
      advancedFilters: advancedFilters,
      triggerBucketsReload: triggerBucketsReload,
      expandedBucketIndex: expandedBucket.index,
      bucketRowsCount: batchBucketRows.length,
      setExpandedBucketByIndex: setExpandedBucketByIndex
    }),
    [advancedFilters, batchBucketRows.length, expandedBucket.index, filterFields, setExpandedBucketByIndex, triggerBucketsReload]
  );

  return (
    <BucketDrawerContextProvider bucketContextValue={bucketContextValue}>
      <BucketDrawer
        jobId={jobId}
        bucketId={bucketId}
        shortBucketId={shortBucketId}
        handleDrawerClose={clearExpandedBucket}
        expandedBucketRowIndex={expandedBucket.index}
      />
    </BucketDrawerContextProvider>
  );
}

export default FeedbackDrawer;

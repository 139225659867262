import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';

@JsonObject('OidcAuthenticationPrepareResponse')
export class OidcAuthenticationPrepareResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('redirect', String)
  redirect?: string = undefined;

  @JsonProperty('state', String)
  state?: string = undefined;

  @JsonProperty('nonce', String)
  nonce?: string = undefined;
}

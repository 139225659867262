import { Col, Row } from 'antd';
import { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { CustomLabel } from '../../../../../../../../components/shared/models/job/AdvancedJobSettings';
import { getLabelInfoById } from '../../../../../../../../components/pages/Main/Jobs/Overview/helper';
import styles from './TooltipJobSeriesAlerts.module.css';
import { CustomLabel as CustomLabelComponent } from '@arcanna/components';
import { useTranslation } from 'react-i18next';

type TooltipJobSeriesAlertsProps = {
  active: boolean;
  payload?: Payload<ValueType, NameType>[];
  label?: string;
  jobLabels: CustomLabel[];
};

function TooltipJobSeriesAlerts({ payload, label, jobLabels }: TooltipJobSeriesAlertsProps) {
  const { t } = useTranslation(['job']);
  const alertsTotalCount: number = useMemo(() => {
    if (payload != null) {
      return payload.map((p) => Number(p.value)).reduce((value1, value2) => value1 + value2, 0);
    }
    return 0;
  }, [payload]);

  const computePercentAlerts = useCallback(
    // eslint-disable-next-line
    (alertCount: any) => {
      return ((alertCount * 100) / alertsTotalCount).toFixed(2);
    },
    [alertsTotalCount]
  );

  if (alertsTotalCount > 0) {
    return (
      <div className={styles.tooltip}>
        <div className={cx([styles.title, 'caption'])}>{label}</div>
        <div className={styles.content}>
          <Row className="w-100">
            <Col className="p-r-10">
              {/* @ts-expect-error TS(2488): Type 'Payload<ValueType, NameType>[] | undefined' ... */}
              {[...payload].reverse().map((p) => (
                <Row key={p.dataKey} className={styles.labelRow}>
                  <CustomLabelComponent
                    hexColor={p.color}
                    name={<span className="color-nb-300 caption-medium">{p.value.toLocaleString()}</span>}
                  />
                </Row>
              ))}
            </Col>
            <Col className="p-r-10">
              {[...payload].reverse().map((p) => (
                <Row key={p.dataKey + '_label'} className={cx([styles.labelRow, 'p-t-2'])}>
                  {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                  <span className="caption-medium">{getLabelInfoById(jobLabels, p.dataKey.toString()).name}</span>
                </Row>
              ))}
            </Col>
            <Col>
              {[...payload].reverse().map((p) => (
                <Row key={p.dataKey + '_percent'} className={cx([styles.labelRow, 'p-t-2'])}>
                  <span className="caption-medium">{computePercentAlerts(p.value)}%</span>
                </Row>
              ))}
            </Col>
          </Row>
        </div>
        <Row className={cx([styles.footer, 'w-100'])}>
          <Col offset={2} span={6}>
            <span className="color-nb-300 caption">{t('job:jobOverview.total')}</span>
          </Col>
          <Col span={8}>
            <span className="caption">{alertsTotalCount.toLocaleString()}</span>
          </Col>
          <Col span={8} />
        </Row>
      </div>
    );
  }

  return null;
}

export default TooltipJobSeriesAlerts;

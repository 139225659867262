import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserUpdateRecord')
export class UserUpdateRecord {
  @JsonProperty('role_id', String)
  role_id: string | undefined;

  @JsonProperty('user_id', String)
  user_id: string | undefined;

  @JsonProperty('operation', String)
  operation: string | undefined;

  constructor(role_id: string, user_id: string, operation: string) {
    this.role_id = role_id;
    this.user_id = user_id;
    this.operation = operation;
  }
}

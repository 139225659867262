import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';

import { useAuth } from './Auth.context';
import { getJsonConvert } from '../../../shared/utilities/json-convert';
import { KibanaLogin } from './KibanaLogin';
import { PlatformData } from '../../../shared/components/PlatformData/PlatformData';
import { useFetch } from '../../../shared/hooks/useFetch';
import { getBackendEndpoint } from '../../../shared/utilities/api';
import { AuthRequest } from '../../../shared/models/auth/AuthRequest';
import { AuthResponse } from '../../../shared/models/auth/AuthResponse';
import { config } from '../../../../config';
import { getErrorMsgByCode } from './auth.helper';
import { showErrorNotification } from '../../../shared/utilities/notification';
import { OidcAuthenticationPrepareResponse } from '../../../shared/models/auth/OidcAuthenticationPrepareResponse';
import { OidcAuthenticationExecuteRequest } from '../../../shared/models/auth/OidcAuthenticationExecuteRequest';
import { OidcAuthenticationExecuteResponse } from '../../../shared/models/auth/OidcAuthenticationExecuteResponse';
import { useApp } from '../../../App/App.context';

const OIDC_STATE_STORAGE_KEY = 'oidc_state';
const OIDC_NONCE_STORAGE_KEY = 'oidc_nonce';

export function useLoginHook() {
  const { setReloadUserPermissions } = useApp();

  const { setAuthResponse, setOidcAuthResponse } = useAuth();
  const history = useHistory();
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState(false);
  const kibanaLogin = React.createRef<KibanaLogin>();

  // Retrieve platform info
  const [platformInfo, setPlatformInfo] = useState({});
  const { platformData } = PlatformData();
  useMemo(() => {
    setPlatformInfo(platformData);
  }, [platformData]);

  const handleUsernameChange = (event: React.FormEvent<EventTarget>) => {
    const target = event.target as HTMLInputElement;
    setUsername(target.value);
  };

  const handlePasswordChange = (event: React.FormEvent<EventTarget>) => {
    const target = event.target as HTMLInputElement;
    setPassword(target.value);
  };

  const { loading, error, post, responseData } = useFetch({
    path: getBackendEndpoint('/user/auth'),
    load: false
  });

  const onFinish = useCallback(
    // eslint-disable-next-line
    (values: any) => {
      // eslint-disable-next-line
      post(new AuthRequest(values.username, values.password)).catch(() => {});
    },
    [post]
  );

  const onSubmit = () => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (config.branding.folder === 'orange') kibanaLogin.current.submitForm();
  };

  useEffect(() => {
    if (!responseData) {
      return;
    }
    const authResponse = jsonConvert.deserializeObject(responseData.resource, AuthResponse);
    setAuthResponse(authResponse);
    setReloadUserPermissions(true);
  }, [responseData, jsonConvert, setAuthResponse, setReloadUserPermissions]);

  useEffect(() => {
    if (error?.code) {
      notification.error({
        message: 'Error',
        description: getErrorMsgByCode(error.code),
        duration: 4.5
      });
    }
  }, [error]);

  const { post: postSsoPrepare } = useFetch({
    path: getBackendEndpoint('/user/openid/auth/prepare'),
    load: false
  });

  const { post: postSsoAuthenticate } = useFetch({
    path: getBackendEndpoint('/user/openid/auth/authenticate'),
    load: false
  });

  const ssoAuthenticationStart = () => {
    postSsoPrepare({})
      // eslint-disable-next-line
      .then((response: any) => {
        const prepareResponse = jsonConvert.deserializeObject(response.data.resource, OidcAuthenticationPrepareResponse);
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        localStorage.setItem(OIDC_STATE_STORAGE_KEY, prepareResponse.state);
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        localStorage.setItem(OIDC_NONCE_STORAGE_KEY, prepareResponse.nonce);
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
        window.location.href = prepareResponse.redirect;
      })
      // eslint-disable-next-line
      .catch((err: any) => {
        showErrorNotification('SSO not working temporarly', err.message);
      });
  };

  const ssoAuthenticationExecute = useCallback(() => {
    const redirectUri = window.location.href;
    const request = new OidcAuthenticationExecuteRequest(
      redirectUri,
      // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable...
      localStorage.getItem(OIDC_STATE_STORAGE_KEY),
      localStorage.getItem(OIDC_NONCE_STORAGE_KEY)
    );
    postSsoAuthenticate(request)
      // eslint-disable-next-line
      .then((response: any) => {
        const authenticationResponse = jsonConvert.deserializeObject(response.data.resource, OidcAuthenticationExecuteResponse);
        setOidcAuthResponse(authenticationResponse);
        history.push(config.routes.homepage);
      })
      // eslint-disable-next-line
      .catch((err: any) => {
        showErrorNotification('SSO Authentication failed', err.message);
      });
    return request;
  }, [history, jsonConvert, postSsoAuthenticate, setOidcAuthResponse]);

  return {
    loading,
    error,
    onSubmit,
    onFinish,
    handleUsernameChange,
    handlePasswordChange,
    kibanaLogin,
    username,
    password,
    platformInfo,
    ssoAuthenticationStart,
    ssoAuthenticationExecute,
    isForgotPasswordVisible,
    setIsForgotPasswordVisible
  };
}

import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { AccumulativeTimeReducedRequest } from '../../../components/shared/models/impact-dashboard/AccumulativeTimeReducedRequest';
import { AccumulativeTimeReducedResponse } from '../../../components/shared/models/impact-dashboard/AccumulativeTimeReducedResponse';
import { getJsonConvert } from '../../../components/shared/utilities/json-convert';
import { config } from '../../../config';

type UseAccumulatedTimeReduced = {
  jobIds: number[];
  startDate: Date;
  endDate: Date;
};
export function useAccumulatedTimeReduced({ jobIds, startDate, endDate }: UseAccumulatedTimeReduced) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const isoStartDate = startDate.toISOString();
  const isoEndDate = endDate.toISOString();

  return useQuery(
    [config.api.jobStatisticsAcumulativeTimeReduced, { startDate: isoStartDate, endDate: isoEndDate, jobIds }],
    () =>
      axios
        .post<{
          resource: AccumulativeTimeReducedResponse;
        }>(config.api.jobStatisticsAcumulativeTimeReduced, new AccumulativeTimeReducedRequest(jobIds, isoStartDate, isoEndDate))
        .then((responseData: AxiosResponse<{ resource: AccumulativeTimeReducedResponse }>) => {
          return jsonConvert.deserializeObject(responseData.data.resource, AccumulativeTimeReducedResponse);
        }),
    {
      enabled: Boolean(jobIds.length > 0 && startDate && endDate)
    }
  );
}

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import cx from 'classnames';
import Button from '../../../../../../shared/components/Button/Button';
import { useBucketDrawerContext } from '@arcanna/pages/Feedback/BucketDrawer/context/useBucketDrawerContext';

export default function ExpandedBucketNavigation() {
  const { expandedBucketIndex, bucketRowsCount, setExpandedBucketByIndex } = useBucketDrawerContext();

  if (bucketRowsCount && expandedBucketIndex !== undefined) {
    return (
      <Space align="center" size={2}>
        <Button
          title="Previous bucket"
          size="small"
          type="transparent"
          icon={
            <LeftOutlined
              className={cx({
                ['color-nb-500']: expandedBucketIndex === 0,
                ['color-nb-200']: expandedBucketIndex !== 0
              })}
            />
          }
          onClick={() => expandedBucketIndex && setExpandedBucketByIndex(expandedBucketIndex - 1)}
        />
        <Space size={2} className="callout color-nb-300">
          <span className="color-nb-100">{expandedBucketIndex + 1}</span>
          <span>of</span>
          <span>{bucketRowsCount}</span>
        </Space>
        <Button
          title="Next bucket"
          size="small"
          type="transparent"
          icon={
            <RightOutlined
              className={cx({
                ['color-nb-500']: expandedBucketIndex === bucketRowsCount - 1,
                ['color-nb-200']: expandedBucketIndex !== bucketRowsCount - 1
              })}
            />
          }
          onClick={() => expandedBucketIndex !== bucketRowsCount - 1 && setExpandedBucketByIndex(expandedBucketIndex + 1)}
        />
      </Space>
    );
  }

  return <></>;
}

import { withTransaction } from '@elastic/apm-rum-react';
import { UserKeyCreate } from './UserKeyCreate';
import { UserKeyProvider } from './UserKey.context';

// eslint-disable-next-line
function UserKeyCreateWrapper(props: any) {
  return (
    <UserKeyProvider>
      <UserKeyCreate {...props} />
    </UserKeyProvider>
  );
}
export default withTransaction('UserAddWrapper', 'component')(UserKeyCreateWrapper);

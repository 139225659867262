import { useFeedbackBucketDetails, useJobInfo } from '../../../../../../../data-access';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../../../../../components/pages/Main/Login/Auth.context';
import { CustomLabel } from 'src/components/shared/models';
import { noDecisionResult } from 'src/components/pages/Main/Jobs/helper';

export const MAXIMUM_NUMBER_OF_VISIBLE_LABELS = 9;

export default function useLabelsFeedbackOptions({ jobId, bucketId }: { jobId: number; bucketId: string }) {
  const jobInfoQuery = useJobInfo(jobId);
  const feedbackBucketDetailsQuery = useFeedbackBucketDetails(jobId, bucketId);

  const [visibleLabels, setVisibleLabels] = useState<CustomLabel[]>([]);
  const [moreLabels, setMoreLabels] = useState<CustomLabel[]>([]);

  const { getUsername } = useAuth();

  const arcannaDecisionLabel = useMemo(() => {
    return (
      jobInfoQuery.data?.info?.advancedSettings.customLabels.find(
        (customLabel) => customLabel.id === feedbackBucketDetailsQuery.data?.bucket.result
      ) || noDecisionResult
    );
  }, [jobInfoQuery.data, feedbackBucketDetailsQuery.data]);

  const labelOfCurrentUsername = useMemo(() => {
    const foundFeedback = feedbackBucketDetailsQuery.data?.bucket.userLabels?.entries?.find(
      (userLabel) => userLabel.username === getUsername()
    )?.label;
    if (foundFeedback !== null) {
      return foundFeedback;
    }
    if (feedbackBucketDetailsQuery.data?.bucket.consensusOverwritten != null) {
      return feedbackBucketDetailsQuery.data.bucket.consensus;
    }
    return null;
  }, [feedbackBucketDetailsQuery.data, getUsername]);

  const currentUserFeedbackLabelOnBucket = jobInfoQuery.data?.info?.advancedSettings.customLabels.find((customLabel) => {
    return customLabel.id === labelOfCurrentUsername;
  });

  useEffect(() => {
    if (feedbackBucketDetailsQuery.data === null) {
      return;
    }
    if (jobInfoQuery.data) {
      // here we want to exclude the feedback that is currently active on the bucket
      // this is the currentUserFeedbackLabelOnBucket if it exists, or the arcannaDecisionLabel if it does not exists
      const feedbackLabelToExclude = currentUserFeedbackLabelOnBucket?.id ?? arcannaDecisionLabel?.id;

      // @ts-expect-error
      const restOfLabels = jobInfoQuery.data?.info.advancedSettings.customLabels.filter(
        (customLabel) => customLabel.id !== feedbackLabelToExclude
      );
      let numVisibleLabels = MAXIMUM_NUMBER_OF_VISIBLE_LABELS;
      if (restOfLabels.length == MAXIMUM_NUMBER_OF_VISIBLE_LABELS) {
        numVisibleLabels += 1;
      }

      setVisibleLabels(restOfLabels.slice(0, numVisibleLabels));
      setMoreLabels(restOfLabels.slice(numVisibleLabels));
    }
  }, [jobInfoQuery.data, feedbackBucketDetailsQuery.data, arcannaDecisionLabel, currentUserFeedbackLabelOnBucket]);

  return {
    visibleLabels,
    moreLabels,
    arcannaDecisionLabel,
    feedbackLabelOnBucket: currentUserFeedbackLabelOnBucket
  };
}

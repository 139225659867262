import { REQUIRING_ATTENTION_FILTERS, FEEDBACK_FILTERS, TRAINING_STATUS_FILTERS } from './constants';

export function getQuickFiltersMetadata() {
  return [
    {
      filter_group: 'feedback',
      filters: FEEDBACK_FILTERS
    },
    {
      filter_group: 'training_status',
      filters: TRAINING_STATUS_FILTERS
    },
    {
      filter_group: 'requiring_attention',
      filters: REQUIRING_ATTENTION_FILTERS
    }
  ];
}

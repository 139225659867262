import { Pagination, TPageSize } from '@arcanna/generic';
import { Table as TanstackTable } from '@tanstack/table-core/build/lib/types';

type TTablePaginationProps<T> = {
  tableInstance: TanstackTable<T>;
};

function TablePagination<T>({ tableInstance }: TTablePaginationProps<T>) {
  return (
    <Pagination
      page={tableInstance.getState().pagination.pageIndex + 1}
      total={tableInstance.getRowCount()}
      pageSize={tableInstance.getState().pagination.pageSize as TPageSize}
      onPageChange={(pageIndex: number) => {
        tableInstance.setPageIndex(pageIndex - 1);
      }}
      onPageSizeChange={(pageSize: TPageSize) => {
        tableInstance.setPageSize(pageSize);
      }}
    />
  );
}

export default TablePagination;

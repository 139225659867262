import { TComponentsOverrides, TPaletteOverrides } from '../../branding.types';

export const getMuiIconButtonStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: 4
      },
      sizeSmall: {
        height: '36px',
        width: '36px'
      },
      sizeLarge: {
        minHeight: '52px'
      }
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          border: `1px solid ${palette.secondary[600]}`,
          backgroundColor: palette.secondary[800],
          color: palette.secondary[200],
          ':hover': {
            backgroundColor: palette.secondary[1000]
          },
          ':disabled': {
            borderColor: palette.secondary[300],
            backgroundColor: palette.secondary[300],
            color: palette.secondary[500]
          }
        }
      },
      {
        props: { variant: 'tertiary' },
        style: {
          border: `1px solid ${palette.secondary[600]}`,
          backgroundColor: 'unset',
          color: palette.secondary[300],
          ':hover': {
            backgroundColor: palette.secondary[1000]
          },
          ':disabled': {
            borderColor: palette.secondary[700],
            backgroundColor: 'unset',
            color: palette.secondary[500]
          }
        }
      }
    ]
  }
});

import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useQuery } from 'react-query';
import { config } from '../../config';
import { FeedbackBatchBucketResponse } from '../../components/shared/models/feedback/FeedbackBatchBucketResponse';
import axios, { AxiosResponse } from 'axios';
import { BatchTableSort } from '../../components/shared/models/event-explorer/EventExplorerBatchRequest';
import { FeedbackQuickFilters } from '../../components/shared/models/feedback/FeedbackQuickFilters';
import { FeedbackBatchUnifiedRequest } from 'src/components/shared/models/feedback/FeedbackBatchUnifiedRequest';

type UseFeedbackBucketsUnifiedBatch = {
  jobIds: number[];
  includeAllJobs: boolean;
  tablePageSize: number;
  tablePage: number;
  startDate: Date | null;
  endDate: Date | null;
  excludeProcessed: boolean;
  includeProcessed: boolean;
  tableSort: BatchTableSort;
  // eslint-disable-next-line
  advancedFilters: any[];
  quickFilters: FeedbackQuickFilters;
  decisionFilters: string[];
  consensusFilters: string[];
};
export function useFeedbackBucketsUnifiedBatch({
  jobIds,
  includeAllJobs,
  tablePageSize,
  tablePage,
  startDate,
  endDate,
  excludeProcessed,
  includeProcessed,
  tableSort,
  quickFilters,
  advancedFilters,
  decisionFilters,
  consensusFilters
}: UseFeedbackBucketsUnifiedBatch) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const payload = new FeedbackBatchUnifiedRequest(
    jobIds,
    includeAllJobs,
    tablePageSize,
    tablePage,
    startDate !== null ? startDate.toISOString() : undefined,
    endDate !== null ? endDate.toISOString() : undefined,
    excludeProcessed,
    includeProcessed,
    tableSort,
    advancedFilters,
    quickFilters,
    decisionFilters,
    consensusFilters
  );
  return useQuery<FeedbackBatchBucketResponse, Error>([config.api.feedbackBucketsUnifiedBatch, payload], () =>
    axios
      .post(config.api.feedbackBucketsUnifiedBatch, payload)
      .then((responseData: AxiosResponse<{ resource: FeedbackBatchBucketResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, FeedbackBatchBucketResponse)
      )
  );
}

import BackspaceIcon from '../../../../../../../components/shared/icons/BackspaceIcon';
import CircledCheckmark from '../../../../../../../components/shared/icons/CircledCheckmark';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { ARCANNA_NO_DECISION } from 'src/components/pages/JobEventExplorer/utils';

type ConfirmDecisionButtonProps = {
  arcannaDecision: string;
  bucketFeedbackFromUser: string;
  disabled: boolean;
  onConfirmDecision: () => void;
  onClearFeedback: () => void;
};

export default function ConfirmDecisionButton({
  arcannaDecision,
  bucketFeedbackFromUser,
  disabled,
  onConfirmDecision,
  onClearFeedback
}: ConfirmDecisionButtonProps) {
  const { t } = useTranslation(['feedback']);
  if (bucketFeedbackFromUser) {
    return (
      <Button size="small" variant="tertiary" endIcon={<BackspaceIcon />} onClick={onClearFeedback} disabled={disabled}>
        {t('clearMyFeedback')}
      </Button>
    );
  }

  if (arcannaDecision && arcannaDecision !== ARCANNA_NO_DECISION && !bucketFeedbackFromUser) {
    return (
      <Box marginRight="auto">
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          disabled={disabled}
          endIcon={<CircledCheckmark />}
          onClick={onConfirmDecision}
        >
          {t('confirmDecision')}
        </Button>
      </Box>
    );
  }
  return null;
}

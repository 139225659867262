import { JobReadyToSelectFeaturesTag } from './Tags/JobReadyToSelectFeaturesTag';
import { JobCollectingDataTag } from './Tags/JobCollectingDataTag';
import { JobEnabledTag } from './Tags/JobEnabledTag';
import { JobDisabledTag } from './Tags/JobDisabledTag';
import { JobInvalidTag } from './Tags/JobInvalidTag';
import { JobRetrainInProgressTag } from './Tags/JobRetrainInProgressTag';
import { JobStateEnum } from '../../models';
import { EJobTrainingState } from '../../models/job/EJobTrainingState';
import JobCloningTag from './Tags/JobCloningTag';
import JobRollbackInProgressTag from './Tags/JobRollbackInProgressTag';
import { JobAutomaticRetrainTag } from './Tags/JobAutomaticRetrainTag';
import { JobRetrainStartingTag } from './Tags/JobRetrainStartingTag';
import { JobRetrainCollectingFeedbackTag } from './Tags/JobRetrainCollectingFeedbackTag';
import { JobRetrainStateErrorTag } from './Tags/JobRetrainStateErrorTag';

export const StartedState = [JobStateEnum.COLLECTING_DATA, JobStateEnum.READY_TO_SELECT_FEATURES, JobStateEnum.ENABLED];

type JobStateProps = {
  state: JobStateEnum | string;
  message?: string;
  jobId?: number;
};

export function JobState({ state, message, jobId }: JobStateProps) {
  const jobStateTagMap: Record<string, React.ReactNode> = {
    [JobStateEnum.ENABLED]: <JobEnabledTag />,
    [JobStateEnum.COLLECTING_DATA]: <JobCollectingDataTag />,
    [JobStateEnum.READY_TO_SELECT_FEATURES]: <JobReadyToSelectFeaturesTag />,
    [JobStateEnum.DISABLED]: <JobDisabledTag />,
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
    [JobStateEnum.ERROR]: <JobInvalidTag message={message} />,
    [JobStateEnum.ROLLING_BACK]: <JobRollbackInProgressTag />,
    // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
    [JobStateEnum.CLONING]: <JobCloningTag jobId={jobId} />
  };

  return <>{jobStateTagMap[state]}</>;
}

type JobRetrainStateProps = {
  retrain_state: EJobTrainingState | string;
  err_msg?: string;
};

export function JobRetrainState({ retrain_state, err_msg }: JobRetrainStateProps) {
  const jobRetrainStateTagMap: Record<string, React.ReactNode> = {
    [EJobTrainingState.STARTED]: <JobRetrainStartingTag />,
    [EJobTrainingState.COLLECTING_FEEDBACK]: <JobRetrainCollectingFeedbackTag />,
    [EJobTrainingState.RETRAIN_IN_PROGRESS]: <JobRetrainInProgressTag />,
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
    [EJobTrainingState.ERROR]: <JobRetrainStateErrorTag message={err_msg} />,
    [EJobTrainingState.AUTOMATIC_RETRAIN]: <JobAutomaticRetrainTag />
  };

  return <>{jobRetrainStateTagMap[retrain_state]}</>;
}

type JobInvalidStateProps = {
  isInvalid: boolean;
  invalidReason?: string;
  invalidTimestamp?: Date;
};

export function JobInvalidState({ isInvalid, invalidReason, invalidTimestamp }: JobInvalidStateProps) {
  if (isInvalid === true) {
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
    return <JobInvalidTag message={invalidReason} invalidTimestamp={invalidTimestamp} />;
  }
  return null;
}

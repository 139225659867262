import { useTranslation } from 'react-i18next';
import ArcannaChip from '../../ArcannaChip';
import { JobCollectingDataSpinner } from './JobCollectingDataSpinner/JobCollectingDataSpinner';

export function JobAutomaticRetrainTag() {
  const { t } = useTranslation(['job']);

  return (
    <ArcannaChip color="orange">
      <div className="flex flex-align-center" style={{ gap: 8 }}>
        <JobCollectingDataSpinner />
        {t('job:jobRetrainState.automaticRetrainInProgress')}
      </div>
    </ArcannaChip>
  );
}

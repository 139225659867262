import { JobStatusToggle } from '@arcanna/components';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '@arcanna/layouts';
import { useFeedbackContext } from '../FeedbackContext/useFeedbackContext';
import { useJobIdFromUrl } from '@arcanna/hooks';
import FeedbackHeader from '../FeedbackHeader';
import FeedbackTable from '../FeedbackTable';
import { Breadcrumbs, Stack } from '@mui/material';
import { EIcon, Icon } from '@arcanna/generic';
import { config } from 'src/config';
import { Link } from 'react-router-dom';
import { useEstablishStartNotificationType } from 'src/components/pages/Main/Jobs/components/JobNotifications/hooks/useEstablishStartNotificationType';
import JobNotifications from 'src/components/pages/Main/Jobs/components/JobNotifications';
import { useJobInfo } from 'src/data-access';
import { useMemo } from 'react';
import FeedbackDrawer from '../FeedbackDrawer';

function FeedbackPageContent() {
  const { t } = useTranslation();
  const isBucketsTableLoaded = useFeedbackContext((context) => context.tableSlice.isBucketsTableLoaded);
  const triggerBucketsReload = useFeedbackContext((context) => context.tableSlice.triggerBucketsReload);
  const jobInfoSlice = useFeedbackContext((context) => context.jobInfoSlice);

  const { jobId } = useJobIdFromUrl();
  const { notificationType, showContent } = useEstablishStartNotificationType(jobId, t('job:navigation.feedback'));
  const jobInfoQuery = useJobInfo(jobId);
  const jobInfos = useMemo(() => (jobInfoQuery.data != null ? [jobInfoQuery.data] : []), [jobInfoQuery.data]);

  return (
    <PageLayout
      breadcrumbContent={
        <Breadcrumbs separator={<Icon name={EIcon.ChevronRight} />}>
          <Link to={config.routes.jobsAll}>{t('job:aiJobs')}</Link>
          <span>{jobInfoSlice.jobTitle}</span>
          <span>{t('job:navigation.feedback')}</span>
        </Breadcrumbs>
      }
      isLoading={!isBucketsTableLoaded}
      actions={<JobStatusToggle jobId={jobInfoSlice.jobId} />}
      notificationsContent={
        <JobNotifications
          key="jobNotifications"
          className="notificationsWrapper"
          fromPage={t('job:navigation.feedback')}
          reload={triggerBucketsReload}
          startNotificationType={notificationType}
        />
      }
    >
      {showContent ? (
        <Stack gap={2}>
          <FeedbackDrawer />
          <FeedbackHeader jobInfos={jobInfos} />
          <FeedbackTable jobInfos={jobInfos} />
        </Stack>
      ) : (
        <></>
      )}
    </PageLayout>
  );
}

export default FeedbackPageContent;

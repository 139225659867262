import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('FeedbackUnifiedFiltersFieldsRequest')
export class FeedbackUnifiedFiltersFieldsRequest extends CommonRequest {
  @JsonProperty('job_ids', [Number])
  job_ids?: number[];

  constructor(job_ids: number[]) {
    super();
    this.job_ids = job_ids;
  }
}

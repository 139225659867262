import { FormControlLabel, IconButton, Menu, RadioGroup, Stack, Tooltip, useTheme } from '@mui/material';
import { Icon, EIcon, RadioButton } from '@arcanna/generic';
import { MouseEvent, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { getTableSizeSelectorOptions } from './TableSizeSelector.utils';
import { useTranslation } from 'react-i18next';

type TTableSizeSelectorProps = {
  tableSize: string;
  handleTableSizeChange: (tableSize: string) => void;
  customTableSizes?: string[];
};

function TableSizeSelector({ customTableSizes = [], tableSize, handleTableSizeChange }: TTableSizeSelectorProps) {
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tableSizeSelectorOptions = useMemo(() => getTableSizeSelectorOptions(t), [t]);
  const customTableSizeSelectorOptions = useMemo(
    () =>
      customTableSizes.map((size) => ({
        label: size,
        value: size
      })),
    [customTableSizes]
  );

  return (
    <>
      <Tooltip arrow placement="top" title={t('common:table.adjustTableSize')}>
        <IconButton
          sx={{
            height: '16px',
            width: '16px',
            color: theme.palette.secondary[200]
          }}
          onClick={handleClick}
        >
          <Icon fontSize="small" name={EIcon.Layout} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Stack
          direction="column"
          width="120px"
          maxWidth="100%"
          padding="14px 12px"
          border={`1px solid ${theme.palette.secondary[600]}`}
          bgcolor={theme.palette.secondary[900]}
          borderRadius="4px"
          gap="16px"
        >
          <Typography variant="subtitle1" sx={{ paddingBottom: 1, borderBottom: `1px solid ${theme.palette.secondary[600]}` }}>
            {t('common:table.tableSizes')}
          </Typography>
          <RadioGroup
            onChange={(event) => {
              handleTableSizeChange(event.target.value);
            }}
            sx={{ gap: 2 }}
          >
            {tableSizeSelectorOptions.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<RadioButton value={value} isChecked={value === tableSize} sx={{ marginRight: 1 }} />}
                label={<Typography variant="subtitle1">{label}</Typography>}
                sx={{ marginLeft: 0 }}
              />
            ))}
            {customTableSizeSelectorOptions.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<RadioButton value={value} isChecked={value === tableSize} sx={{ marginRight: 1 }} />}
                label={<Typography variant="subtitle1">{label}</Typography>}
                sx={{ marginLeft: 0 }}
              />
            ))}
          </RadioGroup>
        </Stack>
      </Menu>
    </>
  );
}

export default TableSizeSelector;

/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext as fluentCreateContext } from '@fluentui/react-context-selector';
import CustomLabelByJob from '../FeedbackTable/models/CustomLabelByJob';

export type TLabelContext = {
  editLabelOnSelected: CustomLabelByJob | null;
  setEditLabelOnSelected: React.Dispatch<React.SetStateAction<CustomLabelByJob | null>>;
  prepareConfirm: boolean;
  setPrepareConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  prepareClear: boolean;
  setPrepareClear: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialLabelContext: TLabelContext = {
  editLabelOnSelected: null,
  setEditLabelOnSelected: () => {},
  prepareConfirm: false,
  setPrepareConfirm: () => {},
  prepareClear: false,
  setPrepareClear: () => {}
};

export const LabelContext = fluentCreateContext<TLabelContext>(initialLabelContext);

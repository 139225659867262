import { useTranslation } from 'react-i18next';
import { JobCollectingDataSpinner } from './JobCollectingDataSpinner/JobCollectingDataSpinner';
import ArcannaChip from '../../ArcannaChip';

export function JobCollectingDataTag() {
  const { t } = useTranslation(['job']);

  return (
    <ArcannaChip>
      <div className="flex flex-align-center" style={{ gap: 8 }}>
        <JobCollectingDataSpinner />
        {t('job:jobState.collectingData')}
      </div>
    </ArcannaChip>
  );
}

import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { UserSummaryRecord } from './UserSummaryRecord';
import { CommonPaginationResponse } from '../common/CommonPaginationResponse';

@JsonObject('UserSummaryResponse')
export class UserSummaryResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('pagination', CommonPaginationResponse)
  pagination?: CommonPaginationResponse = undefined;

  @JsonProperty('entries', [UserSummaryRecord])
  entries?: UserSummaryRecord[] = [];
}

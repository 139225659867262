import { useTranslation } from 'react-i18next';
import ArcannaChip from '../../ArcannaChip';
import { JobCollectingDataSpinner } from './JobCollectingDataSpinner/JobCollectingDataSpinner';

export default function JobRollbackInProgressTag() {
  const { t } = useTranslation(['job']);

  return (
    <ArcannaChip>
      <div className="flex flex-align-center" style={{ gap: 8 }}>
        <JobCollectingDataSpinner />
        {t('job:jobState.rollbackInProgress')}
      </div>
    </ArcannaChip>
  );
}

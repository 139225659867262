import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material';
import { forwardRef, useMemo } from 'react';
import { Label, Spinner } from '@arcanna/generic';

export type TOption = {
  label: string;
  value: string;
};

type TSelectProps<T> = {
  options: T[];
  isTextInputIncluded: boolean;
  onChange: (newValue: string) => void;
  required: boolean;
  placeholder: string;
  label?: string;
  fullWidth?: boolean;
  value: string;
  textFieldProps?: TextFieldProps;
  dataTestId?: string;
  tooltipText?: string;
  isLoading?: boolean;
  renderOptionLabel?: (option: string | T) => React.ReactNode;
} & Omit<
  AutocompleteProps<T, boolean, boolean, boolean>,
  'freeSolo' | 'onInputChange' | 'getOptionLabel' | 'options' | 'isOptionEqualToValue' | 'renderInput' | 'onChange'
>;

function Select<T extends TOption>({
  options,
  isTextInputIncluded,
  onChange,
  required,
  tooltipText,
  placeholder,
  label,
  value,
  textFieldProps,
  isLoading,
  dataTestId,
  renderOptionLabel = (option: string | T) => (typeof option === 'string' ? option : option.label)
}: TSelectProps<T>) {
  const activeOption = useMemo(() => options.find((option) => option.value === value), [options, value]);

  return (
    <Autocomplete
      value={activeOption ?? value}
      options={options}
      size="small"
      data-test-id={dataTestId}
      freeSolo={isTextInputIncluded}
      onInputChange={(_event, newValue) => {
        isTextInputIncluded && onChange(newValue);
      }}
      isOptionEqualToValue={(option, selectedValue) => {
        // eslint-disable-next-line
        // @ts-expect-error
        const valueToCompare: string = typeof option === 'string' ? option : option?.value;

        return typeof selectedValue === 'string' ? valueToCompare === selectedValue : valueToCompare === selectedValue?.value;
      }}
      onChange={(_event, newValue) => {
        if (typeof newValue !== 'string') {
          // eslint-disable-next-line
          // @ts-expect-error
          onChange(newValue?.value ?? '');
          return;
        }

        onChange(newValue ?? '');
      }}
      renderOption={(props, option) => (
        <li
          {...props}
          onClick={(event) => {
            props.onClick?.(event);
          }}
        >
          {renderOptionLabel(option as T)}
        </li>
      )}
      getOptionLabel={(option: string | TOption) => {
        if (typeof option === 'string') {
          return option;
        }

        return isTextInputIncluded ? option.value : option?.label;
      }}
      fullWidth
      className={!label ? 'MuiAutocomplete-without-label' : ''}
      loading={isLoading}
      autoComplete
      sx={{ position: 'relative' }}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            value={value}
            placeholder={placeholder}
            label={label && <Label required={required} text={label} tooltipText={tooltipText} />}
            {...textFieldProps}
          />
          {isLoading && (
            <Spinner
              isOverlay
              sx={{
                height: '36px',
                bottom: 0,
                top: 'unset',
                alignItems: 'flex-end',
                paddingRight: '6px'
              }}
            />
          )}
        </>
      )}
    />
  );
}

export default forwardRef(Select) as <T>(
  props: TSelectProps<T> & { ref?: React.ForwardedRef<HTMLElement> }
) => ReturnType<typeof Select>;

import { useEffect } from 'react';
import { useUrlFilters } from 'src/components/shared/hooks/useUrlFilters';
import { useFeedbackUnifiedContext } from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedProvider/FeedbackUnifiedProvider';
import { FeedbackFlowFilters } from 'src/components/pages/Main/Jobs/Feedback/Flow/FeedbackFlow.context';

function useFeedbackUnifiedFilters() {
  const feedbackState = useFeedbackUnifiedContext((context) => context.state);
  const advancedFilters = useFeedbackUnifiedContext((context) => context.advancedFilters);
  const { setQueryParamsFilters } = useUrlFilters<FeedbackFlowFilters>();

  useEffect(() => {
    setQueryParamsFilters({
      startDate: feedbackState.startDate ? feedbackState.startDate.toISOString() : null,
      endDate: feedbackState.endDate ? feedbackState.endDate.toISOString() : null,
      quickFilters: feedbackState.quickFilters,
      tablePageSize: feedbackState.tablePageSize,
      tablePage: feedbackState.tablePage,
      tableSortColumn: feedbackState.tableSort.column,
      tableSortOrder: feedbackState.tableSort.order,
      advancedFilters: advancedFilters.activeFilters,
      excludeProcessed: `${feedbackState.excludeProcessed}`,
      includeProcessed: `${feedbackState.includeProcessed}`
    });
  }, [
    feedbackState.startDate,
    feedbackState.endDate,
    feedbackState.quickFilters,
    feedbackState.tablePageSize,
    feedbackState.tablePage,
    feedbackState.tableSort,
    setQueryParamsFilters,
    advancedFilters.activeFilters,
    feedbackState.excludeProcessed,
    feedbackState.includeProcessed
  ]);
}

export default useFeedbackUnifiedFilters;

import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { config } from '../../config';
import { REQUEST_STATUS_NOK } from '../../components/shared/hooks/useFetch';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { FeedbackUpdateRequest } from '../../components/shared/models/feedback/FeedbackUpdateRequest';
import { FeedbackUpdateResponse } from '../../components/shared/models/feedback/FeedbackUpdateResponse';
import { useFeedbackComputeMetrics } from './useFeedbackComputeMetrics';
import { FeedbackComputeMetricsRequest } from '../../components/shared/models/feedback/FeedbackComputeMetricsRequest';

export default function useUpdateBucketFeedback(bucketId?: string, jobId?: number) {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['job', 'feedback', 'common']);
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const feedbackComputeMetricsMutation = useFeedbackComputeMetrics();

  return useMutation(
    (payload: FeedbackUpdateRequest) => axios.post(config.api.feedbackBucketsUpdate, payload),
    // BEGIN-NOSCAN
    {
      onSuccess: (response) => {
        const responseData = jsonConvert.deserializeObject(response.data.resource, FeedbackUpdateResponse);
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        if (responseData.request.status === REQUEST_STATUS_NOK) {
          showErrorNotification(
            t('feedback:actionFailed'),
            `${t('job:editLabelFailed')} Message: ${
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              responseData.request.reason
            }`
          );
          return;
        }
        // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig...
        feedbackComputeMetricsMutation.mutate(new FeedbackComputeMetricsRequest(jobId));
        showSuccessNotification(t('common:actionCompleted'), t('job:editLabelSuccessfully'));
      },
      onError: () => {
        showErrorNotification(t('feedback:actionFailed'), t('common:somethingWentWrong'));
      },
      onSettled: () => {
        if (bucketId) {
          queryClient.invalidateQueries([config.api.feedbackBucketDetails.replace(':bucketId', bucketId)]);
        }
        // TODO: create a react query hook to retrieve buckets
        queryClient.invalidateQueries([config.api.feedbackBucketsBatch]);
      }
    }
  );
  // END-NOSCAN
}

import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';

@JsonObject('UserKeyCreateResponse')
export class UserKeyCreateResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('name', String)
  name?: string = undefined;

  @JsonProperty('value', String)
  value?: string = undefined;

  @JsonProperty('created_at', String)
  created_at?: string = undefined;
}

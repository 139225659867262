import { SVGProps } from 'react';

export type CircledCheckmarkProps = SVGProps<SVGSVGElement>;

export default function CircledCheckmark({ ...props }: CircledCheckmarkProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_16127_5467)">
        <path
          d="M5.00004 7.99992L7.00004 9.99992L11 5.99992M14.6667 7.99992C14.6667 11.6818 11.6819 14.6666 8.00004
           14.6666C4.31814 14.6666 1.33337 11.6818 1.33337 7.99992C1.33337 4.31802 4.31814 1.33325 8.00004
            1.33325C11.6819 1.33325 14.6667 4.31802 14.6667 7.99992Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16127_5467">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

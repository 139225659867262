import HttpStatusCode from 'http-status-codes';

export function getErrorMsgByCode(code: string) {
  switch (code.toString()) {
    case HttpStatusCode.UNAUTHORIZED.toString():
      return 'Username or password is wrong! Please try again!';
    case HttpStatusCode.FORBIDDEN.toString():
      return 'You do not have enough permissions. Please contact the platform administrator.';
    default:
      return 'An error appeared on login. Please try again later!';
  }
}

import { Popover, Divider, Typography, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../../../../../../themes/icons';
import { DeleteArrowIcon } from '../../../../../../../themes/icons/DeleteArrowIcon';
import { QuickFiltersGroup } from './QuickFiltersGroup';
import { FeedbackQuickFilters } from '../../../../../../shared/models/feedback/FeedbackQuickFilters';
import { countNonNullValuesInMap } from '../../../../../../../utils/mapUtils';
import { getQuickFiltersMetadata } from './utils';
import { Button } from '@mui/material';
import { REQUIRING_ATTENTION_FILTERS } from './constants';

type FeedbackQuickFiltersProps = {
  quickFiltersState: FeedbackQuickFilters;
  setQuickFilters: (quickFilters: FeedbackQuickFilters) => void;
};

export default function FeedbackFlowQuickFilters({ quickFiltersState, setQuickFilters }: FeedbackQuickFiltersProps) {
  const predifinedQuickFilters = getQuickFiltersMetadata();
  const { t } = useTranslation(['feedback']);
  const [open, setOpen] = useState(false);
  const appliedFiltersCount = countNonNullValuesInMap(quickFiltersState);

  const checkAllRequiringAttention = (shouldCheckAll: boolean) => {
    setQuickFilters(
      new FeedbackQuickFilters(
        shouldCheckAll ? [...REQUIRING_ATTENTION_FILTERS] : [],
        quickFiltersState.feedback,
        quickFiltersState.training_status
      )
    );
  };
  const handleRequireAttentionGroup = (attentions: string[] | string | undefined) => {
    setQuickFilters(
      new FeedbackQuickFilters(attentions as string[], quickFiltersState.feedback, quickFiltersState.training_status)
    );
  };

  const handleFeedbackGroup = (selectedOption: string[] | string | undefined) => {
    setQuickFilters(
      new FeedbackQuickFilters(quickFiltersState.requiring_attention, selectedOption as string, quickFiltersState.training_status)
    );
  };

  const handleTrainingStatusGroup = (selectedOption: string | string[] | undefined) => {
    setQuickFilters(
      new FeedbackQuickFilters(quickFiltersState.requiring_attention, quickFiltersState.feedback, selectedOption as string)
    );
  };

  const clearFeedbackQF = () => {
    setQuickFilters(
      new FeedbackQuickFilters(quickFiltersState.requiring_attention, undefined, quickFiltersState.training_status)
    );
  };

  const clearTrainingStatusQF = () => {
    setQuickFilters(new FeedbackQuickFilters(quickFiltersState.requiring_attention, quickFiltersState.feedback, undefined));
  };

  const clearAllQF = () => {
    setQuickFilters(new FeedbackQuickFilters());
    setOpen(!open);
  };

  const content = (
    <div className={styles.container}>
      <Row className={styles.quickFilters}>
        <Col className={'p-r-20'}>
          <QuickFiltersGroup
            quickFiltersList={predifinedQuickFilters}
            filterGroup={'requiring_attention'}
            onChangeHandler={handleRequireAttentionGroup}
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
            groupSelectedOption={quickFiltersState.requiring_attention}
            showDeleteIcon={false}
            isMultipleChoice
            checkAllHandler={checkAllRequiringAttention}
          />
        </Col>
        <Divider type={'vertical'} className={styles.groupDivider} />
        <Col className={'p-l-20'} data-test-id="quick-filters-feedback-area">
          <QuickFiltersGroup
            quickFiltersList={predifinedQuickFilters}
            filterGroup={'feedback'}
            onChangeHandler={handleFeedbackGroup}
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
            groupSelectedOption={quickFiltersState.feedback}
            showDeleteIcon={quickFiltersState.feedback !== null}
            isMultipleChoice={false}
            stateHandler={clearFeedbackQF}
          />
        </Col>
        <Divider type={'vertical'} className={styles.groupDivider} />
        <Col className={'p-l-20 p-r-10'}>
          <QuickFiltersGroup
            quickFiltersList={predifinedQuickFilters}
            filterGroup={'training_status'}
            onChangeHandler={handleTrainingStatusGroup}
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
            groupSelectedOption={quickFiltersState.training_status}
            showDeleteIcon={quickFiltersState.training_status !== null}
            stateHandler={clearTrainingStatusQF}
            isMultipleChoice={false}
          />
        </Col>
      </Row>
      {appliedFiltersCount > 0 ? (
        <Row className={styles.deleteFilters}>
          <Col>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={clearAllQF}
              data-test-id="clear-all-quick-filters"
              endIcon={<DeleteArrowIcon stroke={styles.deleteArrow} />}
            >
              {t('feedback:quickFilters:clearAllQFButton')}
            </Button>
          </Col>
        </Row>
      ) : null}
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      open={open}
      onOpenChange={() => {
        setOpen(!open);
      }}
    >
      <Button
        variant={open ? 'containedHover' : 'contained'}
        color="secondary"
        size="small"
        data-test-id="feedback-quick-filters-button"
        endIcon={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
      >
        <Typography.Text className={styles.title}>
          {t('feedback:quickFilters:title')} ({appliedFiltersCount})
        </Typography.Text>
      </Button>
    </Popover>
  );
}

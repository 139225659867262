import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('NameValuePair')
export class NameValuePair {
  @JsonProperty('name', String)
  name?: string;

  @JsonProperty('value', String || Number)
  value?: string | number;
}

@JsonObject('FeedbackBucketSimilarityRecord')
export class FeedbackBucketSimilarityRecord {
  @JsonProperty('alert', [NameValuePair])
  alert: NameValuePair[];

  @JsonProperty('similarity_percentage', Number)
  similarityPercentage: number;

  @JsonProperty('arcanna_decision', String)
  arcannaDecision: string;

  @JsonProperty('consensus', String)
  consensus: string;

  @JsonProperty('bucket_id', String)
  bucketId: string;
}

@JsonObject('FeedbackBucketSimilarityResponse')
export class FeedbackBucketSimilarityResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('top_hits', [FeedbackBucketSimilarityRecord])
  topHits: FeedbackBucketSimilarityRecord[];
}

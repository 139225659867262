import { useFeatureSelectionSummary } from '../../../../../../data-access';
import { JobFeatureSelectionSummaryDetails } from '../../../../../shared/models/job/JobFeatureSelectionSummaryDetails';

type UseFeatureSelectionForTransfer = {
  jobId: number;
};
export function useFeatureSelectionForTransfer({ jobId }: UseFeatureSelectionForTransfer) {
  return useFeatureSelectionSummary({
    jobId,
    select: (features: JobFeatureSelectionSummaryDetails[]) => {
      return (
        features
          .filter((feature: JobFeatureSelectionSummaryDetails) => !feature.disabled)
          // the key is used by the transfer component to decide which items are in the target column
          .map((feature) => ({ ...feature, key: feature.name }))
      );
    }
  });
}

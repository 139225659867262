import { CardContent, Typography } from '@mui/material';
import StyledFeedbackHistory from './StyledFeedbackHistory.style';
import { useTranslation } from 'react-i18next';
import { useFeedbackBucketDetails, useJobInfo } from 'src/data-access';
import { ArcannaTable } from 'src/components/shared/components';
import { BucketUserLabelEntry } from 'src/components/shared/models/feedback/FeedbackBucketDetailsResponse';
import { formatDateTime } from 'src/components/shared/utilities/date';
import { CustomLabel } from '@arcanna/components';
import moment from 'moment';
import { useMemo } from 'react';

type FeedbackHistoryProps = {
  jobId: number;
  bucketId: string;
};

function FeedbackHistory({ jobId, bucketId }: FeedbackHistoryProps) {
  const { t } = useTranslation(['common', 'feedback']);

  const jobInfoQuery = useJobInfo(jobId);
  const jobCustomLabels = jobInfoQuery.data?.info?.advancedSettings.customLabels ?? [];
  const feedbackBucketDetailsQuery = useFeedbackBucketDetails(jobId, bucketId);

  const tableData = useMemo(() => {
    let feedbackHistory: BucketUserLabelEntry[] = [];
    if (feedbackBucketDetailsQuery.data != null) {
      // @ts-expect-error TS(2322): Type 'BucketUserLabelEntry[] | undefined' is not a...
      feedbackHistory = feedbackBucketDetailsQuery.data.bucket.userLabels.entries;
    }
    const consensusOverwritten = feedbackBucketDetailsQuery.data?.bucket.consensusOverwritten;
    if (consensusOverwritten?.consensusOverwritten === true && jobInfoQuery.data != null) {
      feedbackHistory = feedbackHistory.filter((entry) => entry.username != consensusOverwritten.consensusOverwrittenBy);
      feedbackHistory.push(
        new BucketUserLabelEntry(
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
          consensusOverwritten.consensusOverwrittenBy,
          consensusOverwritten.consensusOverwrittenDate,
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          feedbackBucketDetailsQuery.data.bucket.consensus,
          true
        )
      );
    }
    return feedbackHistory;
  }, [feedbackBucketDetailsQuery.data, jobInfoQuery.data]);

  const getLabelInfo = (labelId: string) => {
    return (
      jobCustomLabels.find((label) => label.id == labelId) ?? {
        id: labelId,
        name: labelId,
        hexColor: ''
      }
    );
  };

  const columns = [
    {
      title: t('common:username'),
      key: 'username',
      dataIndex: 'username',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      sorter: (item1: BucketUserLabelEntry, item2: BucketUserLabelEntry) => item1.username.localeCompare(item2.username)
    },
    {
      title: t('feedback:bucketExpand.feedbackLabel'),
      width: '280px',
      render: (item: BucketUserLabelEntry) => {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        const labelInfo = getLabelInfo(item.label);
        return (
          labelInfo && (
            <CustomLabel
              id={labelInfo.id}
              name={labelInfo.name}
              hexColor={labelInfo.hexColor}
              fontSize="12px"
              textStyle={{ lineHeight: '14px' }}
            />
          )
        );
      },
      sorter: (item1: BucketUserLabelEntry, item2: BucketUserLabelEntry) => {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return getLabelInfo(item1.label).name.localeCompare(getLabelInfo(item2.label).name);
      }
    },
    {
      title: t('feedback:bucketExpand.feedbackDate'),
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
      render: (item: BucketUserLabelEntry) => formatDateTime(item.labelTimestamp),
      sorter: (item1: BucketUserLabelEntry, item2: BucketUserLabelEntry) => {
        return moment(item1.labelTimestamp).isSameOrAfter(moment(item2.labelTimestamp)) ? 1 : -1;
      }
    }
  ];

  return (
    <StyledFeedbackHistory>
      <CardContent>
        <Typography paddingBottom="16px" variant="subtitle1">
          {t('feedback:bucketExpand.feedbackHistory')}
        </Typography>

        <ArcannaTable
          columns={columns}
          dataSource={tableData}
          headBodySpace
          pagination={false}
          loading={false}
          arcannaSize="middle"
        />
      </CardContent>
    </StyledFeedbackHistory>
  );
}

export default FeedbackHistory;

import { Card, CardProps, styled } from '@mui/material';

const StyledFeedbackHistory = styled(Card)<CardProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary[900],
  border: `1px solid ${theme.palette.secondary[800]}`,
  height: '272px',
  flexGrow: 1,

  '.MuiCardContent-root': {
    padding: '16px'
  },

  '.MuiTypography-subtitle1': {
    color: theme.palette.secondary[400],
    fontWeight: 400
  }
}));

export default StyledFeedbackHistory;

import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { useFeedbackBucketDetails, useJobInfo } from '../../../../../../../data-access';
import { ETextGenerationState } from '../../../../../../shared/models/job/LLMSettings';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGenerateBucketSummary from '../../../../../../../data-access/feedback/useGenerateBucketSummary';
import { BucketGenerateSummaryRequest } from '../../../../../../shared/models/feedback/BucketGenerateSummaryRequest';

type TBucketSummaryProps = {
  jobId: number;
  bucketId: string;
};

const BUCKET_DETAILS_REFETCH_MILLIS = 3000;

export default function BucketSummary({ jobId, bucketId }: TBucketSummaryProps) {
  const [shouldRefetchBucketDetails, setShouldRefetchBucketDetails] = useState<number | false>(false);
  const bucketDetailsQuery = useFeedbackBucketDetails(jobId, bucketId, shouldRefetchBucketDetails);
  const jobInfoQuery = useJobInfo(jobId);
  const { t } = useTranslation(['feedback']);
  const theme = useTheme();
  const generateBucketSummaryMutation = useGenerateBucketSummary({ bucketId });

  const generateSummaryOnDemand = () => {
    const payload = new BucketGenerateSummaryRequest(jobId, bucketId);
    generateBucketSummaryMutation.mutate(payload);
  };

  const summaryBox = () => {
    if (!bucketDetailsQuery.data || !jobInfoQuery.data) {
      return null;
    }

    if (bucketDetailsQuery.data?.bucket.summarization?.text) {
      return (
        <Box>
          <Typography variant="subtitle2">{bucketDetailsQuery.data?.bucket.summarization.text}</Typography>
        </Box>
      );
    }
    if (
      // @ts-expect-error TS(2345):
      jobInfoQuery.data?.info.advancedSettings.llmSettings.summarization.buckets?.text_generation ===
        ETextGenerationState.ON_DEMAND &&
      // @ts-expect-error TS(2345):
      !bucketDetailsQuery.data?.bucket.summarization.onDemandRequest
    ) {
      return (
        <Button
          onClick={() => {
            generateSummaryOnDemand();
          }}
          size={'small'}
          variant={'contained'}
        >
          {t('bucketExpand.summarization.generateBtn')}
        </Button>
      );
    }

    if (
      // @ts-expect-error TS(2345):
      jobInfoQuery.data?.info.advancedSettings.llmSettings.summarization.buckets.text_generation ===
        ETextGenerationState.AUTOMATIC ||
      // @ts-expect-error TS(2345):
      bucketDetailsQuery.data?.bucket.summarization.onDemandRequest
    ) {
      if (shouldRefetchBucketDetails === false) {
        setShouldRefetchBucketDetails(BUCKET_DETAILS_REFETCH_MILLIS);
      }
      return (
        <Box>
          <Typography variant="subtitle2">{t('bucketExpand.summarization.loadingMessage')}</Typography>
        </Box>
      );
    }

    if (
      [ETextGenerationState.DISABLED.valueOf(), null].includes(
        // @ts-expect-error TS(2345):
        jobInfoQuery.data?.info.advancedSettings.llmSettings.summarization.buckets.text_generation
      )
    ) {
      return (
        <Box>
          <Typography variant="subtitle2">{t('bucketExpand.summarization.summarizationDisabledMsg')}</Typography>
        </Box>
      );
    }
  };

  return (
    <Box
      bgcolor={theme.palette.secondary[900]}
      padding={'4px 8px 4px 8px'}
      border={`1px solid ${theme.palette.secondary[800]}`}
      borderRadius={'4px'}
    >
      <Typography
        color={theme.palette.secondary[300]}
        sx={{ textDecoration: 'underline', fontWeight: 500 }}
        variant={'subtitle1'}
      >
        {t('bucketExpand.summarization.title')}
      </Typography>
      <Divider sx={{ margin: '0px -8px' }} />
      <Box maxHeight={'180px'} overflow={'scroll'} marginTop={'4px'}>
        {summaryBox()}
      </Box>
    </Box>
  );
}

import { CustomLabel as Label } from '../../../../../../../components/shared/models/job/AdvancedJobSettings';
import CustomLabel from '../../../../../../shared/components/CustomLabel';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useFeedbackBucketDetails, useJobInfo } from 'src/data-access';

type ModelResultAndUserFeedbackLabelsProps = {
  jobId: number;
  bucketId: string;
  modelDecision: Label | undefined;
  userLabel: Label;
};

export default function ModelResultAndUserFeedbackLabels({
  jobId,
  bucketId,
  modelDecision,
  userLabel
}: ModelResultAndUserFeedbackLabelsProps) {
  const { t } = useTranslation(['common', 'feedback']);
  const jobInfoQuery = useJobInfo(jobId);

  const isStriked = userLabel && modelDecision?.id !== userLabel?.id;
  const bucketDetailsQuery = useFeedbackBucketDetails(jobId, bucketId);

  const hasConsensusOverwritten = useMemo(
    () => bucketDetailsQuery.data?.bucket.consensusOverwritten?.consensusOverwrittenBy != null,
    [bucketDetailsQuery.data]
  );

  const consensusOverwrittenTooltip = useMemo(() => {
    if (hasConsensusOverwritten) {
      const jobCustomLabels = jobInfoQuery.data?.info?.advancedSettings.customLabels ?? [];
      const overwrittenLabel = jobCustomLabels.find((label) => label.id == bucketDetailsQuery.data?.bucket.consensus);

      return t('feedback:bucketExpand:overwrittenConsensusIs', { overwrittenLabel: overwrittenLabel?.name });
    }
  }, [bucketDetailsQuery.data, jobInfoQuery.data, hasConsensusOverwritten, t]);

  return (
    <Stack direction="row" spacing={1}>
      {modelDecision ? (
        <CustomLabel
          hexColor={modelDecision?.hexColor}
          id={modelDecision?.id}
          name={modelDecision?.name}
          strike={isStriked}
          disabled={isStriked}
          isConfirmed={modelDecision?.id === userLabel?.id}
          style={{ position: 'relative' }}
          hasConsensusOverwritten={hasConsensusOverwritten && !isStriked}
          consensusOverwrittenTooltip={consensusOverwrittenTooltip}
        />
      ) : (
        <Typography variant="subtitle1">{t('feedback:noDecision')}</Typography>
      )}
      {modelDecision !== userLabel && userLabel && (
        <CustomLabel
          hexColor={userLabel?.hexColor}
          id={userLabel?.id}
          name={userLabel?.name}
          hasConsensusOverwritten={hasConsensusOverwritten}
          consensusOverwrittenTooltip={consensusOverwrittenTooltip}
        />
      )}
    </Stack>
  );
}

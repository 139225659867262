import { Box, BoxProps, styled } from '@mui/material';

const StyledBucketDrawerHeader = styled(Box)<BoxProps>(({ theme }) => ({
  '.title': {
    color: theme.palette.secondary[200],
    fontSize: '20px',
    lineHeight: '28px',
    weight: '600',
    display: 'inline-block'
  },
  '.statusTagWrapper': {
    display: 'inline-block',
    position: 'relative',
    top: '-3px',
    marginLeft: '10px'
  }
}));

export default StyledBucketDrawerHeader;

import { useMemo, useRef, useState } from 'react';
import { Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ComposedChart, Tooltip } from 'recharts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useJobInfo, useJobAlertsTimeseries } from '../../../../../../../../data-access';
import { JobAlertsTimeseriesRequest, CustomLabel } from '../../../../../../../../components/shared/models';
import { useJobOverviewTimeseries } from '../../../../../../../../components/shared/hooks';
import { shortenNumber } from '../../../../../../../../utils/shortenNumber';
import { useStartDateByPeriod } from '../../../../../../../../components/shared/hooks/useStartDateByPeriod';
import { PeriodOptions } from '../../../../../../../../components/shared/models/filters/period';
import TooltipJobSeriesAlerts from './TooltipJobSeriesAlerts';
import { Box, CardContent, Stack, Typography, useTheme } from '@mui/material';
import StyledArcannaDecisionTimeseries from './StyledArcannaDecisionTimeseries.styles';
import { Spinner, EmptyState } from '@arcanna/generic';
import FilterByPeriod from './FilterByPeriod';

export type ArcannaDecisionTimeseriesProps = {
  jobId: number;
  shortBucketId: string | undefined;
};

function ArcannaDecisionTimeseries({ jobId, shortBucketId: bucketId }: ArcannaDecisionTimeseriesProps) {
  const theme = useTheme();
  const responsiveContainerRef = useRef(null);
  const { t } = useTranslation('job');
  const jobInfoQuery = useJobInfo(jobId);
  const jobCustomLabels = useMemo(
    () => jobInfoQuery.data?.info?.advancedSettings.customLabels ?? [],
    [jobInfoQuery.data?.info?.advancedSettings.customLabels]
  );

  const [period, setPeriod] = useState(PeriodOptions.ALL_TIME);
  const [isDefaultPeriod, setIsDefaultPeriod] = useState(true);
  const startDate = useStartDateByPeriod(period);

  // eslint-disable-next-line
  const now = useMemo(() => moment().toDate(), [startDate]);
  const endDate = useMemo(() => (period === PeriodOptions.ALL_TIME ? null : now), [period, now]);

  const { formatLabel, computeTicks, tickFormatter } = useJobOverviewTimeseries({
    jobId,
    startDate,
    // @ts-expect-error TS(2322): Type 'Date | null' is not assignable to type 'Date...
    endDate,
    selectedLabels: jobCustomLabels,
    period,
    bucketId
  });

  const jobAlertsTimeseriesQuery = useJobAlertsTimeseries(
    // @ts-expect-error TS(2345): Argument of type 'Date | null' is not assignable t...
    new JobAlertsTimeseriesRequest(jobId, startDate, endDate, bucketId),
    jobCustomLabels
  );

  const allDataTotal = useMemo(() => {
    return (
      jobAlertsTimeseriesQuery.data?.graphDataSource
        .map((entry) => entry.totalCount)
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        .reduce((entry1, entry2) => entry1 + entry2, 0)
    );
  }, [jobAlertsTimeseriesQuery.data]);

  const tooltipY = useMemo(() => {
    const tooltipEstimatedHeight = 20 * jobCustomLabels.length + 40;

    return -tooltipEstimatedHeight;
  }, [jobCustomLabels]);

  // eslint-disable-next-line
  const customBarShape = ({ fill, x, y, width, height }: any) => {
    return <rect x={x - Math.round(width / 2)} y={y} width={Math.min(40, Math.round(width * 2))} height={height} fill={fill} />;
  };

  const onSetPeriod = (currentPeriod: PeriodOptions) => {
    setPeriod(currentPeriod);
    setIsDefaultPeriod(false);
  };

  return (
    <>
      {jobAlertsTimeseriesQuery?.isLoading ? (
        <Spinner isOverlay />
      ) : (
        <StyledArcannaDecisionTimeseries>
          <CardContent>
            {allDataTotal == 0 && isDefaultPeriod ? (
              <EmptyState
                title={t('jobTimeseries.missingAlertsErrorTitle')}
                subtitle={t('jobTimeseries.missingAlertsErrorText')}
                alignItems="center"
              />
            ) : (
              <>
                <Stack direction="column" gap="8px">
                  <Typography variant="subtitle1">{t('feedback:bucketExpand.decisionTimeseriesTitle')}</Typography>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <FilterByPeriod period={period} setPeriod={onSetPeriod} />
                    <Stack direction="row">
                      <Typography variant="subtitle1">{t('feedback:bucketExpand.processedEvents')}:</Typography>
                      <Typography variant="subtitle1" className="eventNumber">
                        {/* @ts-expect-error */}
                        {allDataTotal.toLocaleString('en-US')}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                {allDataTotal == 0 ? (
                  <EmptyState
                    title={t('jobTimeseries.missingAlertsErrorTitle')}
                    subtitle={t('jobTimeseries.missingAlertsErrorText')}
                  />
                ) : (
                  <Box>
                    <Box>
                      <ResponsiveContainer ref={responsiveContainerRef} width="100%" aspect={4.0 / 1.0}>
                        <ComposedChart
                          data={jobAlertsTimeseriesQuery.data?.graphDataSource ?? []}
                          margin={{
                            top: 50,
                            bottom: 25,
                            left: -10,
                            right: 10
                          }}
                        >
                          <XAxis
                            dataKey="date"
                            tickFormatter={(dateString) => moment(dateString).format(tickFormatter)}
                            ticks={computeTicks(jobAlertsTimeseriesQuery.data?.graphDataSource ?? [])}
                            tick={{ fill: theme.palette.secondary[400], fontSize: '12px' }}
                          />

                          <YAxis
                            axisLine={false}
                            tickLine={false}
                            tickFormatter={shortenNumber}
                            tick={{ fill: theme.palette.secondary[400], fontSize: '12px' }}
                          />

                          <Tooltip
                            allowEscapeViewBox={{ y: true }}
                            wrapperStyle={{
                              display: 'inherit',
                              zIndex: 9999
                            }}
                            position={{ y: tooltipY }}
                            content={({ active, payload }) => (
                              <TooltipJobSeriesAlerts
                                // @ts-expect-error
                                active={active}
                                payload={payload}
                                label={formatLabel(payload)}
                                jobLabels={jobCustomLabels}
                              />
                            )}
                            cursor={false}
                          />

                          <CartesianGrid vertical={false} stroke={'var(--secondary-color-nb-800)'} />

                          <Bar key="empty" dataKey="empty" stackId="stack" />
                          {[...jobCustomLabels].reverse().map((labelInfo: CustomLabel) => {
                            if (labelInfo) {
                              const labelId = labelInfo.id;
                              return (
                                <Bar
                                  key={labelId}
                                  dataKey={labelId}
                                  fill={labelInfo.hexColor}
                                  stackId="stack"
                                  shape={customBarShape}
                                />
                              );
                            }
                          })}
                        </ComposedChart>
                      </ResponsiveContainer>
                    </Box>
                    <Stack direction="column" alignItems="center">
                      <Typography variant="subtitle2">
                        {/* @ts-expect-error */}
                        {moment(jobAlertsTimeseriesQuery.data.interval.date_start).format('DD MMM YYYY, HH:mm')} -&nbsp;
                        {/* @ts-expect-error */}
                        {moment(jobAlertsTimeseriesQuery.data.interval.date_end).format('DD MMM YYYY, HH:mm')}
                        {/* @ts-expect-error */}
                        &nbsp; (per {jobAlertsTimeseriesQuery.data.interval.unit_friendly_format})
                      </Typography>
                    </Stack>
                  </Box>
                )}
              </>
            )}
          </CardContent>
        </StyledArcannaDecisionTimeseries>
      )}
    </>
  );
}

export default ArcannaDecisionTimeseries;

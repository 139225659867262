import { Col, Row, Typography } from 'antd';
import { JobCategory } from '../../helper';
import { useJobInfo, useJobMetrics, useJobRetrainHistory } from '../../../../../../data-access';
import styles from './style.module.css';
import { useTranslation } from 'react-i18next';
import PiePercent from './PiePercent';
import Button from '../../../../../shared/components/Button/Button';
import { useMemo, useState } from 'react';
import RetrainHistoryDrawer from './RetrainHistoryDrawer';
import moment from 'moment/moment';
import { useQueryClient } from 'react-query';
import { config } from '../../../../../../config';
import { formatNumber } from '../../../../../../utils/numeral';
import DecisionBreakdownCard from './DecisionBreakdownCard';
import useDecisionBreakdown from './useDecisionBreakdown.hook';
import { CustomLabel, RefreshButton } from '@arcanna/components';
import { BucketTrainingStatus } from '../../../../../../constants/BucketTrainingStatus';
import { JobTrainingStatusBreakdownEntry } from '../../../../../shared/models/job/JobTrainingStatusBreakdownEntry';
import { useFeedbackBucketsCount } from '../../../../../../data-access/feedback/useFeedbackBucketsCount';
import JobTimeMetrics from '../JobMetrics/JobTimeMetrics';
import PipelineActions from '../JobMetrics/PipelineActions';

type JobSummaryProps = {
  jobId: number;
};
export default function JobSummary({ jobId }: JobSummaryProps) {
  const jobInfoQuery = useJobInfo(+jobId);
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const currentModelInUse = jobInfoQuery.data?.info.processor.lastModelPath?.split('.')[0];
  const jobMetricsQuery = useJobMetrics(+jobId, currentModelInUse);
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
  const modelHistoryQuery = useJobRetrainHistory(jobId, currentModelInUse);
  // @ts-expect-error TS(2345): Argument of type 'JobTrainingStatusBreakdownEntry[...
  const bucketsCountQuery = useFeedbackBucketsCount(+jobId, modelHistoryQuery.data?.trainingStatusBreakdown);

  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
  const { decisionBreakdown } = useDecisionBreakdown(jobId, currentModelInUse);
  const queryClient = useQueryClient();
  const { t } = useTranslation(['overview']);
  const jobCategory = jobInfoQuery.data?.info?.jobDetails?.category_id;
  const lastTrainTimestamp = modelHistoryQuery.data?.trainDate
    ? moment(modelHistoryQuery.data?.trainDate).format('MMM DD, YYYY HH:mm')
    : null;

  const refreshAllData = () => {
    queryClient.invalidateQueries([
      // @ts-expect-error TS(2769): No overload matches this call.
      config.api.jobModel.replace(':jobId', jobId.toString()).replace(':modelId', currentModelInUse)
    ]);
    queryClient.invalidateQueries([config.api.jobRetrainMetrics.replace(':jobId', jobId.toString())]);
    queryClient.invalidateQueries([config.api.jobInfo.replace(':id', `${jobId}`)]);
    queryClient.invalidateQueries([config.api.jobAllRetrainHistory.replace(':jobId', jobId.toString())]);
    queryClient.invalidateQueries([config.api.feedbackStatisticsBucketsCount]);
  };

  // @ts-expect-error TS(2322): Type 'JobTrainingStatusBreakdownEntry | undefined'...
  const inModelTrainingStatusBreakdown: JobTrainingStatusBreakdownEntry = useMemo(
    () => modelHistoryQuery.data?.trainingStatusBreakdown?.find((status) => status.name === BucketTrainingStatus.IN_MODEL),
    [modelHistoryQuery.data?.trainingStatusBreakdown]
  );

  // @ts-expect-error TS(2322): Type 'JobTrainingStatusBreakdownEntry | undefined'...
  const newTrainingStatusBreakdown: JobTrainingStatusBreakdownEntry = useMemo(
    () => modelHistoryQuery.data?.trainingStatusBreakdown?.find((status) => status.name === BucketTrainingStatus.NEW),
    [modelHistoryQuery.data?.trainingStatusBreakdown]
  );

  const [openRetrainHistoryDrawer, setOpenRetrainHistoryDrawer] = useState(false);

  const buildHoverMessage = () => {
    if (!lastTrainTimestamp) {
      return t('noTrainingRoundCompleted');
    }
    if (jobMetricsQuery.data?.feedbackSessionMetrics?.accuracy == null) {
      return t('noFeedbackGiven');
    }
    return '';
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={3} className="color-nb-0 m-b-16">
            {t('jobSummaryTitle')}
          </Typography.Title>
        </Col>
        <Col>
          <RefreshButton dataTestId="refresh-summary" onRefresh={refreshAllData} />
        </Col>
      </Row>
      <Row className={styles.jobSummaryContainer} justify="space-between">
        <Col className={styles.sectionBorderBottom} span={24}>
          <Row justify="space-between">
            <Col span={16} style={{ padding: 24 }}>
              {jobCategory === JobCategory.DECISION_INTELLIGENCE ? <JobTimeMetrics jobId={jobId} /> : null}
            </Col>
            <Col span={5} style={{ padding: 24 }} className={styles.sectionBorderLeft}>
              {jobCategory === JobCategory.DECISION_INTELLIGENCE ? <PipelineActions jobId={jobId} /> : null}
            </Col>
            <Col span={3}></Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col span={16} style={{ padding: 24 }}>
              <Row justify="space-between">
                <Col span={24} style={{ marginBottom: 12 }}>
                  <Typography.Text className={styles.aiModelTitle}>{t('aiModel')}</Typography.Text>
                </Col>
                <Col style={{ marginRight: 20 }}>
                  <Typography.Text className={styles.trainedOnText}>{t('trainedOn')}</Typography.Text>
                  {lastTrainTimestamp ? (
                    <Typography.Text className={styles.aiModelDate} data-test-id="trained-on">
                      {lastTrainTimestamp}
                    </Typography.Text>
                  ) : (
                    <Typography.Text className={styles.dash}>-</Typography.Text>
                  )}
                </Col>
                <Col style={{ marginRight: 20 }}>
                  <PiePercent
                    title={t('accuracy')}
                    info={buildHoverMessage()}
                    infoCircle={t('accuracyMetricExplanation')}
                    // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
                    percent={jobMetricsQuery.data?.feedbackSessionMetrics.accuracy}
                    dataTestId="accuracy-value"
                  />
                </Col>
                <Col style={{ marginRight: 20 }}>
                  <PiePercent
                    title={t('fScore')}
                    info={buildHoverMessage()}
                    infoCircle={t('fScoreMetricExplanation')}
                    // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
                    percent={jobMetricsQuery.data?.feedbackSessionMetrics.fScoreWeightedAverage}
                    dataTestId="f-score-value"
                  />
                </Col>
                <Col className={styles.viewHistoryBtnContainer}>
                  <Button
                    type="secondaryNew"
                    size="small"
                    onClick={() => {
                      setOpenRetrainHistoryDrawer(true);
                    }}
                    dataTestId="view-history-button"
                  >
                    <Typography.Text className={styles.viewHistoryBtnText}>{t('viewHistory')}</Typography.Text>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={4} className={styles.sectionBorderLeft}>
              <Row style={{ padding: 24 }}>
                <Col span={24} className="m-b-12">
                  <Typography.Text className={styles.kbText}>{t('inModelData')}</Typography.Text>
                </Col>
                <Col className="m-r-18">
                  <Typography.Text className={styles.kbSubTitles}>{t('buckets')}</Typography.Text>
                  <Typography.Text data-test-id="all-buckets-count" className={styles.kbSizeNumbers}>
                    {formatNumber(inModelTrainingStatusBreakdown?.buckets)}
                  </Typography.Text>
                </Col>
                <Col className="m-r-18">
                  <Typography.Text className={styles.kbSubTitles}>{t('events')}</Typography.Text>
                  <Typography.Text data-test-id="all-events-count" className={styles.kbSizeNumbers}>
                    {formatNumber(inModelTrainingStatusBreakdown?.events)}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={4} className={styles.sectionBorderLeft}>
              <Row style={{ padding: 24 }}>
                <Col span={24} className="m-b-12">
                  <Typography.Text className={styles.kbText}>{t('newData')}</Typography.Text>
                </Col>
                <Col className="m-r-18">
                  <Typography.Text className={styles.kbSubTitles}>{t('buckets')}</Typography.Text>
                  <Typography.Text data-test-id="all-buckets-count" className={styles.kbSizeNumbers}>
                    {formatNumber(
                      newTrainingStatusBreakdown?.buckets == undefined
                        ? bucketsCountQuery.data?.buckets
                        : newTrainingStatusBreakdown?.buckets
                    )}
                  </Typography.Text>
                </Col>
                <Col className="m-r-18">
                  <Typography.Text className={styles.kbSubTitles}>{t('events')}</Typography.Text>
                  <Typography.Text className={styles.kbSizeNumbers}>
                    {formatNumber(
                      newTrainingStatusBreakdown?.events == undefined
                        ? bucketsCountQuery.data?.events
                        : newTrainingStatusBreakdown?.events
                    )}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} className={styles.decisionBreakdownContainer}>
          <Row>
            <Col span={24} className="m-b-16">
              <Typography.Text className={styles.decisionBreakdownTxt}>{t('decisionBreakdown')}</Typography.Text>
            </Col>
          </Row>
          <div className={styles.decisionBreakdownRow}>
            {decisionBreakdown?.map((decision) => (
              <DecisionBreakdownCard
                key={decision.label}
                label={
                  decision.label === t('all') ? (
                    decision.label
                  ) : (
                    <CustomLabel hexColor={decision.hexColor} name={decision.label} width={210} />
                  )
                }
                // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
                events={decision.events}
                // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
                buckets={decision.buckets}
                eventsPercentage={decision.eventsPercentage}
                borderColor={decision.hexColor}
              />
            ))}
          </div>
        </Col>
      </Row>
      <RetrainHistoryDrawer jobId={jobId} open={openRetrainHistoryDrawer} onClose={() => setOpenRetrainHistoryDrawer(false)} />
    </div>
  );
}

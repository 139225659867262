import { TableHeadProps, styled, TableHead, alpha } from '@mui/material';

const StyledTableHead = styled(TableHead, {
  shouldForwardProp(propName) {
    return propName !== 'actionsColumnSize';
  }
})<
  TableHeadProps & {
    actionsColumnSize: number;
  }
>(({ theme, actionsColumnSize }) => ({
  '&:after': { color: 'transparent', content: '"-"', display: 'block', lineHeight: '10px' },
  position: 'sticky',
  top: '0',
  margin: '0 0 0 0',
  zIndex: 2,

  '.table-head-row': {
    background: theme.palette.secondary[800],
    borderRadius: '4px',
    boxShadow: `0px 8px 5px 0px ${alpha(theme.palette.secondary[900], 0.9)}`
  },

  '.table-head-cell': {
    borderBottom: 0,
    padding: '0 8px',
    fontSize: '12px',
    lineHeight: '20px',
    position: 'sticky',
    left: 0,
    background: theme.palette.secondary[800],
    zIndex: 1,
    width: `${actionsColumnSize}px`
  }
}));

export default StyledTableHead;

import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonPaginationRequest } from '../common/CommonPaginationRequest';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('UserSummaryRequest')
export class UserSummaryRequest extends CommonRequest {
  @JsonProperty('pagination', CommonPaginationRequest)
  pagination?: CommonPaginationRequest = undefined;

  constructor(pagination: CommonPaginationRequest) {
    super();
    this.pagination = pagination;
  }
}

import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('BucketGenerateSummaryRequest')
export class BucketGenerateSummaryRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number;

  @JsonProperty('bucket_id', String)
  bucket_id?: string;

  constructor(job_id: number, bucket_id: string) {
    super();
    this.job_id = job_id;
    this.bucket_id = bucket_id;
  }
}

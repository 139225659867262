import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { useCallback } from 'react';

export function useUrlFilters<T>() {
  const location = useLocation();
  const history = useHistory();

  const encode = (str: string) => window.btoa(str);

  const decode = (str: string) => {
    try {
      return window.atob(str);
    } catch (err) {
      return str;
    }
  };

  const filtersToParams = useCallback(
    (filtersObject: T) => qs.stringify(filtersObject, { encodeValuesOnly: true, strictNullHandling: true }),
    []
  );
  const paramsToFilters = useCallback((params: string) => qs.parse(params, { strictNullHandling: true }), []);

  const setQueryParamsFilters = useCallback(
    (filtersObject: T) => {
      const stringifiedFilters = filtersToParams(filtersObject);
      const encodedFilters = encode(stringifiedFilters);
      history.replace({ pathname: location.pathname, search: `?filters=${encodedFilters}` });
    },
    [filtersToParams, history, location.pathname]
  );

  const getFiltersFromQueryParams = useCallback(
    (initialFilters: T) => {
      if (location.search) {
        // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable...
        const decoded = decode(new URLSearchParams(location.search).get('filters'));
        const filtersObject = paramsToFilters(decoded);

        return { ...initialFilters, ...filtersObject };
      }

      return initialFilters;
    },
    [paramsToFilters, location.search]
  );

  return {
    setQueryParamsFilters,
    getFiltersFromQueryParams,
    filtersToParams,
    paramsToFilters,
    encode,
    decode
  };
}

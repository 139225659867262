import { TUnifiedDecisionPointsResponse } from '@arcanna/models/Jobs';
import { Cell, Row } from '@tanstack/react-table';
import { getIsDPDisabled, getIsDPDisabledExcludingJobStatus } from '../../DPUnifiedTable.utils';
import { Checkbox, StateChip, TCheckboxState } from '@arcanna/generic';
import _ from 'lodash';
import { JobSummaryRecord } from 'src/components/shared/models/job/JobSummaryRecord';
import { Stack, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TDPFieldNameCellProps = {
  cell: Cell<TUnifiedDecisionPointsResponse, string>;
  row: Row<TUnifiedDecisionPointsResponse>;
  handleCheckboxChange: (state: TCheckboxState, fieldName: string, jobId: number | undefined) => void;
  jobSummaryRecords: JobSummaryRecord[];
};

function DPFieldNameCell({ cell, row, handleCheckboxChange, jobSummaryRecords }: TDPFieldNameCellProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const selectedCount = Object.keys(_.omit(row.original, 'fieldName')).filter(
    (jobId) => row.original[Number(jobId)]?.isDecisionPoint === true
  ).length;
  const possibleSelectionCount = Object.keys(_.omit(row.original, 'fieldName')).filter((jobId) => {
    const jobSummaryRecord = jobSummaryRecords.find((jobRecordItem) => jobRecordItem.id === Number(jobId));

    return !getIsDPDisabled(row.original[Number(jobId)], jobSummaryRecord).isDisabled;
  }).length;
  const possibleSelectionCountExcludingJobStatus = Object.keys(_.omit(row.original, 'fieldName')).filter(
    (jobId) => !getIsDPDisabledExcludingJobStatus(row.original[Number(jobId)])
  ).length;
  const isCustomField = Object.keys(_.omit(row.original, 'fieldName')).some((jobId) => row.original[Number(jobId)].isCustomField);

  const getCheckboxState = (): TCheckboxState => {
    if (
      (selectedCount === possibleSelectionCount && selectedCount > 0) ||
      (selectedCount === possibleSelectionCountExcludingJobStatus && possibleSelectionCount <= 0)
    ) {
      return 'checked';
    }

    if (selectedCount > 0) {
      return 'indeterminate';
    }

    return 'default';
  };

  return (
    <Checkbox
      label={
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          paddingLeft={isCustomField ? '16px' : '46px'}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-all'
          }}
        >
          {isCustomField && (
            <Tooltip title={t('common:customField')} arrow placement="top">
              <Stack>
                <StateChip
                  color={theme.palette.secondary[300]}
                  text={t('common:customFieldShortIndicator')}
                  borderRadius={0.5}
                  paddingY="1px"
                />
              </Stack>
            </Tooltip>
          )}
          {cell.getValue()}
        </Stack>
      }
      sx={{
        overflow: 'hidden',
        width: '100%'
      }}
      disabled={possibleSelectionCount === 0}
      state={getCheckboxState()}
      onChange={(state) => handleCheckboxChange(state, row.original.fieldName, undefined)}
    />
  );
}

export default DPFieldNameCell;

import { TOption } from '@arcanna/generic';
import { TFunction } from 'react-i18next';
import { FeedbackTimeSelectorOption } from 'src/components/pages/Main/Jobs/Feedback/Flow/FeedbackFlow.hook';

export const getTimeOptions = (t: TFunction): TOption[] => [
  {
    value: FeedbackTimeSelectorOption.ALL_TIME,
    label: t('feedback:timeSelectorOptions.allTime')
  },
  {
    value: FeedbackTimeSelectorOption.LAST_30_DAYS,
    label: t('feedback:timeSelectorOptions.last30Days')
  },
  {
    value: FeedbackTimeSelectorOption.LAST_7_DAYS,
    label: t('feedback:timeSelectorOptions.last7Days')
  },
  {
    value: FeedbackTimeSelectorOption.LAST_24_HOURS,
    label: t('feedback:timeSelectorOptions.last24h')
  }
];

import { styled, TableCell, TableCellProps } from '@mui/material';
import { computeTableHeaderCellCSSVariableName } from '../../Table.utils';
import { ColumnPinningPosition } from '@tanstack/react-table';

const StyledTableHeaderCell = styled(TableCell, {
  shouldForwardProp(propName) {
    return (
      propName !== 'isDragging' &&
      propName !== 'isPinned' &&
      propName !== 'columnId' &&
      propName !== 'isLastLeftPinnedColumn' &&
      propName !== 'leftStartPosition' &&
      propName !== 'actionsColumnSize' &&
      propName !== 'hasDataSorting'
    );
  }
})<
  TableCellProps & {
    isPinned: ColumnPinningPosition;
    columnId: string;
    isLastLeftPinnedColumn: boolean;
    leftStartPosition: number;
    isDragging: boolean;
    actionsColumnSize: number;
    hasDataSorting: boolean;
  }
>(({ theme, actionsColumnSize, isDragging, isPinned, columnId, isLastLeftPinnedColumn, leftStartPosition, hasDataSorting }) => ({
  borderBottom: 0,
  padding: '0 8px 0 4px',
  fontSize: '12px',
  lineHeight: '20px',
  maxWidth: `var(${computeTableHeaderCellCSSVariableName(columnId)})`,
  width: `var(${computeTableHeaderCellCSSVariableName(columnId)})`,
  transition: 'width transform 0.2s ease-in-out',

  '.triggers': {
    position: 'absolute',
    alignSelf: 'center',
    height: '100%',
    background: theme.palette.secondary[800],
    color: theme.palette.secondary[200],
    display: 'none',
    right: '7px',
    top: 0,
    paddingTop: 6
  },
  '.info-circle': {
    alignSelf: 'center'
  },
  '.info-trigger': {
    height: '100%',
    alignContent: 'center',
    paddingRight: 4
  },
  '.drag-trigger': {
    height: '100%',
    alignContent: 'center',
    paddingRight: 4,
    cursor: 'pointer',
    '& svg': {
      outline: 0
    }
  },

  ...(isPinned
    ? {
        boxShadow: isLastLeftPinnedColumn ? `-4px 0 4px -4px ${theme.palette.secondary[600]} inset` : 'none',
        left: isPinned === 'left' ? `${leftStartPosition + actionsColumnSize}px` : undefined,
        position: 'sticky',
        zIndex: isPinned ? 1 : 0,
        background: isPinned ? theme.palette.secondary[800] : 'transparent'
      }
    : {
        opacity: isDragging ? 0.8 : 1,
        position: 'relative',
        whiteSpace: 'nowrap',
        zIndex: isDragging ? 1 : 0
      }),

  '.content': {
    justifyContent: 'flex-start',
    width: 'calc(100% - 20px)',
    textDecoration: 'none',
    padding: '8px 0',
    '.MuiButton-endIcon': {
      marginLeft: '2px'
    },
    cursor: hasDataSorting ? 'pointer' : 'default',
    '&:hover': {
      ...(!hasDataSorting
        ? {
            cursor: 'default',
            textDecoration: 'none'
          }
        : {})
    }
  },

  '.pin-trigger': {
    cursor: 'pointer',
    alignItems: 'center',
    paddingRight: '4px',
    color: theme.palette.secondary[200],
    height: '16px'
  },
  '.resizer': {
    display: 'none',
    '&.isResizing': {
      display: 'block',
      position: 'absolute',
      height: '20px',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      right: 0,
      padding: '0 2px',
      background: theme.palette.secondary[200]
    }
  },
  '&:first-child': { borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' },
  '&:last-child': { borderTopRightRadius: '4px', borderBottomRightRadius: '4px' },
  '&:hover': {
    '.resizer': {
      display: 'block',
      position: 'absolute',
      height: '20px',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      right: 0,
      padding: '0 2px',
      width: '4px',
      background: theme.palette.secondary[200],
      cursor: 'col-resize',
      userSelect: 'none',
      touchAction: 'none'
    },
    '.triggers': {
      display: 'flex'
    }
  }
}));

export default StyledTableHeaderCell;

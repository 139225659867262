import { useCallback } from 'react';
import { Row, Col, Form, Input, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { useUserKeyHook } from './UserKey.hook';
import Button from '../../../shared/components/Button/Button';
import { useUserKeyContext } from './UserKey.context';
import style from './UserKey.module.css';
import cx from 'classnames';
import { config } from '../../../../config';
import { useHistory } from 'react-router-dom';

export function UserKeyCreate() {
  const { state: userKeyState } = useUserKeyContext();
  const { t } = useTranslation(['common', 'user']);
  const [form] = Form.useForm();
  const history = useHistory();

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 23 }
  };

  const copyToClipboard = useCallback(() => {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    copy(userKeyState.userKeyCreateResponse?.value);
  }, [userKeyState.userKeyCreateResponse]);

  const navigateToList = () => {
    history.push(config.routes.userKey);
  };

  const { handleUserKeyCreate, loadingCreateUserKey, userKeyCreateErrorMessage } = useUserKeyHook();

  return (
    <>
      <div>
        <Row justify="space-between">
          <Col>
            <h2 className={cx([style.headline, 'm-b-40 headline'])}>{t('user:userKey:addKeyTitle')}</h2>
          </Col>
          <Col className="flex flex-align-center">
            <Button
              title={t('common:back')}
              type="secondaryNew"
              size="small"
              icon={<FontAwesomeIcon icon="arrow-left" />}
              onClick={navigateToList}
            />
          </Col>
        </Row>
        <Form {...formItemLayout} form={form} layout="vertical" initialValues={{}} onFinish={handleUserKeyCreate}>
          {userKeyState.userKeyCreateResponse === null && (
            <>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={8}>
                      {userKeyCreateErrorMessage && (
                        <Alert
                          message={t('common:error')}
                          description={userKeyCreateErrorMessage}
                          type="error"
                          className="m-b-24"
                          showIcon
                        />
                      )}
                      <Form.Item
                        label={t('user:userKey.keyName')}
                        labelCol={{ span: 24 }}
                        name="name"
                        rules={[{ required: true, message: t('common:required') }, { min: 5 }]}
                      >
                        <Input maxLength={500} data-test-id="key-name" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <span className="m-t-40">
                  <Button
                    key="add"
                    dataTestId="create-new-user-key"
                    size="middle"
                    type="primaryNew"
                    htmlType="submit"
                    disabled={loadingCreateUserKey}
                    // @ts-expect-error TS(2322): Type 'false | Element' is not assignable to type '...
                    icon={loadingCreateUserKey && <LoadingOutlined />}
                  >
                    {t('user:userKey.createNewUserKey')}
                  </Button>
                </span>
              </Row>
            </>
          )}
          {userKeyState.userKeyCreateResponse !== null && (
            <>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={16}>
                      <Alert data-test-id="api-key-value" message={userKeyState.userKeyCreateResponse.value} type="success" />
                    </Col>
                    <Col span={1}>
                      <Button
                        dataTestId="copy-api-key-btn"
                        type="secondaryNew"
                        size="middle"
                        icon={<FontAwesomeIcon icon="copy" className="p-l-8" />}
                        iconPosition="right"
                        onClick={() => copyToClipboard()}
                        className="m-l-8"
                      >
                        <span>{t('common:copy')}</span>
                      </Button>
                    </Col>
                  </Row>
                  <Row className="m-t-16">
                    <Col span={16}>
                      <Alert message={t('user:userKey.copyKey')} type="error" />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="m-t-16">
                <Button
                  key="add"
                  size="middle"
                  type="secondaryNew"
                  icon={<FontAwesomeIcon icon="arrow-left" />}
                  onClick={navigateToList}
                >
                  <span className="m-l-8">{t('common:back')}</span>
                </Button>
              </Row>
            </>
          )}
        </Form>
      </div>
    </>
  );
}

import { TFunction } from 'react-i18next';
import BucketDrawerFeedbackBody from 'src/components/pages/Main/Jobs/Feedback/Flow/BucketDrawer/BucketDrawerFeedbackBody';
import DecisionPointsBody from './components/DecisionPointsBody';
import AIExplainaibilityBody from './components/AIExplainaibilityBody';
import { EBucketDrawerTabs } from './BucketDrawer.constants';

export const getBucketDrawerTabs = (t: TFunction) => [
  {
    key: EBucketDrawerTabs.FEEDBACK,
    name: t('feedback:bucketExpand.feedback'),
    component: BucketDrawerFeedbackBody
  },
  {
    key: EBucketDrawerTabs.DECISION_BREAKDOWN,
    name: t('feedback:bucketExpand.decisionBreakdown'),
    component: DecisionPointsBody
  },
  {
    key: EBucketDrawerTabs.AI_EXPLAINABILITY,
    name: t('feedback:bucketExpand.similarity.tabTitle'),
    component: AIExplainaibilityBody
  }
];

import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useQuery } from 'react-query';
import { EventExplorerBatchResponse } from '../../components/shared/models/event-explorer/EventExplorerBatchResponse';
import { config } from '../../config';
import axios, { AxiosResponse } from 'axios';
import { EventExplorerBatchRequest } from '../../components/shared/models/event-explorer/EventExplorerBatchRequest';
import { EventExplorerFiltersState } from '../../components/pages/JobEventExplorer/models/EventExplorerFilters';
import {
  getIsFilterWithMultipleValue,
  getTransformedAdvancedFilters
} from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.utils';
import { TFilterItem } from '@arcanna/components';
import { useJobInfo } from '../job';
import { ARCANNA_RESULT_FIELD } from 'src/components/pages/JobEventExplorer/utils';
import { TFilterItemWithValue } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.types';

type UseEventExplorerBatch = EventExplorerFiltersState & {
  jobId: number;
};
export function useEventExplorerBatch({
  jobId,
  tablePageSize,
  tablePage,
  startDate,
  endDate,
  tableSort,
  advancedFilters
}: UseEventExplorerBatch) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const jobInfoQuery = useJobInfo(Number(jobId));

  const jobInfoCustomLabels = React.useMemo(
    () => jobInfoQuery.data?.info?.advancedSettings?.customLabels ?? [],
    [jobInfoQuery.data?.info?.advancedSettings?.customLabels]
  );

  return useQuery<EventExplorerBatchResponse, Error>(
    [
      config.api.eventExplorerBatch,
      {
        jobId,
        tablePageSize,
        tablePage: (tablePage - 1) * tablePageSize,
        startDate: startDate?.toISOString(),
        endDate: endDate ? endDate.toISOString() : null,
        tableSort,
        advancedFilters
      }
    ],
    () => {
      const parsedAdvancedFilters = getTransformedAdvancedFilters(advancedFilters as TFilterItem[]);

      const advancedFiltersToSend = parsedAdvancedFilters.map((filter) => {
        // for ARCANNA_RESULT_FIELD we should send the ID's of the custom labels of the job, hence this logic that is written below
        if (filter.field !== ARCANNA_RESULT_FIELD) {
          return filter;
        }

        const isMultipleFilter = getIsFilterWithMultipleValue(filter);

        if (isMultipleFilter) {
          return {
            ...filter,
            value: ((filter as TFilterItemWithValue).value as string[]).map((customLabelName) => {
              const foundLabel = jobInfoCustomLabels.find((customLabel) => customLabel.name === customLabelName);

              return foundLabel?.id ?? customLabelName;
            })
          };
        }

        const customLabelNameFromFilter = (filter as TFilterItemWithValue).value as string;
        const foundLabelInJobInfo = jobInfoCustomLabels.find((customLabel) => customLabel.name === customLabelNameFromFilter);

        return {
          ...filter,
          value: foundLabelInJobInfo?.id ?? customLabelNameFromFilter
        };
      });

      return axios
        .post<{
          resource: EventExplorerBatchResponse;
        }>(
          config.api.eventExplorerBatch,
          new EventExplorerBatchRequest(
            jobId,
            tablePageSize,
            (tablePage - 1) * tablePageSize,
            // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
            startDate?.toISOString(),
            endDate ? endDate.toISOString() : null,
            tableSort,
            advancedFiltersToSend
          )
        )
        .then((responseData: AxiosResponse<{ resource: EventExplorerBatchResponse }>) => {
          return jsonConvert.deserializeObject(responseData.data.resource, EventExplorerBatchResponse);
        });
    }
  );
}

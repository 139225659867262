import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFeedbackBulkContext } from '@arcanna/pages/Feedback/FeedbackBulkContext/useFeedbackBulkContext';
import CircledCheckmark from 'src/components/shared/icons/CircledCheckmark';
import { showInfoNotification } from 'src/components/shared/utilities/notification';

function ConfirmArcannaDecisionButton() {
  const { t } = useTranslation(['feedback']);
  const { canConfirmLabels, handleLabelsConfirm } = useFeedbackBulkContext();

  const handleConfirm = () => {
    handleLabelsConfirm();
    showInfoNotification(t('feedback:confirmSelected'), t('feedback:confirmSelectedInfo'));
  };

  return (
    <Button
      size="small"
      color="secondary"
      variant="outlined"
      disabled={!canConfirmLabels()}
      endIcon={<CircledCheckmark />}
      onClick={() => handleConfirm()}
      sx={{
        width: '250px'
      }}
    >
      {t('feedback:confirmDecisionsUnified')}
    </Button>
  );
}

export default ConfirmArcannaDecisionButton;

import { TFeedbackBulkContext } from './FeedbackBulkContext.type';
import { FeedbackBulkContext } from './FeedbackBulkContext';
import { useContext } from 'react';

export function useFeedbackBulkContext(): TFeedbackBulkContext {
  const context = useContext(FeedbackBulkContext);

  if (context === undefined) {
    throw new Error('useFeedbackBulkContext must be used within FeedbackBulkContextProvider');
  }

  return context;
}

import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FeedbackAdvancedFilterRecord')
export class FeedbackAdvancedFilterRecord {
  @JsonProperty('field', String)
  field?: string | undefined;

  @JsonProperty('operator', String)
  operator?: string | undefined;

  @JsonProperty('value', String)
  value?: string | undefined;

  constructor(field: string, operator: string, value: string) {
    this.field = field;
    this.operator = operator;
    this.value = value;
  }
}

import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * new_label - can be empty if class is used to prepare request for clear feedback
 *
 * */
@JsonObject('FeedbackUpdateRecord')
export class FeedbackUpdateRecord {
  @JsonProperty('entry_id', String)
  entry_id?: string;

  @JsonProperty('new_label', String)
  new_label?: string;

  constructor(entry_id: string, label?: string) {
    this.entry_id = entry_id;
    this.new_label = label;
  }
}

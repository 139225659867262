import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFeedbackBulkContext } from '@arcanna/pages/Feedback/FeedbackBulkContext/useFeedbackBulkContext';
import BackspaceIcon from 'src/components/shared/icons/BackspaceIcon';
import { showInfoNotification } from 'src/components/shared/utilities/notification';

function ClearMyFeedbackButton() {
  const { t } = useTranslation(['common', 'feedback']);
  const { canClearLabels, handleLabelsClear } = useFeedbackBulkContext();

  const handleClear = () => {
    handleLabelsClear();
    showInfoNotification(t('feedback:clearSelected'), t('feedback:clearSelectedInfo'));
  };

  return (
    <Button
      size="small"
      variant="tertiary"
      endIcon={<BackspaceIcon />}
      onClick={() => handleClear()}
      disabled={!canClearLabels()}
    >
      {t('feedback:clearMyFeedback')}
    </Button>
  );
}

export default ClearMyFeedbackButton;

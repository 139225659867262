import { Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../shared/components/Button/Button';
import { CustomLabel } from '@arcanna/components';
import PermissionsCheck from '../../../../../shared/components/Permissions/Permissions';
import { CustomLabel as ICustomLabel } from '../../../../../shared/models/job/AdvancedJobSettings';
import styles from './SelectFeedback.module.css';
import { Stack } from '@mui/material';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import { useMemo } from 'react';

type SelectFeedbackProps = {
  jobInfos: JobInfoResponse[];
  disabled: boolean;
  selectedLabel: string;
  onSelect: (id: string) => void;
};

export function getCustomLabelsFromJobs(jobInfos: JobInfoResponse[]) {
  const labelsMap = new Map<string, ICustomLabel>();
  jobInfos.forEach((jobInfo) => {
    jobInfo.info?.advancedSettings.customLabels.forEach((customLabel) => {
      labelsMap.set(customLabel.id, customLabel);
    });
  });
  return Array.from(labelsMap.values());
}

export default function SelectFeedback({ jobInfos, disabled, selectedLabel, onSelect }: SelectFeedbackProps) {
  const { t } = useTranslation(['feedback']);
  const customLabels = useMemo(() => getCustomLabelsFromJobs(jobInfos), [jobInfos]);

  if (customLabels.length === 2) {
    return (
      <Space data-test-id="select-feedback">
        <PermissionsCheck>
          <Button
            type="primary"
            size="small"
            disabled={disabled}
            style={{ backgroundColor: customLabels[0].hexColor }}
            dataTestId={`${customLabels[0]?.name}-feedback-button`}
            onClick={() => onSelect(customLabels[0].id)}
          >
            <span>{customLabels[0].name}</span>
          </Button>
        </PermissionsCheck>

        <PermissionsCheck>
          <Button
            type="primary"
            size="small"
            disabled={disabled}
            style={{ backgroundColor: customLabels[1].hexColor }}
            dataTestId={`${customLabels[1]?.name}-feedback-button`}
            onClick={() => onSelect(customLabels[1].id)}
          >
            <span>{customLabels[1].name}</span>
          </Button>
        </PermissionsCheck>
      </Space>
    );
  }

  return (
    <PermissionsCheck>
      <Select
        size="small"
        placement="topRight"
        className={styles.selectFeedback}
        onChange={(labelId) => onSelect(labelId)}
        placeholder={t('feedback:selectClass')}
        disabled={disabled}
        value={selectedLabel}
        data-test-id="select-feedback"
      >
        {customLabels.map((customLabel: ICustomLabel) => (
          <Select.Option key={customLabel.id} value={customLabel.id}>
            <Stack direction="row" alignItems="center" height="100%">
              <CustomLabel
                name={customLabel.name}
                dataTestId={`${customLabel?.name}-feedback-button`}
                hexColor={customLabel.hexColor}
              />
            </Stack>
          </Select.Option>
        ))}
      </Select>
    </PermissionsCheck>
  );
}

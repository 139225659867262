/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { TBucketDrawerContext } from './BucketDrawerContext.type';

export const BucketDrawerContext = createContext<TBucketDrawerContext>({
  filterFields: [],
  advancedFilters: {
    fieldOptions: [],
    operatorOptionsMap: {},
    addAdvancedFilter: () => {},
    activeFilters: [],
    removeAdvancedFilter: () => {},
    clearAllAdvancedFilters: () => {},
    getValueOptions: () => Promise.resolve([])
  },
  triggerBucketsReload: () => {},
  expandedBucketIndex: undefined,
  bucketRowsCount: 0,
  setExpandedBucketByIndex: () => {}
});

import { FeedbackBatchBucketRow } from 'src/components/shared/models/feedback/FeedbackBatchBucketRow';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import LabelColumn from './components/LabelColumn';
import { TFunction } from 'react-i18next';
import { Table } from '@arcanna/generic';
import { TFilterItem } from '@arcanna/components';
import { TFeedbackTableRowData } from './FeedbackTable.types';
import { createColumnHelper } from '@tanstack/react-table';
import TrainingStatus from './components/TrainingStatus';
import { formatDateTime } from 'src/components/shared/utilities/date';
import DecisionPointsColumn from './components/DecisionPointsColumn';
import CustomLabelByJob from './models/CustomLabelByJob';

export const getColumnValue = (row: FeedbackBatchBucketRow, columnName: string): string | null => {
  if (!columnName) {
    return null;
  }

  const column = row.columns?.find((columnItem) => columnItem.name === columnName);

  return column?.value ?? null;
};

export const getColumnKeyBasedOnFilters = (
  tableColumnName: string | undefined,
  filters: FeedbackFiltersFieldsRecord[]
): string => {
  const columnName = Table.extractColumnNameFromTableColumnId(tableColumnName ?? '');

  if (columnName === 'timestamp' || !columnName) {
    return 'timestamp';
  }

  const associatedFilter = filters.find((filter) => filter.name === columnName || filter.source === columnName);

  return associatedFilter?.source ?? 'timestamp';
};

export const getColumns = ({
  t,
  extraBaseColumnNames,
  batchBucketDynamicColumns,
  jobInfoCustomLabels,
  activeFilters,
  addAdvancedFilter,
  getFilterSourceByName,
  activeTableSize
}: {
  t: TFunction;
  extraBaseColumnNames: string[];
  batchBucketDynamicColumns: string[];
  jobInfoCustomLabels: CustomLabelByJob[];
  activeFilters: TFilterItem[];
  addAdvancedFilter: (filterItem: TFilterItem) => void;
  getFilterSourceByName: (name: string) => string | undefined;
  activeTableSize: string;
}) => {
  const columnHelper = createColumnHelper<TFeedbackTableRowData>();
  const isMinimized = activeTableSize === t('feedback:minimized');

  let baseColumns = [
    columnHelper.accessor('result', {
      id: 'result',
      header: String(t('job:jobDashboardFlow.nnLabel')),
      cell: ({ cell, tableSize }) => (
        <LabelColumn
          cell={cell}
          isDisplayedOnTwoRows={tableSize === Table.TABLE_SIZES.large || isMinimized}
          jobInfoCustomLabels={jobInfoCustomLabels}
        />
      ),
      minSize: 80,
      size: 165,
      enableHiding: false
    }),
    columnHelper.accessor('trainingStatus', {
      id: 'training_status',
      header: String(t('trainingStatus')),
      cell: ({ row }) => <TrainingStatus rowData={row.original} jobInfoCustomLabels={jobInfoCustomLabels} />,
      enableHiding: false,
      minSize: 80,
      size: 80
    })
  ];

  let extraBaseColumns = extraBaseColumnNames.map((columnName: string) =>
    columnHelper.accessor(columnName, {
      id: columnName,
      header: String(t('job:aiJob')),
      minSize: 150,
      cell: ({ cell }) => cell.getValue(),
      enableHiding: false
    })
  );

  if (extraBaseColumnNames.length == 0) {
    extraBaseColumns = [
      columnHelper.accessor('timestamp', {
        id: 'timestamp',
        header: String(t('timestamp')),
        minSize: 125,
        size: 125,
        cell: ({ cell }) => formatDateTime(cell.getValue()),
        enableHiding: false
      })
    ];
  }

  baseColumns = baseColumns.concat(extraBaseColumns);

  if (isMinimized) {
    return [
      ...baseColumns,
      columnHelper.accessor('decisionPoints', {
        id: 'decisionPoints',
        header: String(t('decisionPoints')),
        minSize: 800,
        cell: ({ row }) => (
          <DecisionPointsColumn
            row={row}
            batchBucketDynamicColumns={batchBucketDynamicColumns}
            addAdvancedFilter={addAdvancedFilter}
            activeFilters={activeFilters}
            getFilterSourceByName={getFilterSourceByName}
          />
        ),
        enableHiding: false,
        enableSorting: false
      })
    ];
  }

  // dynamic columns
  return [
    ...baseColumns,
    ...(batchBucketDynamicColumns.map((dynamicColumn) =>
      columnHelper.accessor(Table.computeTableColumnIdFromColumnName(dynamicColumn), {
        id: Table.computeTableColumnIdFromColumnName(dynamicColumn),
        header: dynamicColumn,
        cell: ({ cell, tableSize }) => (
          <Table.GeneralCellWithFilters
            tableSize={tableSize}
            filterSource={getFilterSourceByName(dynamicColumn)}
            columnName={dynamicColumn}
            value={cell.row.original[dynamicColumn]}
            addAdvancedFilter={addAdvancedFilter}
            activeFilters={activeFilters}
          />
        ),
        size: 240
      })
    ) ?? [])
  ];
};

export const getTableRowData = (batchBucketRows: FeedbackBatchBucketRow[]): TFeedbackTableRowData[] =>
  batchBucketRows.map((row) => {
    return row.columns?.reduce(
      (acc, currentColumn) => {
        if (!currentColumn.name) {
          return acc;
        }

        return {
          ...acc,
          [`${currentColumn.name}`]: currentColumn.value
        };
      },
      {
        id: row.row_id,
        consensusOverwritten: row.consensusOverwritten,
        enabled: row.enabled
      }
    ) as TFeedbackTableRowData;
  });

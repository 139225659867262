import { Stack } from '@mui/material';
import { TrainingNotification } from './TrainingNotification';
import { BucketsModelUpdateNotification } from './BucketsModelUpdateNotification';
import { BucketsUpdateNotification } from './BucketsUpdateNotification';
import { JobCategory } from '../../helper';
import { JobNotificationsProvider } from './JobNotificationsProvider';
import RollbackInProgressNotification from 'src/components/shared/components/RollbackInProgressNotification';
import AutomaticRetrainNotification from 'src/components/shared/components/AutomaticRetrainNotification/AutomaticRetrainNotification';
import JobInvalidNotification from './JobInvalidNotification';
import JobStartNotification from './JobStartNotification/JobStartNotification';
import { StartNotificationType } from './hooks/useEstablishStartNotificationType';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { useJobInfo } from 'src/data-access';

type TJobNotificationsProps = {
  fromPage?: string;
  reload?: () => void;
  startNotificationType?: StartNotificationType;
  className?: string;
};

export default function JobNotifications({
  reload = () => {
    return;
  },
  fromPage,
  startNotificationType,
  className
}: TJobNotificationsProps) {
  const { jobId } = useJobIdFromUrl();
  const jobInfoQuery = useJobInfo(jobId);
  const jobCategory = jobInfoQuery.data?.info?.jobDetails?.category_id;
  const isDIJob = jobCategory === JobCategory.DECISION_INTELLIGENCE;

  return (
    <Stack gap={1} className={className}>
      <JobStartNotification jobId={jobId} fromPage={fromPage} notificationType={startNotificationType} />
      <JobNotificationsProvider>
        <TrainingNotification jobId={jobId} jobCategory={jobCategory} />
        {isDIJob && <BucketsModelUpdateNotification jobId={jobId} reload={reload} />}
        {isDIJob && <BucketsUpdateNotification jobId={jobId} fromPage={fromPage} reload={reload} />}
      </JobNotificationsProvider>
      <JobInvalidNotification jobId={jobId} />
      {isDIJob && <RollbackInProgressNotification jobId={jobId} />}
      <AutomaticRetrainNotification jobId={jobId} />
    </Stack>
  );
}

import { SVGProps } from 'react';

export type BackspaceIconProps = SVGProps<SVGSVGElement>;

export default function BackspaceIcon({ ...props }: BackspaceIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.3333 6.00033L7.33333 10.0003M7.33333 6.00033L11.3333 10.0003M1.81333 8.64033L4.69333 12.4803C4.928
      12.7932 5.04533 12.9497 5.19403 13.0625C5.32574 13.1624 5.47488 13.237 5.63385 13.2824C5.81334 13.3337 6.00889
       13.3337 6.4 13.3337H11.4667C12.5868 13.3337 13.1468 13.3337 13.5746 13.1157C13.951 12.9239 14.2569 12.618
        14.4487 12.2416C14.6667 11.8138 14.6667 11.2538 14.6667 10.1337V5.86699C14.6667 4.74689 14.6667 4.18683
        14.4487 3.75901C14.2569 3.38269 13.951 3.07673 13.5746 2.88498C13.1468 2.66699 12.5868 2.66699 11.4667
        2.66699H6.4C6.00889 2.66699 5.81334 2.66699 5.63385 2.71825C5.47488 2.76365 5.32574 2.83822 5.19403
         2.93816C5.04533 3.05099 4.928 3.20744 4.69333 3.52033L1.81333 7.36033C1.6412 7.58983 1.55514 7.70458
          1.52196 7.83061C1.49268 7.94186 1.49268 8.05879 1.52196 8.17004C1.55514 8.29607 1.6412 8.41082 1.81333
          8.64033Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { JsonObject, JsonProperty } from 'json2typescript';
import { FeedbackBatchRequest } from './FeedbackBatchRequest';
import type { BatchTableSort } from '../event-explorer/EventExplorerBatchRequest';
import { FeedbackQuickFilters } from './FeedbackQuickFilters';

@JsonObject('FeedbackBatchUnifiedRequest')
export class FeedbackBatchUnifiedRequest extends FeedbackBatchRequest {
  @JsonProperty('job_ids', [Number])
  job_ids?: number[] = [];

  @JsonProperty('include_all_jobs', Boolean)
  include_all_jobs = false;

  constructor(
    job_ids: number[],
    include_all_jobs = false,
    size = 10,
    from = 0,
    start_date: string | undefined = undefined,
    end_date: string | undefined = undefined,
    exclude_processed = false,
    include_processed = false,
    tableSort: BatchTableSort = { column: 'timestamp', order: 'desc' },
    // eslint-disable-next-line
    filters: any = [],
    quick_filters: FeedbackQuickFilters = {},
    decision_filters: string[] = [],
    consensus_filters: string[] = []
  ) {
    super(
      0,
      size,
      from,
      start_date,
      end_date,
      exclude_processed,
      include_processed,
      tableSort,
      filters,
      quick_filters,
      decision_filters,
      consensus_filters
    );
    this.job_ids = job_ids;
    this.include_all_jobs = include_all_jobs;
  }
}

import { Card, CardProps, styled } from '@mui/material';

const StyledConsensusMetrics = styled(Card)<CardProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary[900],
  border: `1px solid ${theme.palette.secondary[800]}`,
  height: '272px',
  width: '250px',

  '.MuiCardContent-root': {
    padding: '16px'
  },

  '.MuiTypography-body1': {
    fontWeight: 600
  },

  '.MuiTypography-subtitle2': {
    color: theme.palette.secondary[400]
  },

  '.MuiTypography-subtitle1': {
    color: theme.palette.secondary[400],
    fontWeight: 400,
    overflowWrap: 'break-word'
  },

  '.MuiTypography-subtitle1 b': {
    color: theme.palette.secondary[100],
    fontWeight: 500
  },

  '.toggleOverwriteConsensus:hover': {
    backgroundColor: 'inherit'
  }
}));

export default StyledConsensusMetrics;

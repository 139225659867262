import FeedbackBulkContextProvider from '@arcanna/pages/Feedback/FeedbackBulkContext/FeedbackBulkContextProvider';
import { Stack } from '@mui/material';
import FeedbackFlowFooter from '../FeedbackFlowFooter';
import { useCallback } from 'react';
import { useFeedbackContext } from '@arcanna/pages/Feedback/FeedbackContext/useFeedbackContext';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { useLabelContext } from '@arcanna/pages/Feedback/FeedbackContext/useLabelContext';

function FeedbackTableFooter({
  onResetRow,
  selectedBuckets,
  jobInfos
}: {
  onResetRow: () => void;
  selectedBuckets: string[];
  jobInfos: JobInfoResponse[];
}) {
  const { jobId } = useJobIdFromUrl();

  const { editLabelOnSelected, setEditLabelOnSelected, prepareConfirm, setPrepareConfirm, prepareClear, setPrepareClear } =
    useLabelContext((context) => context);

  const tableSlice = useFeedbackContext((context) => context.tableSlice);

  const handleCancelBulkFeedback = useCallback(() => {
    onResetRow();
    setEditLabelOnSelected(null);
  }, [onResetRow, setEditLabelOnSelected]);

  return (
    <Stack sx={{ height: '80px' }}>
      <FeedbackBulkContextProvider
        jobId={jobId}
        selectedLabel={editLabelOnSelected}
        prepareConfirm={prepareConfirm}
        prepareClear={prepareClear}
        feedbackBulkContextValue={{
          totalBucketsCount: tableSlice.totalRecordsCount,
          selectedBuckets: selectedBuckets,
          bucketsRows: tableSlice.batchBucketRows,
          setEditLabelOnSelected: setEditLabelOnSelected,
          handleCancelBulkFeedback,
          triggerBucketsReload: tableSlice.triggerBucketsReload,
          setPrepareConfirm: setPrepareConfirm,
          setPrepareClear: setPrepareClear
        }}
      >
        <FeedbackFlowFooter
          enabled={selectedBuckets.length > 0}
          jobInfos={jobInfos}
          prepareConfirm={prepareConfirm}
          prepareClear={prepareClear}
        />
      </FeedbackBulkContextProvider>
    </Stack>
  );
}

export default FeedbackTableFooter;

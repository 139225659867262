import { Stack } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { TFeedbackTableRowData } from '../../FeedbackTable.types';
import { TFilterItem } from '@arcanna/components';
import DecisionPointsColumnItem from './components/DecisionPointsColumnItem';

type TDecisionPointsColumnProps = {
  row: Row<TFeedbackTableRowData>;
  batchBucketDynamicColumns: string[];
  activeFilters: TFilterItem[];
  addAdvancedFilter: (filterItem: TFilterItem) => void;
  getFilterSourceByName: (name: string) => string | undefined;
};

function DecisionPointsColumn({
  row,
  batchBucketDynamicColumns,
  activeFilters,
  addAdvancedFilter,
  getFilterSourceByName
}: TDecisionPointsColumnProps) {
  return (
    <Stack direction="row" flexWrap="wrap" gap={1} paddingY={1}>
      {batchBucketDynamicColumns.map((columnName, index) => (
        <DecisionPointsColumnItem
          key={index}
          value={row.original[columnName]}
          columnName={columnName}
          activeFilters={activeFilters}
          addAdvancedFilter={addAdvancedFilter}
          filterSource={getFilterSourceByName(columnName)}
        />
      ))}
    </Stack>
  );
}

export default DecisionPointsColumn;

import axios, { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { config } from '../../config';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { FeedbackComputeMetricsRequest } from '../../components/shared/models/feedback/FeedbackComputeMetricsRequest';

type Response = AxiosResponse<{ resource: { request: CommonResponse } }>;

export function useFeedbackComputeMetrics() {
  return useMutation<Response, Response, FeedbackComputeMetricsRequest>((payload: FeedbackComputeMetricsRequest) =>
    axios.post<{ resource: { request: CommonResponse } }>(config.api.feedbackStatisticsComputeMetrics, payload)
  );
}

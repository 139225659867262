import { FormControlLabel, FormControlLabelProps, Checkbox as MuiCheckbox, Typography } from '@mui/material';
import { CheckboxUncheckedIcon, CheckboxCheckedIcon, CheckboxIndeterminateIcon } from './CheckboxIcons';
import React, { useMemo, MouseEventHandler, ReactNode } from 'react';
import CheckboxGroup from './CheckboxGroup';

export type TCheckboxState = 'checked' | 'indeterminate' | 'default';

type TCheckboxProps = {
  label?: ReactNode;
  onChange?: (state: TCheckboxState, event: React.ChangeEvent<HTMLInputElement>) => void;
  state: TCheckboxState;
  disabled?: boolean;
  value?: string;
  textColor?: string;
  activeColor?: string;
  onClick?: MouseEventHandler<HTMLLabelElement>;
} & Omit<FormControlLabelProps, 'control' | 'onClick' | 'label' | 'onChange'>;

function Checkbox({ label, onChange, state, disabled, onClick, value, textColor, activeColor, ...otherProps }: TCheckboxProps) {
  const labelColor = useMemo(() => {
    if (!activeColor) {
      return textColor;
    }
    if (state === 'checked') {
      return activeColor;
    }
    return textColor;
  }, [activeColor, textColor, state]);

  return (
    <FormControlLabel
      {...otherProps}
      sx={{
        marginLeft: 0,
        opacity: disabled ? 0.4 : 1,
        '&.Mui-disabled': {
          cursor: 'not-allowed'
        },
        ...(otherProps.sx ?? {})
      }}
      onClick={onClick}
      control={
        <MuiCheckbox
          size="small"
          checked={state === 'checked'}
          indeterminate={state === 'indeterminate'}
          disabled={disabled}
          name={'checkbox'}
          checkedIcon={<CheckboxCheckedIcon />}
          icon={<CheckboxUncheckedIcon />}
          indeterminateIcon={<CheckboxIndeterminateIcon />}
          value={value}
          sx={{ padding: 0, marginRight: label ? 1 : 0 }}
          onChange={(event) => {
            if (!onChange) {
              return;
            }

            if (state === 'checked' || state === 'indeterminate') {
              return onChange('default', event);
            }

            return onChange('checked', event);
          }}
        />
      }
      label={
        label && (
          <Typography variant="subtitle1" color={labelColor} lineHeight={1}>
            {label}
          </Typography>
        )
      }
    />
  );
}

Checkbox.Group = CheckboxGroup;
export default Checkbox;

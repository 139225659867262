import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { FeedbackUpdateRequest } from '../../components/shared/models/feedback/FeedbackUpdateRequest';
import axios from 'axios';
import { config } from '../../config';
import { FeedbackUpdateResponse } from '../../components/shared/models/feedback/FeedbackUpdateResponse';
import { REQUEST_STATUS_NOK } from '../../components/shared/hooks/useFetch';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { FeedbackUpdateRecord } from 'src/components/shared/models/feedback/FeedbackUpdateRecord';

export default function useClearFeedback() {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['feedback', 'common']);
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  return useMutation(
    (payload: FeedbackUpdateRequest) => axios.post(config.api.clearFeedback, payload),
    // BEGIN-NOSCAN
    {
      onSuccess: (response) => {
        const responseData = jsonConvert.deserializeObject(response.data.resource, FeedbackUpdateResponse);
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        if (responseData.request.status === REQUEST_STATUS_NOK) {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          showErrorNotification(t('common:error'), responseData.request.reason);
          return;
        }
        showSuccessNotification(t('common:actionCompleted'), t('feedback:clearFeedbackSuccess'));
      },
      onError: () => {
        showErrorNotification(t('common:error'), t('common:somethingWentWrong'));
      },
      onSettled: (data, error, variables) => {
        variables.updates?.forEach((update: FeedbackUpdateRecord) => {
          if (update.entry_id != null) {
            queryClient.invalidateQueries([config.api.feedbackBucketDetails.replace(':bucketId', update.entry_id)]);
          }
        });
        queryClient.invalidateQueries([config.api.feedbackBucketsBatch]);
      }
    }
  );
  // END-NOSCAN
}

import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification
} from '../../components/shared/utilities/notification';
import { ErrorResponse } from '../../components/shared/models/error/ErrorResponse';
import { JobRetrainMultipleRequest } from 'src/components/shared/models/retrain-statistics/JobRetrainMultipleRequest';
import { JobRetrainMultipleResponse } from 'src/components/shared/models/retrain-statistics/JobRetrainMultipleResponse';

export function useRetrainMultipleJobs() {
  const { t } = useTranslation(['common', 'job', 'retrain']);
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const queryClient = useQueryClient();

  return useMutation(
    // @ts-expect-error TS(2769): No overload matches this call.
    (jobIds: number[]) =>
      axios.post<{ resource: { request: JobRetrainMultipleRequest } }>(
        config.api.jobRetrainMultiple,
        new JobRetrainMultipleRequest(jobIds, false)
      ),
    {
      onSuccess: (responseData: AxiosResponse<{ resource: { request: JobRetrainMultipleResponse } }>, jobIds) => {
        if (!responseData.data) {
          return;
        }
        if (!responseData.data.resource) {
          showErrorNotification(t('common:actionCompleted'), t('job:retrainError'));
          return;
        }

        const jobRetrainResponse = jsonConvert.deserializeObject(responseData.data.resource, JobRetrainMultipleResponse);

        if (jobRetrainResponse.request?.status === 'OK') {
          const failedJobIds = jobRetrainResponse.failed_job_ids;
          jobIds.forEach((jobId: number) => {
            if (!failedJobIds.includes(jobId.toString())) {
              queryClient.invalidateQueries([config.api.feedbackStatisticsEventsBreakdown]);
              queryClient.invalidateQueries([config.api.jobInfo.replace(':id', `${jobId}`)]);
              queryClient.invalidateQueries([config.api.feedbackStatisticsConsensus]);
              queryClient.invalidateQueries([config.api.feedbackStatisticsUsers]);
              queryClient.invalidateQueries([config.api.isJobRetrainAvailable, { jobId }]);
            }
          });
          if (failedJobIds.length > 0) {
            showWarningNotification(
              jobRetrainResponse.details || t('common:actionPartiallyCompleted'),
              t('retrain:notAllJobsRetrained'),
              t('common:failed') + ': ' + jobRetrainResponse.failed_job_titles.join()
            );
          } else {
            showSuccessNotification(t('common:actionCompleted'), t('job:retrainSuccessfully'));
          }
        } else {
          showErrorNotification(t('common:actionCompleted'), t('job:retrainError'), jobRetrainResponse.request?.reason);
        }
      },
      onError: (error: { data: ErrorResponse }) => {
        showErrorNotification(t('common:error'), t('common:retrainError') + error.data.resource.request.reason);
      }
    }
  );
}

import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { FatigueRiskRequest } from '../../../components/shared/models/impact-dashboard/FatigueRiskRequest';
import { FatigueRiskResponse } from '../../../components/shared/models/impact-dashboard/FatigueRiskResponse';
import { getJsonConvert } from '../../../components/shared/utilities/json-convert';
import { config } from '../../../config';

type UseFatigueRisk = {
  startDate: Date;
  endDate: Date;
  jobIds: number[];
};
export function useFatigueRisk({ startDate, endDate, jobIds }: UseFatigueRisk) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const isoStartDate = startDate.toISOString();
  const isoEndDate = endDate.toISOString();

  return useQuery(
    [config.api.jobStatisticsFatigueRisk, { startDate: isoStartDate, endDate: isoEndDate, jobIds }],
    () =>
      axios
        .post<{
          resource: FatigueRiskResponse;
        }>(config.api.jobStatisticsFatigueRisk, new FatigueRiskRequest(jobIds, isoStartDate, isoEndDate))
        .then((responseData: AxiosResponse<{ resource: FatigueRiskResponse }>) => {
          return jsonConvert.deserializeObject(responseData.data.resource, FatigueRiskResponse);
        }),

    {
      enabled: Boolean(jobIds.length > 0 && startDate && endDate)
    }
  );
}

import { AdvancedFilters, RefreshButton, TimeSelector } from '@arcanna/components';
import { Stack } from '@mui/material';
import FeedbackFlowQuickFilters from 'src/components/pages/Main/Jobs/Feedback/Flow/FeedbackFlowQuickFilters';
import FeedbackFlowConsensusDecisionFilters from 'src/components/pages/Main/Jobs/Feedback/Flow/FeedbackFlowConsensusDecisionFilters';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { FeedbackTimeSelectorOption } from 'src/components/pages/Main/Jobs/Feedback/Flow/FeedbackFlow.hook';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import { getTimeOptions } from '@arcanna/pages/Feedback/FeedbackHeader/FeedbackHeader.utils';
import { useFeedbackUnifiedContext } from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedProvider/FeedbackUnifiedProvider';
import useFeedbackUnified from '@arcanna/pages/FeedbackUnified/hooks/useFeedbackUnified';
import RetrainButtonUnified from '@arcanna/pages/FeedbackUnified/RetrainButtonUnified';
import JobComboBox from '../JobComboBox/JobComboBox';
import { useJobNavigate } from 'src/components/shared/hooks/useJobNavigate';

export type TFeedbackUnifiedHeaderProps = {
  jobIds: number[];
  jobInfos: JobInfoResponse[];
};

function FeedbackUnifiedHeader({ jobIds, jobInfos }: TFeedbackUnifiedHeaderProps) {
  const { t } = useTranslation();

  const feedbackUnifiedState = useFeedbackUnifiedContext((context) => context.state);
  const advancedFilters = useFeedbackUnifiedContext((context) => context.advancedFilters);
  const additionalFilters = useFeedbackUnifiedContext((context) => context.additionalFilters);
  const clearAdditionalFilters = useFeedbackUnifiedContext((context) => context.clearAdditionalFilters);
  const setQuickFilters = useFeedbackUnifiedContext((context) => context.setQuickFilters);
  const setDecisionFilters = useFeedbackUnifiedContext((context) => context.setDecisionFilters);
  const setConsensusFilters = useFeedbackUnifiedContext((context) => context.setConsensusFilters);
  const handleFeedbackTimeSelection = useFeedbackUnifiedContext((context) => context.filtersSlice.handleFeedbackTimeSelection);
  const { reloadTable } = useFeedbackUnified();
  const { navigateToJobFeedbackUnified } = useJobNavigate();

  const [feedbackTimeSelectorOption, setFeedbackTimeSelectorOption] = useState<FeedbackTimeSelectorOption>(
    FeedbackTimeSelectorOption.ALL_TIME
  );
  const timeSelectorOptions = useMemo(() => getTimeOptions(t), [t]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
          <TimeSelector
            options={timeSelectorOptions}
            currentValue={feedbackTimeSelectorOption}
            handleChange={(event) => {
              const newValue = event.target.value as FeedbackTimeSelectorOption;

              setFeedbackTimeSelectorOption(newValue);
              handleFeedbackTimeSelection(newValue);
            }}
            width={190}
          />
          <JobComboBox initialJobIds={jobIds} onSelectionChange={navigateToJobFeedbackUnified} />
          <FeedbackFlowQuickFilters quickFiltersState={feedbackUnifiedState.quickFilters} setQuickFilters={setQuickFilters} />
          <FeedbackFlowConsensusDecisionFilters
            jobInfos={jobInfos}
            decisionFilters={feedbackUnifiedState.decisionFilters}
            consensusFilters={feedbackUnifiedState.consensusFilters}
            setDecisionFilters={setDecisionFilters}
            setConsensusFilters={setConsensusFilters}
          />
          <AdvancedFilters.Button
            fieldOptions={advancedFilters.fieldOptions}
            operatorOptionsMap={advancedFilters.operatorOptionsMap}
            addAdvancedFilter={advancedFilters.addAdvancedFilter}
            getValueOptions={advancedFilters.getValueOptions}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Stack flexGrow={1} direction="row" justifyContent="flex-end">
            <RetrainButtonUnified jobInfos={feedbackUnifiedState.jobInfos} />
          </Stack>
          <RefreshButton onRefresh={() => reloadTable()} />
        </Stack>
      </Stack>
      <AdvancedFilters.List
        filters={advancedFilters.activeFilters}
        fieldOptions={advancedFilters.fieldOptions}
        operatorOptionsMap={advancedFilters.operatorOptionsMap}
        onDelete={advancedFilters.removeAdvancedFilter}
        onUpdate={advancedFilters.addAdvancedFilter}
        onClearAll={() => {
          advancedFilters.clearAllAdvancedFilters();
          clearAdditionalFilters();
        }}
        getValueOptions={advancedFilters.getValueOptions}
        additionalFilters={additionalFilters}
      />
    </>
  );
}

export default FeedbackUnifiedHeader;

import * as React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { useFeedbackBucketDetails, useFeedbackBucketOverwrite } from '../../../../../../../../data-access';
import { useConsensusPercent } from '../../../../../../../../components/pages/Main/Jobs/Feedback/Flow/hooks/ConsensusPercent.hooks';
import { useTranslation } from 'react-i18next';
import { useJobInfo } from '../../../../../../../../data-access';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatDateTime } from '../../../../../../../../components/shared/utilities/date';
import PermissionsCheck from '../../../../../../../../components/shared/components/Permissions/Permissions';
import { permissions } from '../../../../../../../../components/shared/static/ComponentsPermissions';
import { EIcon, Icon, Spinner } from '@arcanna/generic';
import { CustomLabel } from '@arcanna/components';
import { FeedbackOverwriteRequest } from '../../../../../../../../components/shared/models/feedback/FeedbackOverwriteRequest';
import { Button, CardContent, Stack, Typography, useTheme, Box, Autocomplete, TextField, Tooltip } from '@mui/material';
import StyledConsensusMetrics from './StyledConsensusMetrics.styles';
import addEllipsisOnLongText from 'src/components/shared/utilities/addEllipsisOnLongText';
import FeedbackHistory from './components/FeedbackHistory';

const PIE_WIDTH = 88;
const PIE_HEIGHT = 88;
const PIE_LINE_THICKNESS = 8;
const ELLIPSIS_MAX_LENGTH = 36;

type FeedbackFlowBucketConsensusMetricsProps = {
  jobId: number;
  bucketId: string;
};

function ConsensusMetrics({ jobId, bucketId }: FeedbackFlowBucketConsensusMetricsProps) {
  const theme = useTheme();
  const jobInfoQuery = useJobInfo(jobId);

  const { t } = useTranslation(['common', 'feedback']);
  const jobCustomLabels = jobInfoQuery.data?.info?.advancedSettings.customLabels ?? [];
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
  const [selectedLabelId, setSelectedLabelId] = React.useState<string>(null);
  const [consensusOverwrittenFlag, setConsensusOverwrittenFlag] = React.useState<boolean>(false);
  const saveFeedbackOverwriteMutation = useFeedbackBucketOverwrite();
  const feedbackBucketDetailsQuery = useFeedbackBucketDetails(jobId, bucketId);
  const [overwriteConsensusOptions, setOverwriteConsensusOptions] = useState(false);
  const { percent } = useConsensusPercent(jobId, bucketId);

  const handleSave = () => {
    const payload = new FeedbackOverwriteRequest(jobId, bucketId, selectedLabelId, false);
    saveFeedbackOverwriteMutation.mutateAsync(payload).then(() => {
      // Reset the selected label id to hide the save, cancel Button.
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
      setSelectedLabelId(null);
      setOverwriteConsensusOptions(false);
      setConsensusOverwrittenFlag(true);
    });
  };

  const handleCancel = useCallback(() => {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
    setSelectedLabelId(null);
    setOverwriteConsensusOptions(false);
  }, []);

  const handleRevert = () => {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
    const payload = new FeedbackOverwriteRequest(jobId, bucketId, null, true);
    saveFeedbackOverwriteMutation.mutate(payload);
    setOverwriteConsensusOptions(false);
  };

  const consensusOverwritten = useMemo(
    () => feedbackBucketDetailsQuery.data?.bucket.consensusOverwritten,
    [feedbackBucketDetailsQuery.data]
  );

  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'boolean | undefined' is not assi...
    setConsensusOverwrittenFlag(feedbackBucketDetailsQuery.data?.bucket.consensusOverwritten?.consensusOverwritten);
  }, [feedbackBucketDetailsQuery.data]);

  const consensusPieData = useMemo(() => {
    if (
      jobInfoQuery.data &&
      feedbackBucketDetailsQuery.data &&
      percent !== 0 &&
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      feedbackBucketDetailsQuery.data.bucket.userLabels.entries.length > 0
    ) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      return feedbackBucketDetailsQuery.data.bucket.userLabels.entries.reduce((result, entry) => {
        // eslint-disable-next-line
        const hasLabel = result.find((item) => (item as any).code === entry.label);
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        const customLabel = jobInfoQuery.data.info.advancedSettings.customLabels.find((l) => l.id === entry.label);

        return hasLabel
          ? result
          : [
              ...result,
              ...(customLabel
                ? [
                    {
                      color: customLabel.hexColor,
                      code: customLabel.id,
                      value: customLabel.id === feedbackBucketDetailsQuery.data.bucket.consensus ? percent : 100 - percent
                    }
                  ]
                : [])
            ];
      }, []);
    } else {
      return [];
    }
  }, [feedbackBucketDetailsQuery.data, percent, jobInfoQuery.data]);

  const consensusCustomLabel = useMemo(() => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    return jobInfoQuery.data?.info.advancedSettings.customLabels.find(
      (customLabel) => customLabel.id === feedbackBucketDetailsQuery.data?.bucket.consensus
    );
  }, [jobInfoQuery.data, feedbackBucketDetailsQuery.data]);

  const labelValue = useMemo(
    () => consensusCustomLabel?.name ?? t('feedback:bucketExpand.undecided'),
    [consensusCustomLabel?.name, t]
  );

  return (
    <Stack direction="row" gap="16px">
      <StyledConsensusMetrics>
        <CardContent>
          {feedbackBucketDetailsQuery.isLoading ? (
            <Spinner isOverlay />
          ) : (
            <Stack direction="column">
              <Typography variant="subtitle1">{t('feedback:bucketExpand.consensus')}</Typography>
              <Stack direction="column">
                <Stack direction="row" justifyContent="space-between" marginTop="8px">
                  <Tooltip title={labelValue.length >= ELLIPSIS_MAX_LENGTH ? labelValue : ''} placement="top" arrow>
                    <Typography>{addEllipsisOnLongText(labelValue, ELLIPSIS_MAX_LENGTH, 'middle')}</Typography>
                  </Tooltip>
                  <PermissionsCheck permissions={[permissions.feedbackOverwriteUpdate]} action={permissions.action.hiddenAction}>
                    {/* @ts-expect-error TS(2322): Type 'false | Element' is not assignable to type '... */}
                    {!overwriteConsensusOptions && !consensusOverwrittenFlag && (
                      <Button
                        className="toggleOverwriteConsensus"
                        endIcon={<Icon name={EIcon.Edit16} style={{ fontSize: '14px', color: theme.palette.secondary[400] }} />}
                        onClick={() => setOverwriteConsensusOptions(true)}
                      ></Button>
                    )}
                  </PermissionsCheck>
                </Stack>
                {!overwriteConsensusOptions && !consensusOverwrittenFlag && (
                  <Stack direction="column">
                    <Typography variant="subtitle1" padding="16px 0 24px 0">
                      <b>{Math.round(percent * 100) / 100}%</b> consensus
                    </Typography>
                    <PieChart width={PIE_WIDTH} height={PIE_HEIGHT}>
                      <Pie
                        // @ts-expect-error
                        data={consensusPieData}
                        innerRadius={PIE_HEIGHT / 2 - PIE_LINE_THICKNESS}
                        outerRadius={PIE_HEIGHT / 2}
                        fill={theme.palette.common.white}
                        paddingAngle={5}
                        dataKey="value"
                        startAngle={90}
                        endAngle={450}
                        stroke="none"
                        cornerRadius={0}
                      >
                        {/* eslint-disable-next-line */}
                        {(consensusPieData as any).map((data: { code: string; color: string }) => (
                          <Cell key={`job-retrain-breakdown-cell-${data.code}`} fill={data.color} />
                        ))}
                      </Pie>
                    </PieChart>
                  </Stack>
                )}
                {overwriteConsensusOptions && (
                  <Stack direction="column" paddingTop="16px">
                    <Autocomplete
                      options={jobCustomLabels.map((customLabel) => ({
                        label: customLabel.name,
                        value: customLabel.id,
                        hexColor: customLabel.hexColor
                      }))}
                      size="small"
                      fullWidth
                      autoHighlight
                      onChange={(_, option) => {
                        // @ts-expect-error TS(2531): Object is possibly 'null'.
                        setSelectedLabelId(option.value);
                      }}
                      renderOption={(props, option) => (
                        <Box padding="16px" component="li" {...props}>
                          <CustomLabel name={option.label} hexColor={option.hexColor} />
                        </Box>
                      )}
                      className={'MuiAutocomplete-without-label'}
                      renderInput={(params) => <TextField {...params} />}
                      readOnly={saveFeedbackOverwriteMutation.isLoading}
                    />
                    <Stack direction="row" justifyContent="flex-end" paddingTop="16px" gap="16px">
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}
                        disabled={saveFeedbackOverwriteMutation.isLoading}
                      >
                        {t('common:cancel')}
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleSave}
                        disabled={saveFeedbackOverwriteMutation.isLoading || !selectedLabelId}
                        endIcon={saveFeedbackOverwriteMutation.isLoading && <Spinner />}
                      >
                        {t('common:save')}
                      </Button>
                    </Stack>
                  </Stack>
                )}
                {consensusOverwrittenFlag && consensusOverwritten != null && (
                  <>
                    <Box paddingTop="24px">
                      <Typography variant="subtitle1">
                        {t('feedback:bucketExpand.overwrittenTooltipStart')}
                        <b>{consensusOverwritten.consensusOverwrittenBy}</b>,&nbsp;
                        {/* @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... */}
                        {formatDateTime(consensusOverwritten.consensusOverwrittenDate)}.
                      </Typography>
                    </Box>
                    <Box paddingTop="20px">
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        endIcon={saveFeedbackOverwriteMutation.isLoading && <Spinner />}
                        disabled={saveFeedbackOverwriteMutation.isLoading}
                        onClick={handleRevert}
                      >
                        {t('feedback:bucketExpand.revertToOriginalConsensus')}
                      </Button>
                    </Box>
                  </>
                )}
              </Stack>
            </Stack>
          )}
        </CardContent>
      </StyledConsensusMetrics>
      <FeedbackHistory jobId={jobId} bucketId={bucketId} />
    </Stack>
  );
}

export default ConsensusMetrics;

import { SVGProps } from 'react';

type CloneIconProps = SVGProps<SVGSVGElement>;

export default function CloneIcon({ ...props }: CloneIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="transparent" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_15490_18364)">
        <path
          // eslint-disable-next-line max-len
          d="M1.3335 3.46659C1.3335 2.71985 1.3335 2.34648 1.47882 2.06126C1.60665 1.81038 1.81063 1.60641 2.06151 1.47858C2.34672 1.33325 2.72009 1.33325 3.46683 1.33325H8.5335C9.28023 1.33325 9.6536 1.33325 9.93882 1.47858C10.1897 1.60641 10.3937 1.81038 10.5215 2.06126C10.6668 2.34648 10.6668 2.71985 10.6668 3.46659V8.53325C10.6668 9.27999 10.6668 9.65336 10.5215 9.93857C10.3937 10.1895 10.1897 10.3934 9.93882 10.5213C9.6536 10.6666 9.28023 10.6666 8.5335 10.6666H3.46683C2.72009 10.6666 2.34672 10.6666 2.06151 10.5213C1.81063 10.3934 1.60665 10.1895 1.47882 9.93857C1.3335 9.65336 1.3335 9.27999 1.3335 8.53325V3.46659Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          // eslint-disable-next-line max-len
          d="M5.3335 7.46659C5.3335 6.71985 5.3335 6.34648 5.47882 6.06126C5.60665 5.81038 5.81063 5.60641 6.06151 5.47858C6.34672 5.33325 6.72009 5.33325 7.46683 5.33325H12.5335C13.2802 5.33325 13.6536 5.33325 13.9388 5.47858C14.1897 5.60641 14.3937 5.81038 14.5215 6.06126C14.6668 6.34648 14.6668 6.71985 14.6668 7.46659V12.5333C14.6668 13.28 14.6668 13.6534 14.5215 13.9386C14.3937 14.1895 14.1897 14.3934 13.9388 14.5213C13.6536 14.6666 13.2802 14.6666 12.5335 14.6666H7.46683C6.72009 14.6666 6.34672 14.6666 6.06151 14.5213C5.81063 14.3934 5.60665 14.1895 5.47882 13.9386C5.3335 13.6534 5.3335 13.28 5.3335 12.5333V7.46659Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15490_18364">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { TFeedbackBulkContext } from './FeedbackBulkContext.type';

export const FeedbackBulkContext = createContext<TFeedbackBulkContext>({
  setEditLabelOnSelected: () => {},
  setPrepareConfirm: () => {},
  setPrepareClear: () => {},
  isReadOnly: true,
  selectedBucketsCount: 0,
  totalBucketsCount: 0,
  editLabelsCount: 0,
  handleCancelBulkFeedback: () => {},
  handleSaveBulkFeedback: () => {},
  canConfirmLabels: () => false,
  canClearLabels: () => false,
  handleLabelsClear: () => {},
  handleLabelsConfirm: () => {},
  isLoading: false
});

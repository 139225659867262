import { ReactElement, useEffect } from 'react';
import { withTransaction } from '@elastic/apm-rum-react';
import { useLoginHook } from './Login.hook';

import s from './style.module.css';

function AuthenticationOidc(): ReactElement {
  const { ssoAuthenticationExecute } = useLoginHook();

  useEffect(() => {
    setTimeout(() => {
      ssoAuthenticationExecute();
    }, 500);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={s.bg}>
        <h4>Completing authentication...</h4>
      </div>
    </>
  );
}
export default withTransaction('AuthenticationOidc', 'component')(AuthenticationOidc);

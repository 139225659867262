import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Stack,
  TextField
} from '@mui/material';
import { Label, Checkbox, TOption } from '@arcanna/generic';
import { FieldError } from 'react-hook-form';
import { forwardRef } from 'react';

export type TComboBoxProps<T> = {
  options: T[];
  label?: string;
  required?: boolean;
  tooltipText?: string;
  placeholder?: string;
  disabled?: boolean;
  value: T[];
  error?: FieldError | undefined;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  renderOptionLabel?: (option: T) => React.ReactNode;
  renderTags: (selectedOptions: T[], getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode;
} & Omit<AutocompleteProps<T, true, false, false>, 'renderInput'>;

function ComboBox<T extends TOption>({
  options,
  label,
  required,
  tooltipText,
  placeholder,
  disabled,
  value,
  error,
  onChange,
  renderInput,
  renderTags,
  renderOptionLabel = (option) => option.label
}: TComboBoxProps<T>) {
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          onClick={(event) => {
            event.preventDefault(); // stop propagation in order to not close the dropdown menu on checkbox click
            props.onClick?.(event);
          }}
        >
          <Stack paddingY={1}>
            <Checkbox label={renderOptionLabel(option)} state={selected ? 'checked' : 'default'} />
          </Stack>
        </li>
      )}
      renderTags={renderTags}
      limitTags={2}
      renderInput={(params) => {
        if (renderInput) {
          return renderInput(params);
        } else {
          return (
            <TextField
              {...params}
              label={label ? <Label required={required} text={label} tooltipText={tooltipText} /> : <></>}
              placeholder={placeholder}
              error={!!error}
              helperText={error?.message}
              sx={{
                '.MuiAutocomplete-inputRoot': {
                  paddingY: '4px',
                  minHeight: '40px',
                  height: 'auto',
                  input: { boxSizing: 'border-box', height: 'auto', maxHeight: '30px' }
                }
              }}
            />
          );
        }
      }}
      disabled={disabled}
      onChange={onChange}
      value={value}
      isOptionEqualToValue={(option, valueToCompare) => {
        return option.value === valueToCompare.value;
      }}
    />
  );
}

export default forwardRef(ComboBox) as <T>(
  props: TComboBoxProps<T> & { ref?: React.ForwardedRef<HTMLElement> }
) => ReturnType<typeof ComboBox>;

import { Spinner } from '@arcanna/generic';
import { useFeedbackBulkContext } from '@arcanna/pages/Feedback/FeedbackBulkContext/useFeedbackBulkContext';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClearMyFeedbackButton, ConfirmArcannaDecisionButton, SaveCancelArea, SelectCustomLabelArea } from './components';
import PermissionsCheck from 'src/components/shared/components/Permissions/Permissions';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import { permissions } from 'src/components/shared/static/ComponentsPermissions';
import StyledFeedbackFooterWrapper from './StyledFeedbackFooterWrapper';
import CustomLabelByJob from '../../models/CustomLabelByJob';

type FeedbackFlowFooterProps = {
  enabled: boolean;
  jobInfos: JobInfoResponse[];
  prepareConfirm: boolean;
  prepareClear: boolean;
};

function FeedbackFlowFooter({ enabled, jobInfos, prepareConfirm, prepareClear }: FeedbackFlowFooterProps) {
  const { t } = useTranslation(['common', 'feedback']);
  const { isLoading, selectedBucketsCount, isReadOnly, setEditLabelOnSelected, setPrepareClear, setPrepareConfirm } =
    useFeedbackBulkContext();
  const [selectedLabel, setSelectedLabel] = useState<CustomLabelByJob | null>(null);

  const feedbackChange = (editLabel: CustomLabelByJob | null) => {
    setPrepareClear(false);
    setPrepareConfirm(false);
    setSelectedLabel(editLabel);
    setEditLabelOnSelected(editLabel);
  };

  useEffect(() => {
    if (selectedBucketsCount == 0) {
      setSelectedLabel(null);
      setEditLabelOnSelected(null);
      setPrepareClear(false);
      setPrepareConfirm(false);
    }
  }, [selectedBucketsCount, setSelectedLabel, setEditLabelOnSelected, setPrepareClear, setPrepareConfirm]);

  return enabled ? (
    <Box>
      {isLoading && <Spinner isOverlay />}
      {!isReadOnly && (
        <PermissionsCheck
          permissions={[permissions.feedbackUpdate]}
          action={permissions.action.hiddenAction}
          testId={'feedback-footer'}
        >
          <StyledFeedbackFooterWrapper>
            <Stack direction="column" gap="8px" flexGrow={1} width="100%">
              <Box>
                <b>{selectedBucketsCount}</b>
                <span className="selectedBuckets">{t('feedback:selectedBuckets')}</span>
              </Box>
              <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                <Stack direction="row" gap={4}>
                  <ConfirmArcannaDecisionButton />
                  <SelectCustomLabelArea
                    jobInfos={jobInfos}
                    disabled={selectedBucketsCount === 0}
                    selectedLabel={selectedLabel}
                    onSelect={feedbackChange}
                  />
                  <ClearMyFeedbackButton />
                </Stack>
                <SaveCancelArea
                  editLabelsCount={selectedBucketsCount}
                  selectedLabel={selectedLabel}
                  prepareConfirm={prepareConfirm}
                  prepareClear={prepareClear}
                  onFinish={() => setSelectedLabel(null)}
                />
              </Stack>
            </Stack>
          </StyledFeedbackFooterWrapper>
        </PermissionsCheck>
      )}
    </Box>
  ) : (
    <></>
  );
}

export default FeedbackFlowFooter;

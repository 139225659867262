import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('FeedbackFiltersValuesResponse')
export class FeedbackFiltersValuesResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('recommended_values', Any)
  // eslint-disable-next-line
  recommended_values?: any;
}

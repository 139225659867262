import { Button, Popover, Stack, useTheme } from '@mui/material';
import * as React from 'react';

type TPopoverButton = {
  text: string;
  children: React.ReactNode;
  icon: React.ReactNode;
};

export default function PopoverButton({ text, children, icon }: TPopoverButton) {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        disableRipple
        variant={open ? 'containedHover' : 'contained'}
        color="secondary"
        size="small"
        onClick={handleClick}
        endIcon={icon}
      >
        {text}
      </Button>
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: '4px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack
          gap={'16px'}
          flexDirection={'column'}
          border={`1px solid ${palette.info.main}`}
          borderRadius={'4px'}
          padding={'12px'}
        >
          {children}
        </Stack>
      </Popover>
    </div>
  );
}

import { Button, DialogContent, Stack, Typography, useTheme } from '@mui/material';
import { IdeaBoxRequest } from 'src/components/shared/models/idea-box/IdeaBoxRequest';
import useIdeaBoxMutation from 'src/data-access/idea-box/useIdeaBoxMutation';
import IdeaBoxTitle from './components/IdeaBoxTitle';
import { FormProvider, RHFSubmitButton, RHFTextField } from '@arcanna/forms';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TIdeaBoxFormValues } from './IdeaBoxDialog.types';
import StyledIdeaBoxDialog from './StyledIdeaBoxDialog.styles';
import { joiResolver } from '@hookform/resolvers/joi';
import { getIdeaBoxDefaultValues, getIdeaBoxFormSchema } from './IdeaBoxDialog.utils';

type TIdeaBoxDialogProps = {
  isDialogOpen: boolean;
  setIsDialogOpen: (value: boolean) => void;
};

function IdeaBoxDialog({ isDialogOpen, setIsDialogOpen }: TIdeaBoxDialogProps) {
  const location = useLocation();
  const { t } = useTranslation('idea-box');
  const { palette } = useTheme();

  const { mutateAsync: sendIdea } = useIdeaBoxMutation();

  const schema = getIdeaBoxFormSchema(t);
  const methods = useForm<TIdeaBoxFormValues>({
    resolver: joiResolver(schema),
    defaultValues: getIdeaBoxDefaultValues()
  });

  const handleSubmit = async (values: TIdeaBoxFormValues) => {
    await sendIdea(new IdeaBoxRequest(values.topic, values.message, location.pathname));
    setIsDialogOpen(false);
    methods.reset();
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
    methods.reset();
  };

  return (
    <StyledIdeaBoxDialog open={isDialogOpen} onClose={handleCancel} maxWidth="sm">
      <DialogContent>
        <Stack direction="column" gap={5}>
          <IdeaBoxTitle />
          <FormProvider<TIdeaBoxFormValues> methods={methods} onSubmit={methods.handleSubmit(handleSubmit)}>
            <RHFTextField
              name="topic"
              id="topic"
              label={t('topic')}
              placeholder={t('topicPlaceholder')}
              required
              size="medium"
              fullWidth
              inputProps={{
                maxLength: 100
              }}
              className="topicInput"
            />
            <RHFTextField
              name="message"
              id="message"
              label={t('message')}
              placeholder={t('messagePlaceholder')}
              required
              size="medium"
              fullWidth
              multiline
              minRows={4}
              inputProps={{
                maxLength: 1200
              }}
              className="messageInput"
            />
            <Stack direction="row" gap={2} marginTop={5}>
              <RHFSubmitButton text={t('sendBtn')} />
              <Button variant="outlined" color="secondary" onClick={handleCancel}>
                {t('cancelBtn')}
              </Button>
            </Stack>
          </FormProvider>
          <Typography color={palette.grey.A400} fontSize="12px" lineHeight="20px" marginTop={5}>
            <Typography component="span" color={palette.secondary[300]} fontSize="12px" display="inline" lineHeight="20px">
              {t('disclaimer1')}
            </Typography>
            {t('disclaimer2')}
          </Typography>
        </Stack>
      </DialogContent>
    </StyledIdeaBoxDialog>
  );
}

export default IdeaBoxDialog;

import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { FeedbackUnifiedFiltersFieldsRequest } from 'src/components/shared/models/feedback/FeedbackUnifiedFiltersFieldsRequest';
import { config } from '../../config';
import { FeedbackFiltersFieldsResponse } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsResponse';
import { useMemo } from 'react';
import { getJsonConvert } from 'src/components/shared/utilities/json-convert';

export function useFeedbackUnifiedFilterFields(jobIds: number[]) {
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  return useQuery<FeedbackFiltersFieldsResponse, Error>([config.api.unified.filterFields, jobIds], () =>
    axios
      .post<{
        resource: FeedbackFiltersFieldsResponse;
      }>(config.api.unified.filterFields, new FeedbackUnifiedFiltersFieldsRequest(jobIds))
      .then((responseData: AxiosResponse<{ resource: FeedbackFiltersFieldsResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, FeedbackFiltersFieldsResponse)
      )
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function countNonNullValuesInMap(obj: { [key: string]: any }): number {
  return Object.values(obj).reduce((acc, value) => {
    if (Array.isArray(value)) {
      return acc + value.length;
    }
    if (value !== null && value !== undefined) {
      return acc + 1;
    }
    return acc;
  }, 0);
}

import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('JobRetrainMultipleRequest')
export class JobRetrainMultipleRequest extends CommonRequest {
  @JsonProperty('job_ids', [Number])
  job_ids: number[] = [];

  @JsonProperty('include_all_jobs', Boolean)
  include_all_jobs: boolean;

  constructor(job_ids: number[], include_all_jobs: boolean) {
    super();
    this.job_ids = job_ids;
    this.include_all_jobs = include_all_jobs;
  }
}

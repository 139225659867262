import { Stack, Switch, SwitchProps, Typography, useTheme } from '@mui/material';
import { Label } from '@arcanna/generic';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TRHFSwitchProps = SwitchProps & {
  name: string;
  label?: string;
  tooltipText?: string;
  required?: boolean;
};

function RHFSwitch({ label, tooltipText, name, required, disabled }: TRHFSwitchProps) {
  const theme = useTheme();
  const {
    control,
    formState: { isSubmitting }
  } = useFormContext();
  const { t } = useTranslation('common');

  const toBoolValue = (field: ControllerRenderProps<FieldValues, string>) => {
    return field.value == 'true' || field.value == true;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Stack direction="column" gap={0.5}>
          {label && <Label text={label} required={required} tooltipText={tooltipText} />}
          <Stack direction="row" alignItems="center" gap={1}>
            <Switch
              checked={toBoolValue(field)}
              // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage ...
              name={name}
              disabled={disabled || isSubmitting}
              // @ts-expect-error TS(2322): Type 'false | { opacity: ".7"; } | undefined' is n...
              sx={disabled && { opacity: '.7' }}
              {...field}
            />
            <Typography component="span" fontSize="14px" color={theme.palette.secondary[400]}>
              {toBoolValue(field) ? t('common:enabled') : t('common:disabled')}
            </Typography>
          </Stack>
        </Stack>
      )}
    />
  );
}

export default RHFSwitch;

import { Box, Button, Grid, Stack } from '@mui/material';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useState } from 'react';
import { SearchIcon } from 'src/components/shared/icons/SearchIcon';
import cx from 'classnames';
import styles from './styles.module.css';
import DecisionPointsTableEnrichedColumns from '../../../components/DecisionPointsTable/DecisionPointsTableEnrichedColumns';
import { useUrlFilters } from '../../../../../../shared/hooks/useUrlFilters';
import SingleBucketFeedback from '@arcanna/pages/Feedback/BucketDrawer/components/SingleBucketFeedback/SingleBucketFeedback';
import { EIcon, Icon } from '@arcanna/generic';
import { EFilterOperatorWithValue } from '@arcanna/components';
import BucketSummary from '../BucketSummary';
import { useBucketDrawerContext } from '@arcanna/pages/Feedback/BucketDrawer/context/useBucketDrawerContext';

type BucketDrawerFeedbackBodyProps = {
  jobId: number;
  bucketId: string;
  shortBucketId: string;
};

export default function BucketDrawerFeedbackBody({ jobId, bucketId, shortBucketId }: BucketDrawerFeedbackBodyProps) {
  const { t } = useTranslation(['feedback']);

  const { filterFields, advancedFilters, triggerBucketsReload } = useBucketDrawerContext();

  const { filtersToParams, encode } = useUrlFilters();

  const eventsExplorerUrl = React.useMemo(() => {
    const advancedFiltersList = [
      {
        id: shortBucketId,
        label: 'arcanna.bucket_id',
        field: 'arcanna.bucket_id',
        operator: EFilterOperatorWithValue.is,
        status: 'active',
        value: shortBucketId
      }
    ];
    return `/jobs/event-explorer/${jobId}?filters=${encode(filtersToParams({ job_id: jobId, advancedFilters: advancedFiltersList }))}`;
    // eslint-disable-next-line
  }, [shortBucketId, jobId]);

  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <Stack overflow="scroll" marginBottom="267px" gap={'16px'}>
        <Grid container direction="column">
          <Grid container direction="row" gap="8px">
            <Grid item>
              <Input
                placeholder={t('bucketExpand.searchDecisionPoints')}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                allowClear
                className={cx(styles.searchDP, styles.searchDPPlaceholder)}
                prefix={<SearchIcon />}
              />
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="secondary"
                variant="outlined"
                endIcon={<Icon name={EIcon.GoTo} />}
                onClick={() => window.open(eventsExplorerUrl, '_blank')}
              >
                {t('feedback:goToEventExplorerButtonLabel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <DecisionPointsTableEnrichedColumns
          jobId={jobId}
          bucketId={bucketId}
          searchValue={searchValue}
          filterFields={filterFields}
          advancedFilters={advancedFilters}
        />
        <BucketSummary jobId={jobId} bucketId={bucketId} />
      </Stack>
      <Box position="absolute" bottom="0" right="0" width="100%">
        <SingleBucketFeedback jobId={jobId} bucketId={bucketId} triggerBucketsReload={triggerBucketsReload} />
      </Box>
    </>
  );
}

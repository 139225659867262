import useDecisionPointsTableData from './useDecisionPointsTableData';
import DecisionPointsTable from './index';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import { TUseAdvancedFilters } from '@arcanna/components';

export type DecisionPointsTableEnrichedColumnsProps = {
  jobId: number;
  bucketId: string;
  searchValue: string;
  filterFields: FeedbackFiltersFieldsRecord[];
  advancedFilters: TUseAdvancedFilters;
};

export default function DecisionPointsTableEnrichedColumns({
  jobId,
  bucketId,
  searchValue,
  filterFields,
  advancedFilters
}: DecisionPointsTableEnrichedColumnsProps) {
  const { tableData, columns, loadingTable } = useDecisionPointsTableData({
    jobId,
    bucketId,
    showValues: true,
    searchValue,
    filterFields,
    advancedFilters
  });

  return <DecisionPointsTable tableData={tableData} columns={columns} loadingTable={loadingTable} />;
}

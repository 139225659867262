import CustomLabelByJob from '@arcanna/pages/Feedback/FeedbackTable/models/CustomLabelByJob';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';

function getCustomLabelsFromJobs(jobInfos: JobInfoResponse[]): CustomLabelByJob[] {
  const labelsMap = new Map<string, CustomLabelByJob>();
  jobInfos.forEach((jobInfo) => {
    jobInfo.info?.advancedSettings.customLabels.forEach((customLabel) => {
      const labelKey = customLabel.id + '-' + customLabel.name + '-' + customLabel.hexColor;
      const jobId = jobInfo.info?.id;
      if (jobId == undefined) {
        return;
      }
      if (labelsMap.has(labelKey)) {
        labelsMap.get(labelKey)?.jobIds?.push(jobId);
      } else {
        labelsMap.set(labelKey, CustomLabelByJob.createfromCustomLabel(customLabel, [jobId]));
      }
    });
  });
  return Array.from(labelsMap.values());
}

export { getCustomLabelsFromJobs };

import { Box, Button, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomLabel } from '@arcanna/components';
import { useMemo, useState } from 'react';
import useUpdateBucketFeedback from 'src/data-access/feedback/useUpdateBucketFeedback';
import { FeedbackUpdateRequest } from 'src/components/shared/models/feedback/FeedbackUpdateRequest';
import { FeedbackUpdateRecord } from 'src/components/shared/models/feedback/FeedbackUpdateRecord';
import ConfirmDecisionButton from './components/ConfirmDecisionButton';
import useClearFeedback from 'src/data-access/feedback/useClearFeedback';
import useLabelsFeedbackOptions from './hooks/useLabelsFeedbackOptions';
import ModelResultAndUserFeedbackLabels from './components/ModelResultAndUserFeedbackLabels';
import { DotDotDotVerticalIcon } from 'src/themes/icons/DotDotDotVerticalIcon';
import StyledSingleBucketFeedback from './StyledSingleBucketFeedback.styles';
import React from 'react';
import { LabelCircle } from '@arcanna/components';
import { Spinner } from '@arcanna/generic';
import { useFeedbackBucketDetails } from 'src/data-access';

export type SingleBucketFeedbackProps = {
  jobId: number;
  bucketId: string;
  triggerBucketsReload: () => void;
};

export default function SingleBucketFeedback({ jobId, bucketId, triggerBucketsReload }: SingleBucketFeedbackProps) {
  const { t } = useTranslation(['common', 'feedback']);

  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);
  const openMore = Boolean(moreAnchorEl);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { visibleLabels, moreLabels, feedbackLabelOnBucket, arcannaDecisionLabel } = useLabelsFeedbackOptions({
    jobId,
    bucketId
  });

  const updateBucketFeedbackMutation = useUpdateBucketFeedback(bucketId, jobId);
  const clearBucketFeedback = useClearFeedback();
  const bucketDetailsQuery = useFeedbackBucketDetails(jobId, bucketId);

  const hasConsensusOverwritten = useMemo(
    () => bucketDetailsQuery.data?.bucket.consensusOverwritten?.consensusOverwrittenBy != null,
    [bucketDetailsQuery.data]
  );

  const clearFeedbackOnBucket = async () => {
    setIsLoading(true);
    const update = new FeedbackUpdateRecord(bucketId);
    clearBucketFeedback
      .mutateAsync(new FeedbackUpdateRequest(jobId, [update]))
      .then(() => {
        triggerBucketsReload();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateFeedbackOnBucket = async (labelId: string) => {
    setMoreAnchorEl(null);
    setIsLoading(true);
    const update = new FeedbackUpdateRecord(bucketId, labelId);
    updateBucketFeedbackMutation
      .mutateAsync(new FeedbackUpdateRequest(jobId, [update]))
      .then(() => {
        triggerBucketsReload();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleMoreClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };

  return (
    <StyledSingleBucketFeedback>
      <Box className="singleBucketFeedbackSection">
        {isLoading ? (
          <Spinner isOverlay />
        ) : (
          <>
            <Box className="arcannaAndFeedbackContainer">
              <Box marginTop="auto" marginBottom="auto" marginRight="20px">
                <Stack direction="row" gap="8px">
                  <Typography variant="subtitle1">{t('common:arcannaDecision')}:</Typography>
                  <ModelResultAndUserFeedbackLabels
                    jobId={jobId}
                    bucketId={bucketId}
                    modelDecision={arcannaDecisionLabel}
                    // eslint-disable-next-line
                    userLabel={feedbackLabelOnBucket as any}
                  />
                </Stack>
              </Box>
              <ConfirmDecisionButton
                arcannaDecision={arcannaDecisionLabel?.id}
                // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
                bucketFeedbackFromUser={feedbackLabelOnBucket?.id}
                onConfirmDecision={() => {
                  updateFeedbackOnBucket(arcannaDecisionLabel?.id);
                }}
                onClearFeedback={clearFeedbackOnBucket}
                disabled={hasConsensusOverwritten}
              />
            </Box>
            {
              <div className="labelsContainer">
                <Typography variant="subtitle1" className="changeDecisionTxt">
                  {t('feedback:changeDecisionTo')}:
                </Typography>
                <Grid container className="decisionLabelsWrapper">
                  {visibleLabels.map((customLabel) => {
                    return (
                      <Grid item xs={12 / 5} key={`single-feedback-option-${customLabel.id}`}>
                        <CustomLabel
                          id={customLabel.id}
                          hexColor={customLabel.hexColor}
                          name={customLabel.name}
                          hasBorder
                          disabled={hasConsensusOverwritten}
                          disabledTooltip={false}
                          disabledTooltipOnOverflow={true}
                          showBorderOnlyOnHover
                          className="customLabel"
                          onClick={updateFeedbackOnBucket}
                          width={104}
                        />
                      </Grid>
                    );
                  })}
                  {moreLabels.length > 0 && (
                    <Grid item xs={12 / 5}>
                      <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        className="moreLabels"
                        // @ts-expect-error TS(2774): This condition will always return true since this ...
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        // @ts-expect-error TS(2774): This condition will always return true since this ...
                        aria-expanded={open ? 'true' : undefined}
                        endIcon={<DotDotDotVerticalIcon />}
                        disabled={hasConsensusOverwritten}
                        onClick={handleMoreClicked}
                      >
                        {t('feedback:moreLabels')}
                      </Button>
                      <Menu
                        anchorEl={moreAnchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: moreLabels.length * 48 + 6,
                          horizontal: 'left'
                        }}
                        open={openMore}
                        onClose={handleMoreClose}
                      >
                        {moreLabels.map((customLabel) => (
                          <MenuItem
                            key={customLabel.id}
                            onClick={() => updateFeedbackOnBucket(customLabel.id)}
                            className="MuiMenuItem-root--small"
                          >
                            <Stack direction="row" gap={1} alignItems="center" minWidth="210px">
                              <LabelCircle hexColor={customLabel.hexColor} id={customLabel.id} />
                              <span>{customLabel.name}</span>
                            </Stack>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Grid>
                  )}
                </Grid>
              </div>
            }
          </>
        )}
      </Box>
    </StyledSingleBucketFeedback>
  );
}

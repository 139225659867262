import { useUrlFilters } from 'src/components/shared/hooks/useUrlFilters';
import { FeedbackFlowFilters, FeedbackStateType } from '../FeedbackFlow.context';
import moment from 'moment';
import { TFeedbackUnifiedState } from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedProvider/FeedbackUnifiedProvider';

export default function useFeedbackFlowFilters() {
  const { getFiltersFromQueryParams } = useUrlFilters<FeedbackFlowFilters>();

  const getFeedbackFiltersFromQueryParams = (state: FeedbackStateType | TFeedbackUnifiedState) => {
    const filters = getFiltersFromQueryParams({
      startDate: state.startDate ? state.startDate.toISOString() : null,
      endDate: state.endDate ? state.endDate.toISOString() : null,
      advancedFilters: state.advancedFilters,
      quickFilters: state.quickFilters,
      tablePageSize: state.tablePageSize,
      tablePage: state.tablePage,
      tableSortColumn: state.tableSort.column,
      tableSortOrder: state.tableSort.order,
      excludeProcessed: `${state.excludeProcessed}`,
      includeProcessed: `${state.includeProcessed}`
    });

    return {
      startDate: filters.startDate ? moment(filters.startDate).toDate() : null,
      endDate: filters.endDate ? moment(filters.endDate).toDate() : null,
      advancedFilters: filters.advancedFilters,
      quickFilters: filters.quickFilters,
      tablePageSize: Number(filters.tablePageSize),
      tablePage: Number(filters.tablePage),
      tableSort: {
        column: filters.tableSortColumn,
        order: filters.tableSortOrder
      },
      // all query params are parsed as strings and this will not change. See https://github.com/ljharb/qs/issues/91
      excludeProcessed: filters.excludeProcessed === 'true',
      includeProcessed: filters.includeProcessed === 'true'
    };
  };
  return {
    getFeedbackFiltersFromQueryParams
  };
}

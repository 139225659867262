import FeedbackSharedContextProvider from '@arcanna/pages/Feedback/FeedbackContext/FeedbackSharedContextProvider';
import FeedbackUnifiedProvider from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedProvider/FeedbackUnifiedProvider';
import FeedbackUnifiedPageContent from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedPageContent/FeedbackUnifiedPageContent';

function FeedbackUnified() {
  return (
    <FeedbackSharedContextProvider>
      <FeedbackUnifiedProvider>
        <FeedbackUnifiedPageContent />
      </FeedbackUnifiedProvider>
    </FeedbackSharedContextProvider>
  );
}

export default FeedbackUnified;

import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Popconfirm, Row, Select, Tag, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { EditOutlined, SaveOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons/lib';

import { useFetch } from '../../../shared/hooks/useFetch';
import { getBackendEndpoint } from '../../../shared/utilities/api';
import { getJsonConvert } from '../../../shared/utilities/json-convert';
import { useUserContext } from './User.context';
import { UserSummaryRequest } from '../../../shared/models/user/UserSummaryRequest';
import { UserSummaryResponse } from '../../../shared/models/user/UserSummaryResponse';
import { CommonPaginationRequest } from '../../../shared/models/common/CommonPaginationRequest';
import { UserRoleNamesRequest } from '../../../shared/models/user/UserRolesNamesRequest';
import { UserRoleNamesResponse } from '../../../shared/models/user/UserRoleNamesResponse';
import { UserUpdateRequest } from '../../../shared/models/user/UserUpdateRequest';
import { showSuccessNotification, showErrorNotification } from '../../../shared/utilities/notification';
import { UserUpdateResponse } from '../../../shared/models/user/UserUpdateResponse';
import { UserSummaryRecord } from '../../../shared/models/user/UserSummaryRecord';
import { UserUpdateRecord } from '../../../shared/models/user/UserUpdateRecord';
import { UserRoleNamesRecord } from '../../../shared/models/user/UserRoleNamesRecord';
import { config } from '../../../../config';
import { UserDeleteRequest } from '../../../shared/models/user/UserDeleteRequest';
import { UserDeleteResponse } from '../../../shared/models/user/UserDeleteResponse';
import { permissions } from '../../../shared/static/ComponentsPermissions';
import PermissionsCheck from '../../../shared/components/Permissions/Permissions';
import { searchText } from '../../../../utils/searchText';
import styles from './User.module.css';

export function useUserHook() {
  const {
    state: userState,
    setReloadUserRoles,
    setReloadUsers,
    setUserRoleNames,
    setTableData,
    setInitialTableData,
    setSearchValue,
    setConfirmSave,
    setConfirmDelete,
    setSelectedUserForDelete,
    setRolesToBeUpdated,
    setChangedColumnRole,
    setColumnToEdit
  } = useUserContext();
  const { t } = useTranslation(['common', 'user']);
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const { Option } = Select;

  const history = useHistory();

  const { responseData: updateResponseData, post: postUpdate } = useFetch({
    path: getBackendEndpoint('/user/update_role'),
    initialResponseData: null,
    load: false
  });

  const { responseData: deleteResponseData, post: postDelete } = useFetch({
    path: getBackendEndpoint('/user/delete'),
    initialResponseData: null,
    load: false
  });

  const navigateToAdd = useCallback(() => {
    const route = config.routes.userAdd;
    history.push(route);
  }, [history]);

  const navigateToList = useCallback(() => {
    const route = config.routes.users;
    history.push(route);
  }, [history]);

  const handleOnSearch = useCallback(
    (value: string) => {
      setSearchValue(value);
      if (userState.searchValue != null) {
        setTableData(searchText<UserSummaryRecord>(userState.tableData, userState.searchValue, 'username'));
      } else {
        setTableData(userState.initialTableData);
      }
    },
    // eslint-disable-next-line
    [userState.tableData, userState.initialTableData]
  );

  const handleChange = useCallback((id: string) => {
    setChangedColumnRole(id);
    // eslint-disable-next-line
  }, []);

  const handleSaveConfirm = useCallback(() => {
    if (userState.rolesToBeUpdated.length > 0) {
      postUpdate(new UserUpdateRequest(userState.rolesToBeUpdated));
    }
    // eslint-disable-next-line
  }, [userState.rolesToBeUpdated]);

  const handleDeleteConfirm = useCallback(
    // eslint-disable-next-line
    (username: any) => {
      postDelete(new UserDeleteRequest(username));
    },
    [postDelete]
  );

  const handleOnSave = useCallback(() => {
    setConfirmSave(true);
    // eslint-disable-next-line
  }, []);

  const handleSaveCancel = useCallback(() => {
    setConfirmSave(false);
    setRolesToBeUpdated([]);
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
    setChangedColumnRole(null);
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
    setColumnToEdit(null);
    // eslint-disable-next-line
  }, []);

  const handleOnDelete = useCallback(
    (id: string) => {
      setSelectedUserForDelete(id);
      setConfirmDelete(true);
    },
    [setConfirmDelete, setSelectedUserForDelete]
  );

  const handleDeleteCancel = useCallback(() => {
    setConfirmDelete(false);
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
    setSelectedUserForDelete(null);
  }, [setConfirmDelete, setSelectedUserForDelete]);

  const getUserRoles = useCallback(
    (item: UserSummaryRecord) => {
      if (userState.userRoleNames.length === 0) {
        if (userState.columnToEdit === item.id) {
          showErrorNotification(t('common:error'), t('user:rolesNotAvailable'));
        }
        return null;
      }

      let currentRoles = item.roles;
      const addedRoles = userState.rolesToBeUpdated.filter(
        (role: UserUpdateRecord) => role.operation === 'add' && role.user_id === item.id
      );
      const removedRoles = userState.rolesToBeUpdated.filter(
        (role: UserUpdateRecord) => role.operation === 'remove' && role.user_id === item.id
      );
      addedRoles.forEach((addedRole: UserUpdateRecord) => {
        // Prevent double adding the same role on re-render
        currentRoles = currentRoles.filter((role: string) => role !== addedRole.role_id);
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        currentRoles.push(addedRole.role_id);
      });
      removedRoles.forEach((removedRole: UserUpdateRecord) => {
        currentRoles = currentRoles.filter((role: string) => role !== removedRole.role_id);
      });
      if (userState.columnToEdit !== item.id) {
        return currentRoles.map((roleId: string, index: number) => {
          const roleName = userState.userRoleNames.filter((roleDetails) => roleDetails.id === roleId)[0].name;
          return <Tag key={index}>{roleName}</Tag>;
        });
      }

      return (
        <Row>
          <Col xs={24}>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder={t('user:selectRole')}
              defaultValue={currentRoles}
              onSelect={(roleId: string) => {
                // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
                handleChange(item.id);
                // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
                setRolesToBeUpdated([...userState.rolesToBeUpdated, new UserUpdateRecord(roleId, item.id, 'add')]);
              }}
              onDeselect={(roleId: string) => {
                // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
                handleChange(item.id);
                const roleThatWasNotSaved = userState.rolesToBeUpdated.filter(
                  (role: UserUpdateRecord) => role.role_id === roleId && role.operation === 'add' && role.user_id === item.id
                );
                if (roleThatWasNotSaved.length) {
                  setRolesToBeUpdated(
                    userState.rolesToBeUpdated.filter(
                      (role: UserUpdateRecord) => role.role_id !== roleId || role.operation !== 'add' || role.user_id !== item.id
                    )
                  );
                  return;
                }
                // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
                setRolesToBeUpdated([...userState.rolesToBeUpdated, new UserUpdateRecord(roleId, item.id, 'remove')]);
              }}
            >
              {userState.userRoleNames.map((role: UserRoleNamesRecord) => (
                <Option key={role.id} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      );
    },
    // eslint-disable-next-line
    [userState.userRoleNames, userState.columnToEdit, userState.rolesToBeUpdated]
  );

  const createdFrom = (item: UserSummaryRecord) => {
    return item?.external ? t('user:external') : t('user:internal');
  };

  const getUserColumns = () => {
    const columns = [
      {
        title: t('user:username'),
        width: 250,
        key: 'username',
        dataIndex: 'username'
      },
      {
        title: t('user:displayName'),
        width: 250,
        key: 'display_name',
        dataIndex: 'display_name'
      },
      {
        title: t('user:email'),
        width: 250,
        key: 'email',
        dataIndex: 'email'
      },
      {
        title: t('user:roles'),
        key: 'roles',
        width: 300,
        render: (item: UserSummaryRecord) => getUserRoles(item)
      },
      {
        title: t('user:createdFrom'),
        width: 200,
        key: 'created_from',
        render: (item: UserSummaryRecord) => createdFrom(item)
      },
      {
        title: t('common:actions'),
        key: 'action',
        width: 200,
        render: (item: UserSummaryRecord) => (
          <Row>
            <Col>
              {userState.changedColumnRole === item.id ? (
                <Popconfirm
                  placement="left"
                  title={t('user:saveConfirm')}
                  onConfirm={() => handleSaveConfirm()}
                  onCancel={() => handleSaveCancel()}
                  okText={t('common:yes')}
                  cancelText={t('common:cancel')}
                  open={userState.confirmSave}
                  icon={<ExclamationCircleOutlined className="ant-popconfirm" />}
                >
                  <Tooltip placement="top" title={t('user:saveUserRoles')}>
                    <Button onClick={() => handleOnSave()}>
                      <SaveOutlined className="color-success p-2" />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Tooltip placement="top" title={t('user:editUserRoles')}>
                  <PermissionsCheck
                    permissions={[permissions.userUpdate]}
                    action={permissions.action.disabledAction}
                    testId={'edit-user-role'}
                  >
                    {/* @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... */}
                    <Button onClick={() => setColumnToEdit(item.id)} className={styles.btn} disabled={item?.external}>
                      <EditOutlined />
                    </Button>
                  </PermissionsCheck>
                </Tooltip>
              )}
            </Col>
            <Col>
              {userState.selectedUserForDelete === item.id ? (
                <Popconfirm
                  placement="left"
                  title={t('user:deleteConfirm')}
                  onConfirm={() => handleDeleteConfirm(item.username)}
                  onCancel={() => handleDeleteCancel()}
                  okText={t('common:yes')}
                  cancelText={t('common:cancel')}
                  open={userState.confirmDelete}
                  icon={<ExclamationCircleOutlined className="ant-popconfirm" />}
                >
                  <Tooltip placement="top" title={t('user:deleteUser')}>
                    {/* eslint-disable-next-line */}
                    <Button onClick={() => {}} className="p-2">
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Tooltip placement="top" title={t('user:deleteUser')}>
                  <PermissionsCheck
                    permissions={[permissions.userDelete]}
                    action={permissions.action.disabledAction}
                    testId={'delete-user'}
                  >
                    {/* @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... */}
                    <Button onClick={() => handleOnDelete(item.id)} className={styles.btn} disabled={item?.external}>
                      <DeleteOutlined />
                    </Button>
                  </PermissionsCheck>
                </Tooltip>
              )}
            </Col>
          </Row>
        )
      }
    ];

    return columns;
    // eslint-disable-next-line
  };

  const { responseData: responseDataRoles, post: postRoles } = useFetch({
    path: getBackendEndpoint('/user/role_names'),
    initialResponseData: null,
    load: false
  });

  useEffect(() => {
    if (userState.reloadUserRoles) {
      postRoles(new UserRoleNamesRequest());
    }
  }, [postRoles, userState.reloadUserRoles]);

  useEffect(() => {
    if (responseDataRoles !== null) {
      const rolesResponse = jsonConvert.deserializeObject(responseDataRoles.resource, UserRoleNamesResponse);
      // @ts-expect-error TS(2345): Argument of type 'UserRoleNamesRecord[] | undefine...
      setUserRoleNames(rolesResponse.entries);
    }
    // eslint-disable-next-line
  }, [responseDataRoles, jsonConvert]);

  const {
    responseData: responseDataSummary,
    post: postSummary,
    loading: loadingSummary
  } = useFetch({
    path: getBackendEndpoint('/user/summary'),
    initialResponseData: null,
    load: false
  });

  useEffect(() => {
    if (userState.reloadUserRoles || userState.reloadUsers) {
      postSummary(new UserSummaryRequest(new CommonPaginationRequest(0, 100)));
      setReloadUserRoles(false);
      setRolesToBeUpdated([]);
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
      setSelectedUserForDelete(null);
      setReloadUsers(false);
    }
    // eslint-disable-next-line
  }, [postSummary, userState.reloadUserRoles, userState.reloadUsers]);

  useEffect(() => {
    if (responseDataSummary !== null) {
      const userSummaryResponse = jsonConvert.deserializeObject(responseDataSummary.resource, UserSummaryResponse);
      const { entries } = userSummaryResponse;
      // @ts-expect-error TS(2345): Argument of type 'UserSummaryRecord[] | undefined'...
      setInitialTableData(entries);

      if (userState.searchValue != null && userState.searchValue.length > 0) {
        // @ts-expect-error TS(2345): Argument of type 'UserSummaryRecord[] | undefined'...
        setTableData(searchText<UserSummaryRecord>(entries, userState.searchValue, 'username'));
      } else {
        // @ts-expect-error TS(2345): Argument of type 'UserSummaryRecord[] | undefined'...
        setTableData(entries);
      }
    }
    // eslint-disable-next-line
  }, [responseDataSummary, jsonConvert, userState.searchValue]);

  useEffect(() => {
    if (updateResponseData !== null) {
      const updateResponse = jsonConvert.deserializeObject(updateResponseData.resource, UserUpdateResponse);
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (updateResponse.request.status === 'OK') {
        showSuccessNotification(t('common:actionCompleted'), `${t('user:roleUpdatedSuccessfully')}`);
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      } else if (updateResponse.request.status === 'NOK') {
        showSuccessNotification(
          t('common:actionCompleted'),
          `${t('user:actionFailed')} Message: ${updateResponse.request?.reason}`
        );
      }
      setReloadUserRoles(true);
      setRolesToBeUpdated([]);
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
      setColumnToEdit(null);
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
      setChangedColumnRole(null);
      setConfirmSave(false);
    }
    // eslint-disable-next-line
  }, [updateResponseData, jsonConvert, t]);

  useEffect(() => {
    if (deleteResponseData !== null) {
      const deleteResponse = jsonConvert.deserializeObject(deleteResponseData.resource, UserDeleteResponse);
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (deleteResponse.request.status === 'OK') {
        showSuccessNotification(t('common:actionCompleted'), `${t('user:userDeletedSuccessfully')}`);
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      } else if (deleteResponse.request.status === 'NOK') {
        showSuccessNotification(
          t('common:actionCompleted'),
          `${t('user:actionFailed')} Message: ${deleteResponse.request?.reason}`
        );
      }
      setReloadUsers(true);
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
      setSelectedUserForDelete(null);
      setConfirmDelete(false);
    }
    // eslint-disable-next-line
  }, [deleteResponseData, jsonConvert, t]);

  return {
    navigateToAdd,
    navigateToList,
    handleOnSave,
    loadingSummary,
    handleOnSearch,
    getUserRoles,
    getUserColumns,
    handleSaveConfirm,
    handleSaveCancel
  };
}

import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { FeedbackBucketDetailsRequest } from '../../components/shared/models/feedback/FeedbackBucketDetailsRequest';
import { FeedbackBucketSimilarityResponse } from '../../components/shared/models/feedback/FeedbackBucketSimilarityResponse';

export function useFeedbackBucketSimilarity(jobId: number, bucketId: string) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<FeedbackBucketSimilarityResponse, Error>(
    [config.api.feedbackBucketSimilarity.replace(':bucketId', bucketId)],
    () =>
      axios
        .post<{
          resource: FeedbackBucketSimilarityResponse;
        }>(config.api.feedbackBucketSimilarity.replace(':bucketId', bucketId), new FeedbackBucketDetailsRequest(jobId))
        .then((responseData: AxiosResponse<{ resource: FeedbackBucketSimilarityResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, FeedbackBucketSimilarityResponse)
        )
  );
}

import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { FeedbackOverwriteRequest } from '../../components/shared/models/feedback/FeedbackOverwriteRequest';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { config } from '../../config';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { useTranslation } from 'react-i18next';

export function useFeedbackBucketOverwrite() {
  const { t } = useTranslation(['common', 'feedback']);
  const queryClient = useQueryClient();

  return useMutation(
    (payload: FeedbackOverwriteRequest) => {
      return axios.post<{ resource: CommonResponse }>(config.api.feedbackBucketsOverwrite, payload);
    },
    {
      onSuccess: (_data, variables: FeedbackOverwriteRequest) => {
        if (variables.revert == true) {
          showSuccessNotification(t('common:actionCompleted'), t('feedback:overwriteRevertLabelSuccessfully'));
        } else {
          showSuccessNotification(t('common:actionCompleted'), t('feedback:overwriteLabelSuccessfully'));
        }
      },
      onError: () => {
        showErrorNotification(t('common:error'), t('common:updateError'));
      },
      onSettled: (_data, _error, variables: FeedbackOverwriteRequest) => {
        queryClient.invalidateQueries([config.api.feedbackBucketsOverwrite]);
        // @ts-expect-error TS(2769): No overload matches this call.
        queryClient.resetQueries([config.api.feedbackBucketDetails.replace(':bucketId', variables.entry_id)]);
        queryClient.resetQueries([config.api.feedbackBucketsBatch]);
      }
    }
  );
}

import { useMutation, useQueryClient } from 'react-query';
import { showErrorNotification, showSuccessNotification } from 'src/components/shared/utilities/notification';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config';
import axios from 'axios';
import { UnifiedDecisionPointsListUpdate } from '@arcanna/models/Jobs';
import { ErrorResponse } from 'src/components/shared/models/error/ErrorResponse';

function useUpdateUnifiedDecisionPointsList() {
  const { t } = useTranslation(['common', 'decisionPointsUnified']);
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UnifiedDecisionPointsListUpdate) => axios.post(config.api.jobFeatureSelectionUnifiedUpdate, payload),
    {
      onError: (err: { data: ErrorResponse }) => {
        showErrorNotification(t('common:error'), err.data.resource.request.reason || t('decisionPointsUnified:updateDPError'));
      },
      onSuccess: () => {
        showSuccessNotification(t('common:actionCompleted'), t('decisionPointsUnified:updateDPSuccess'));
      },
      onSettled: (_, __, payload: UnifiedDecisionPointsListUpdate) => {
        payload.updates.forEach((update) =>
          queryClient.invalidateQueries([config.api.jobFeedbackFilterFields + `${update.job_id}`])
        );
      }
    }
  );
}

export default useUpdateUnifiedDecisionPointsList;

import { PageLayout } from '@arcanna/layouts';
import { withTransaction } from '@elastic/apm-rum-react';
import { useFeedbackUnifiedContext } from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedProvider/FeedbackUnifiedProvider';
import { EIcon, Icon } from '@arcanna/generic';
import { useTranslation } from 'react-i18next';
import FeedbackUnifiedTable from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedTable';
import { Breadcrumbs } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { getCustomLabelsFromJobs } from '@arcanna/pages/Feedback/FeedbackTable/components/FeedbackFlowFooter/components/SelectCustomLabelArea/SelectCustomLabelArea.utils';
import { config } from 'src/config';
import { fetchJobInfo } from 'src/data-access';
import React from 'react';
import { getJsonConvert } from 'src/components/shared/utilities/json-convert';
import FeedbackUnifiedHeader from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedHeader';
import FeedbackUnifiedBucketDrawer from '../FeedbackUnifiedBucketDrawer';
import LabelContextProvider from '@arcanna/pages/Feedback/FeedbackContext/LabelContextProvider';

function FeedbackUnifiedPageContent() {
  const feedbackState = useFeedbackUnifiedContext((context) => context.state);
  const setJobIds = useFeedbackUnifiedContext((context) => context.setJobIds);
  const setJobInfos = useFeedbackUnifiedContext((context) => context.setJobInfos);
  const setJobCustomLabels = useFeedbackUnifiedContext((context) => context.setJobCustomLabels);
  const { t } = useTranslation(['common', 'homepage', 'job', 'feedback']);
  const queryClient = useQueryClient();

  const { ids } = useParams<{ ids: string }>();
  const jobIds = useMemo(() => ids.split(',').map((id: string) => Number(id)), [ids]);
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  useEffect(() => {
    setJobIds(jobIds);
    Promise.all(jobIds.map(async (id) => await fetchJobInfo(id, jsonConvert))).then((jInfos) => {
      setJobInfos(jInfos);
      setJobCustomLabels(getCustomLabelsFromJobs(jInfos));
    });
    queryClient.invalidateQueries([config.api.feedbackBucketsUnifiedBatch, jobIds]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobIds, queryClient]);

  return (
    <PageLayout
      breadcrumbContent={
        <Breadcrumbs separator={<Icon name={EIcon.ChevronRight} />}>
          <Link to={config.routes.jobsAll}>{t('homepage:AIJobsCardTitle')}</Link>
          <span>{t('feedback:jobsGroup')}</span>
          <span>{t('job:navigation.feedback')}</span>
        </Breadcrumbs>
      }
      isLoading={feedbackState.loading}
      gap={2}
    >
      <FeedbackUnifiedHeader jobIds={jobIds} jobInfos={feedbackState.jobInfos} />
      <LabelContextProvider>
        <FeedbackUnifiedTable />
      </LabelContextProvider>
      <FeedbackUnifiedBucketDrawer />
    </PageLayout>
  );
}

export default withTransaction('FeedbackUnified', 'component')(FeedbackUnifiedPageContent);

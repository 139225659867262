import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { AuthRecord } from './AuthRecord';
import { CustomerRecord } from '../customer/CustomerRecord';

@JsonObject('AuthResponse')
export class AuthResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('user', AuthRecord)
  user?: AuthRecord = undefined;

  @JsonProperty('customer', CustomerRecord)
  customer?: CustomerRecord = undefined;
}

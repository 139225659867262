import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { UserAddRequest } from '../../components/shared/models/user/UserAddRequest';
import { UserAddResponse } from '../../components/shared/models/user/UserAddResponse';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';

type Response = AxiosResponse<{ resource: { request: UserAddResponse } }>;

export function useAddUser() {
  const { t } = useTranslation(['job']);
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useMutation<Response, Response, UserAddRequest>(
    (payload: UserAddRequest) => {
      return axios.post<{ resource: { request: UserAddResponse } }>('/user/create', payload);
    },
    {
      onSuccess: (responseData: Response) => {
        if (responseData !== null) {
          const userAddResponse = jsonConvert.deserializeObject(responseData.data.resource, UserAddResponse);

          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          if (userAddResponse.request.status === 'OK') {
            showSuccessNotification(t('common:actionCompleted'), t('common:createdSuccessfully'));
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          } else if (userAddResponse.request.status === 'NOK') {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            showErrorNotification(t('common:error'), `${t('common:createFailed')} Message: ${userAddResponse.request.reason}`);
          }
        }
      },
      onError: (responseData: Response) => {
        if (responseData !== null) {
          const userAddResponse = jsonConvert.deserializeObject(responseData.data.resource, UserAddResponse);
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          if (userAddResponse.request.status === 'NOK') {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            showErrorNotification(t('common:error'), `${t('common:createFailed')} Message: ${userAddResponse.request.reason}`);
          }
        }
      }
    }
  );
}

import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('UserKeyDeleteRequest')
export class UserKeyDeleteRequest extends CommonRequest {
  @JsonProperty('id', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  id: string = undefined;

  constructor(id: string) {
    super();
    this.id = id;
  }
}

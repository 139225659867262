import { memo } from 'react';
import { IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon, EIcon } from '@arcanna/generic';
import StyledGeneralCellWithFilters from './StyledGeneralCellWithFilters.styles';
import { getDisplayedValue } from './GeneralCellWithFilters.utils';
import { copyToClipboard } from '@arcanna/utils';
import { AdvancedFilters, TFilterItem, EFilterOperatorWithValue, EFilterOperatorWithoutValue } from '@arcanna/components';

type TGeneralCellWithFiltersProps = {
  columnName: string;
  filterSource: string | undefined;
  value: string | undefined;
  activeFilters: TFilterItem[];
  addAdvancedFilter: (filterItem: TFilterItem) => void;
  tableSize: string;
};

function GeneralCellWithFilters({
  value,
  activeFilters,
  filterSource,
  columnName,
  addAdvancedFilter,
  tableSize
}: TGeneralCellWithFiltersProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getIsFilterPresent, getFilterToApply } = AdvancedFilters.useQuickFilters({ value, filterSource, columnName });

  const renderFilterButton = (
    operatorWithValue: EFilterOperatorWithValue,
    operatorWithoutValue: EFilterOperatorWithoutValue,
    iconName: EIcon,
    label: string
  ) => {
    const { filterToApply } = getFilterToApply(operatorWithValue, operatorWithoutValue);

    return filterToApply && !getIsFilterPresent(filterToApply, activeFilters) ? (
      <Tooltip arrow title={label} placement="top">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            addAdvancedFilter(filterToApply);
          }}
          sx={{
            padding: '2px',
            '> .MuiSvgIcon-root': { fontSize: '14px' },
            '&:hover': {
              '> .MuiSvgIcon-root': {
                color: theme.palette.info[400]
              }
            }
          }}
        >
          <Icon htmlColor={theme.palette.secondary[500]} name={iconName} />
        </IconButton>
      </Tooltip>
    ) : (
      <></>
    );
  };

  const displayedValue = getDisplayedValue(value);

  const hasTooltip = typeof displayedValue === 'string' && displayedValue?.length > 20;

  return (
    <Tooltip
      title={hasTooltip && displayedValue}
      placement="left"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            background: theme.palette.secondary[600],
            width: '500px',
            maxHeight: '50vh',
            overflowY: 'auto',
            overflowX: 'hidden'
          }
        }
      }}
      enterDelay={1000}
      enterNextDelay={1000}
    >
      <StyledGeneralCellWithFilters component="div" tableSize={tableSize}>
        {displayedValue}
        <Stack className="filters">
          {renderFilterButton(
            EFilterOperatorWithValue.is,
            EFilterOperatorWithoutValue.notExists,
            EIcon.PlusCircle,
            t('common:tableCellFilters_is')
          )}
          {renderFilterButton(
            EFilterOperatorWithValue.isNot,
            EFilterOperatorWithoutValue.exists,
            EIcon.MinusCircle,
            t('common:tableCellFilters_is_not')
          )}
          <Tooltip arrow title={t('common:copyValue')} placement="top">
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                copyToClipboard(value, t);
              }}
              sx={{
                padding: '2px',
                '> .MuiSvgIcon-root': { fontSize: '14px' },
                '&:hover': {
                  '> .MuiSvgIcon-root': {
                    color: theme.palette.info[400]
                  }
                }
              }}
            >
              <Icon htmlColor={theme.palette.secondary[500]} name={EIcon.Copy} />
            </IconButton>
          </Tooltip>
        </Stack>
      </StyledGeneralCellWithFilters>
    </Tooltip>
  );
}

export default memo(GeneralCellWithFilters);

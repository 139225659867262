import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserKeyRecord')
export class UserKeyRecord {
  @JsonProperty('_id', String)
  _id: string | undefined;

  @JsonProperty('name', String)
  name: string | undefined;

  @JsonProperty('created_at', String)
  created_at: string | undefined;
}

import { Box, BoxProps, styled } from '@mui/material';

const StyledSingleBucketFeedback = styled(Box)<BoxProps>(({ theme }) => ({
  '.singleBucketFeedbackSection': {
    position: 'relative',
    width: '100%',
    minHeight: '178px',
    backgroundColor: theme.palette.secondary[1000],
    padding: '24px 0px'
  },

  '.arcannaAndFeedbackContainer': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '40px',
    verticalAlign: 'middle'
  },

  '.decisionLabelsWrapper': {
    width: '870px'
  },

  '.decisionLabelsWrapper > div': {
    marginBottom: '16px'
  },

  '.changeDecisionTxt': {
    display: 'block',
    marginBottom: '4px',
    color: theme.palette.secondary[200]
  },

  '.labelsContainer': {
    marginTop: '16px',
    borderTop: `1px solid ${theme.palette.secondary[700]}`,
    paddingTop: '16px',
    maxHeight: '400px',
    overflow: 'auto'
  },

  '.customLabel': {
    color: theme.palette.secondary[100],
    width: '160px',
    maxHeight: '36px',
    textAlign: 'center',
    border: `1px solid ${theme.palette.secondary[600]}`
  },
  '.moreLabels': {
    width: '160px',
    maxHeight: '36px'
  }
}));

export default StyledSingleBucketFeedback;

import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import axios from 'axios';
import { config } from '../../config';
import { REQUEST_STATUS_NOK } from '../../components/shared/hooks/useFetch';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { BucketGenerateSummaryRequest } from '../../components/shared/models/feedback/BucketGenerateSummaryRequest';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';

type TUseGenerateBucketSummary = {
  bucketId: string;
};
export default function useGenerateBucketSummary({ bucketId }: TUseGenerateBucketSummary) {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['feedback', 'common']);
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  return useMutation(
    (payload: BucketGenerateSummaryRequest) =>
      axios.post(config.api.generateBucketSummary.replace(':bucketId', bucketId), payload),
    {
      onSuccess: (response) => {
        const responseData = jsonConvert.deserializeObject(response.data.resource.request, CommonResponse);
        if (responseData.status === REQUEST_STATUS_NOK) {
          showErrorNotification(
            t('feedback:actionFailed'),
            `${t('feedback:bucketExpand.summarization.failedToGenerateSummaryMsg')} Message: ${responseData.reason}`
          );
          return;
        }
        showSuccessNotification(t('common:actionCompleted'), t('feedback:bucketExpand.summarization.successGenerateSummaryMsg'));
      },
      onError: () => {
        showErrorNotification(t('feedback:actionFailed'), t('feedback:bucketExpand.summarization.failedToGenerateSummaryMsg'));
      },
      onSettled: () => {
        if (bucketId) {
          queryClient.invalidateQueries([config.api.feedbackBucketDetails.replace(':bucketId', bucketId)]);
        }
      }
    }
  );
}

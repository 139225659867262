import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useJobInfo, useJobMetrics } from 'src/data-access';
import { JobInsightsTableEntry, useJobInsightsTableData } from '../../hooks/useJobInsightsTableData';
import { Table } from '@arcanna/generic';
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, PaginationState, useReactTable } from '@tanstack/react-table';
import { Stack } from '@mui/material';
import ConfusionMatrix from './ConfusionMatrix';
import { getColumns } from './JobAiModelInsights.utils';

type TJobAiModelInsightsProps = {
  jobId: number;
  modelId: string;
};

export function JobAiModelInsights({ jobId, modelId }: TJobAiModelInsightsProps) {
  const { t } = useTranslation(['overview']);
  const jobMetricsQuery = useJobMetrics(jobId, modelId);
  const tableData = useJobInsightsTableData({ jobId, modelId });
  const jobInfoQuery = useJobInfo(jobId);
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 10,
    pageIndex: 0
  });

  const columns = useMemo(
    () => getColumns(t, jobInfoQuery.data?.info?.advancedSettings.customLabels),
    [t, jobInfoQuery.data?.info?.advancedSettings.customLabels]
  );

  const tableInstance = useReactTable<JobInsightsTableEntry>({
    data: tableData,
    columns: columns,
    enableSorting: true,
    enableHiding: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableColumnPinning: false,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.label,
    state: {
      pagination
    },
    onPaginationChange: setPagination
  });

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <Table.component<JobInsightsTableEntry>
          tableInstance={tableInstance}
          isLoading={jobMetricsQuery.isLoading}
          noResultsMessageTitle={t('common:noResults')}
          noResultsMessageSubtitle={t('overview:nothing')}
          isTableResizable={false}
          defaultTableSize={Table.TABLE_SIZES.medium}
        />
        <Table.TablePagination<JobInsightsTableEntry> tableInstance={tableInstance} />
      </Stack>
      <ConfusionMatrix jobId={jobId} modelId={modelId} />
    </Stack>
  );
}

export default JobAiModelInsights;

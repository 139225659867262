import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';

@JsonObject('OidcAuthenticationExecuteResponse')
export class OidcAuthenticationExecuteResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('username', String)
  username?: string = undefined;

  @JsonProperty('access_token', String)
  access_token?: string = undefined;

  @JsonProperty('refresh_token', String)
  refresh_token?: string = undefined;
}

import { TFunction } from 'i18next';
import { TABLE_SIZES } from './TableSizeSelector.constants';

export const getTableSizeSelectorOptions = (t: TFunction) => [
  {
    label: t('common:table.tableSizeSmall'),
    value: TABLE_SIZES.small
  },
  {
    label: t('common:table.tableSizeMedium'),
    value: TABLE_SIZES.medium
  },
  {
    label: t('common:table.tableSizeLarge'),
    value: TABLE_SIZES.large
  }
];

import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BucketTrainingStatus } from 'src/constants/BucketTrainingStatus';
import { enumFromValue } from 'src/components/shared/utilities/enumFromValue';
import { useJobInfo } from 'src/data-access';
import ExpandedBucketNavigation from 'src/components/pages/Main/Jobs/Feedback/Flow/ExpandedBucketNavigation';
import { BucketTrainingStatusTag } from 'src/components/pages/Main/Jobs/Feedback/Flow/BucketTrainingStatusTag';
import { useFeedbackBucketDetails } from 'src/data-access/feedback/useFeedbackBucketDetails';
import StyledBucketDrawerHeader from './StyledBucketDrawerHeader.styles';

export type BucketDrawerHeaderProps = {
  jobId: number;
  bucketId: string;
  shortBucketId?: string;
};

function BucketDrawerHeader({ jobId, bucketId, shortBucketId }: BucketDrawerHeaderProps) {
  const { t } = useTranslation(['feedback']);
  const jobInfoQuery = useJobInfo(jobId);
  const feedbackBucketDetailsQuery = useFeedbackBucketDetails(jobId, bucketId);

  const bucketTrainingStatus = useMemo(() => {
    return feedbackBucketDetailsQuery.data?.bucket?.training_status
      ? enumFromValue(feedbackBucketDetailsQuery.data.bucket.training_status, BucketTrainingStatus)
      : null;
  }, [feedbackBucketDetailsQuery.data]);

  const bucketTrainingLabel = useMemo(() => {
    const training_label = feedbackBucketDetailsQuery.data?.bucket?.training_label;
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    return jobInfoQuery.data?.info.advancedSettings.customLabels.find((customLabel) => customLabel.id === training_label)?.name;
  }, [feedbackBucketDetailsQuery.data, jobInfoQuery.data]);

  return (
    <StyledBucketDrawerHeader flexGrow={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography className="title">
            {t('feedback:bucketExpand.headline')}&nbsp;<b>{shortBucketId ?? ''}</b>
          </Typography>
          <BucketTrainingStatusTag
            style={{ marginRight: 0 }}
            trainingStatus={bucketTrainingStatus}
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
            trainedWith={bucketTrainingLabel}
          />
        </Stack>
        <ExpandedBucketNavigation />
      </Stack>
    </StyledBucketDrawerHeader>
  );
}

export default BucketDrawerHeader;

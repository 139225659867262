import { CustomLabel } from 'src/components/shared/models';

class CustomLabelByJob {
  id: string;
  name: string;
  hexColor: string;
  jobIds?: number[];

  constructor(id: string, name: string, hexColor: string, jobIds?: number[]) {
    this.id = id;
    this.name = name;
    this.hexColor = hexColor;
    this.jobIds = jobIds;
  }

  static createfromCustomLabel(customLabel: CustomLabel, jobIds?: number[]): CustomLabelByJob {
    return new CustomLabelByJob(customLabel.id, customLabel.name, customLabel.hexColor, jobIds);
  }
}

export default CustomLabelByJob;

import { Stack, StackProps, alpha, styled } from '@mui/material';
import { TABLE_SIZES } from '../TableSizeSelector/TableSizeSelector.constants';

const StyledGeneralCellWithFilters = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'tableSize';
  }
})<StackProps & { tableSize: string }>(({ theme, tableSize }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: tableSize === TABLE_SIZES.large ? '2' : '1',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-all',
  position: 'relative',
  '.filters': {
    display: 'none'
  },

  '&:hover': {
    '.filters': {
      display: 'flex',
      height: '100%',
      flexDirection: 'row',
      gap: 0.25,
      alignItems: 'center',
      position: 'absolute',
      right: 0,
      top: 0,
      background: theme.palette.secondary[800],
      paddingLeft: '2px',
      boxShadow: `-4px 0px 40px 0px ${alpha(theme.palette.secondary[800], 0.75)}`
    }
  }
}));

export default StyledGeneralCellWithFilters;

import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('UserDeleteRequest')
export class UserDeleteRequest extends CommonRequest {
  @JsonProperty('username', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  username: string = undefined;

  constructor(username: string) {
    super();
    this.username = username;
  }
}

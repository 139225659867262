import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { UserRoleNamesRecord } from './UserRoleNamesRecord';

@JsonObject('UserRoleNamesResponse')
export class UserRoleNamesResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('entries', [UserRoleNamesRecord])
  entries?: UserRoleNamesRecord[] = [];
}

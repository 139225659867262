import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import ArcannaChip from '../../ArcannaChip';

type JobRetrainStateErrorTagProps = {
  message: string;
};

export function JobRetrainStateErrorTag({ message }: JobRetrainStateErrorTagProps) {
  const { t } = useTranslation('job');

  return (
    <div className="flex flex-align-center" style={{ gap: 8 }}>
      <ArcannaChip color="red">{t('job:jobRetrainState.retrainError')}</ArcannaChip>
      {message ? (
        <Tooltip title={message}>
          <WarningFilled className="color-nb-400" style={{ fontSize: 20 }} />
        </Tooltip>
      ) : null}
    </div>
  );
}

import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { UserRoleNamesResponse } from '../../components/shared/models/user/UserRoleNamesResponse';
import { UserRoleNamesRequest } from '../../components/shared/models/user/UserRolesNamesRequest';

export const useRoles = () => {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<UserRoleNamesResponse, Error>([config.api.roleNames], () =>
    axios
      .post<{ resource: UserRoleNamesResponse }>(config.api.roleNames, new UserRoleNamesRequest())
      .then((responseData: AxiosResponse<{ resource: UserRoleNamesResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, UserRoleNamesResponse)
      )
  );
};

import { useMemo } from 'react';
import { TFeedbackTableRowData } from '../../FeedbackTable.types';
import { Stack, Tooltip } from '@mui/material';
import { CustomLabel } from '@arcanna/components';
import { CustomLabel as CustomLabelType } from 'src/components/shared/models';
import { noDecisionResult, undecidedConsensus } from 'src/components/pages/Main/Jobs/helper';
import { Cell } from '@tanstack/react-table';
import CustomLabelByJob from '../../models/CustomLabelByJob';
import { useLabelContext } from '@arcanna/pages/Feedback/FeedbackContext/useLabelContext';

type TLabelColumnProps = {
  cell: Cell<TFeedbackTableRowData, string>;
  isDisplayedOnTwoRows: boolean;
  jobInfoCustomLabels: CustomLabelByJob[];
};

function getLabel(labelId: string, currentJobInfoLabels: CustomLabelByJob[]) {
  const value: CustomLabelType | undefined =
    labelId === undecidedConsensus.id
      ? undecidedConsensus
      : currentJobInfoLabels.find((customLabel) => customLabel.id === labelId);
  return value;
}

function getMatchingLabel(labelId: string, rowSelectedLabel: CustomLabelByJob | null, currentJobInfoLabels: CustomLabelByJob[]) {
  const value: CustomLabelType | undefined =
    labelId === undecidedConsensus.id
      ? undecidedConsensus
      : currentJobInfoLabels.find(
          (customLabel) =>
            // name and id must concide when a label is selected
            (customLabel.id === labelId && rowSelectedLabel != null && rowSelectedLabel.name == customLabel.name) ||
            // otherwise just displayed the new label retrieved by API (the one previously saved)
            (customLabel.id === labelId && rowSelectedLabel == null)
        );
  return value;
}

function LabelColumn({ cell, isDisplayedOnTwoRows, jobInfoCustomLabels }: TLabelColumnProps) {
  const selectedLabel = useLabelContext((context) => context.editLabelOnSelected);
  const prepareConfirm = useLabelContext((context) => context.prepareConfirm);
  const prepareClear = useLabelContext((context) => context.prepareClear);

  const row = cell.row;
  const rowSelectedLabel = row.getIsSelected() ? selectedLabel : null;
  const rowData = row.original;
  const labelId = rowData.label;
  const jobId = Number(rowData.job_id);
  const consensus = rowData.consensus;
  const currentJobInfoLabels = jobInfoCustomLabels.filter((customLabel) => {
    return customLabel.jobIds != undefined ? customLabel.jobIds.includes(jobId) : true;
  });
  const newLabel = useMemo(() => {
    if (prepareConfirm && row.getIsSelected()) {
      return getLabel(labelId, currentJobInfoLabels);
    }
    if (prepareClear && row.getIsSelected()) {
      return undefined;
    }
    const matchingLabel = getMatchingLabel(rowSelectedLabel?.id || '', rowSelectedLabel, currentJobInfoLabels);
    const consensusLabel = getLabel(consensus, currentJobInfoLabels);
    return matchingLabel || consensusLabel;
  }, [row, currentJobInfoLabels, rowSelectedLabel, consensus, labelId, prepareConfirm, prepareClear]);

  const hasConsensusOverwritten = Boolean(rowData.consensusOverwritten?.consensusOverwrittenBy);

  let oldValue: CustomLabelType | undefined = getLabel(labelId, currentJobInfoLabels);

  if (oldValue == null) {
    oldValue = noDecisionResult;
  }

  const hasNewValue = newLabel && newLabel !== oldValue && newLabel !== undefined;

  const isFeedbackSelected = useMemo(
    () => (Boolean(newLabel) && newLabel !== oldValue) || (prepareConfirm && selectedLabel?.id !== undefined),
    [newLabel, oldValue, prepareConfirm, selectedLabel]
  );

  const getContent = (width?: number) =>
    oldValue ? (
      <Stack
        direction={isDisplayedOnTwoRows ? 'column' : 'row'}
        alignItems={isDisplayedOnTwoRows ? 'flex-start' : 'center'}
        gap={1}
      >
        <CustomLabel
          name={oldValue.name}
          dataTestId="label-column-value"
          hexColor={oldValue.hexColor}
          disabled={isFeedbackSelected}
          strike={isFeedbackSelected}
          fontSize="12px"
          disableColorCircle={oldValue === noDecisionResult || oldValue.name === undecidedConsensus.name}
          isConfirmed={newLabel === oldValue}
          hasConsensusOverwritten={hasConsensusOverwritten && newLabel == oldValue}
          width={width && (hasNewValue && !isDisplayedOnTwoRows ? width / 2 : width)}
        />
        {hasNewValue && (
          <CustomLabel
            name={newLabel.name}
            dataTestId="new-label-column-value"
            disableColorCircle={newLabel === undecidedConsensus}
            hexColor={newLabel.hexColor}
            fontSize="12px"
            hasConsensusOverwritten={hasConsensusOverwritten}
            width={width && !isDisplayedOnTwoRows ? width / 2 : undefined}
          />
        )}
      </Stack>
    ) : (
      <></>
    );

  return oldValue ? (
    <Tooltip title={getContent()} enterDelay={1000} enterNextDelay={1000}>
      {getContent(cell.column.getSize() - 32)}
    </Tooltip>
  ) : null;
}

export default LabelColumn;

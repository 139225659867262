import { Tag, TagProps } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import style from './../FeedbackFlow.module.css';
import { BucketTrainingStatus } from '../../../../../../../constants/BucketTrainingStatus';
import { Tooltip } from '@mui/material';

type BucketBucketTrainingProps = {
  trainingStatus: BucketTrainingStatus | null | undefined;
  trainedWith: string;
};

const StateTagColorMap: Record<BucketTrainingStatus, string> = {
  [BucketTrainingStatus.NEW]: 'default',
  [BucketTrainingStatus.IN_MODEL]: 'success'
};

export function BucketTrainingStatusTag({
  trainingStatus: trainingStatus,
  trainedWith,
  ...props
}: BucketBucketTrainingProps & TagProps) {
  const { t } = useTranslation('feedback');
  const StateTagLabelMap: Record<BucketTrainingStatus, string> = {
    [BucketTrainingStatus.NEW]: t('stateTagLabelNew'),
    [BucketTrainingStatus.IN_MODEL]: t('trainingStatusTagInModel')
  };

  const StateTagTooltipMap: Record<BucketTrainingStatus, string> = {
    [BucketTrainingStatus.NEW]: t('trainingStatusTagTooltipNew'),
    [BucketTrainingStatus.IN_MODEL]: t('trainingStatusTagTooltipInModel', { trained_with: trainedWith })
  };

  return (
    <Tooltip arrow placement="left" title={trainingStatus ? StateTagTooltipMap[trainingStatus] : t('stateTagTooltipMissing')}>
      {trainingStatus ? (
        <Tag {...props} color={StateTagColorMap[trainingStatus]} className={style.tag}>
          {StateTagLabelMap[trainingStatus]}
        </Tag>
      ) : (
        <MinusOutlined />
      )}
    </Tooltip>
  );
}

export default BucketTrainingStatusTag;

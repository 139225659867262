import { useTheme } from '@mui/material';
import StyledLabelCircle from './StyledLabelCircle.styles';

export type LabelCircleProps = {
  hexColor: string;
  id?: string;
  isDisabled?: boolean;
  shouldDisableColorCircle?: boolean;
  onClick?: (id: string) => void;
  style?: React.CSSProperties;
};

function LabelCircle({ hexColor, isDisabled = false, shouldDisableColorCircle = false }: LabelCircleProps) {
  const theme = useTheme();
  const disabledColor = theme.palette.secondary[400].toString();
  const color = isDisabled ? disabledColor : hexColor;

  return (
    <StyledLabelCircle>
      {!shouldDisableColorCircle ? (
        <span
          className="root"
          style={{
            backgroundColor: `${color}4D`
          }}
        >
          <span
            className="point"
            style={{
              backgroundColor: color
            }}
          />
        </span>
      ) : null}
    </StyledLabelCircle>
  );
}

export default LabelCircle;

import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('OidcAuthenticationExecuteRequest')
export class OidcAuthenticationExecuteRequest {
  @JsonProperty('redirect_uri', String)
  redirect_uri?: string = undefined;

  @JsonProperty('state', String)
  state?: string = undefined;

  @JsonProperty('nonce', String)
  nonce?: string = undefined;

  constructor(redirect_uri: string, state: string, nonce: string) {
    this.redirect_uri = redirect_uri;
    this.state = state;
    this.nonce = nonce;
  }
}

import type { CheckboxOptionType, CheckboxValueType } from 'antd/es/checkbox/Group';
import { ARCANNA_NO_DECISION, ARCANNA_NO_DECISION_DISPLAY_VALUE } from '../../../../../JobEventExplorer/utils';
import { FeedbackBucketState } from '../../../../../../../constants';
import { useState } from 'react';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import { getCustomLabelsFromJobs } from '../SelectFeedback';

type useFiltersProps = {
  jobInfos: JobInfoResponse[];
  setDecisionFilters: (decisionFilters: string[]) => void;
  setConsensusFilters: (consensusFilters: string[]) => void;
};

export enum Filters {
  DECISION = 'decision',
  CONSENSUS = 'consensus'
}

export function useFilters({ jobInfos, setDecisionFilters, setConsensusFilters }: useFiltersProps) {
  const jobCustomLabels = getCustomLabelsFromJobs(jobInfos);
  const { t } = useTranslation(['feedback']);

  const [open, setOpen] = useState(false);

  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  const getAllCheckedList = (group_type: string = null) => {
    const checkedGroupList: CheckboxValueType[] = [];
    if (group_type === Filters.DECISION) {
      checkedGroupList.push(ARCANNA_NO_DECISION);
    } else if (group_type === Filters.CONSENSUS) {
      checkedGroupList.push(FeedbackBucketState.Undecided);
    }
    jobCustomLabels.forEach((item) => {
      checkedGroupList.push(item.id.toString());
    });
    return checkedGroupList;
  };

  const allCheckDecision = getAllCheckedList(Filters.DECISION);
  const allCheckConsensus = getAllCheckedList(Filters.CONSENSUS);

  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  const getGroupOptions = (group_type: string = null) => {
    const groupOptions: CheckboxOptionType[] = [];
    if (group_type === Filters.DECISION) {
      groupOptions.push({
        label: ARCANNA_NO_DECISION_DISPLAY_VALUE,
        value: ARCANNA_NO_DECISION
      });
    } else if (group_type === Filters.CONSENSUS) {
      groupOptions.push({
        label: t('feedback:bucketExpand:undecided'),
        value: FeedbackBucketState.Undecided
      });
    }
    jobCustomLabels.forEach((item) => {
      groupOptions.push({
        label: item.name,
        value: item.id
      });
    });
    return groupOptions;
  };

  const onChangeDecision = (list: CheckboxValueType[]) => {
    setDecisionFilters(list.map(String));
  };

  const onChangeConsensus = (list: CheckboxValueType[]) => {
    setConsensusFilters(list.map(String));
  };

  const onCheckAllDecision = (e: CheckboxChangeEvent) => {
    setDecisionFilters(e.target.checked ? allCheckDecision.map(String) : []);
  };

  const onCheckAllConsensus = (e: CheckboxChangeEvent) => {
    setConsensusFilters(e.target.checked ? allCheckConsensus.map(String) : []);
  };

  const handleClearAll = () => {
    setConsensusFilters([]);
    setDecisionFilters([]);
    setOpen(!open);
  };

  return {
    open,
    setOpen,
    getGroupOptions,
    onChangeDecision,
    onChangeConsensus,
    onCheckAllDecision,
    onCheckAllConsensus,
    handleClearAll
  };
}

import { FeedbackBatchBucketColumn } from 'src/components/shared/models/feedback/FeedbackBatchBucketColumn';
import { FeedbackBatchBucketRow } from 'src/components/shared/models/feedback/FeedbackBatchBucketRow';

export type BucketFeedbackRow = {
  rowId: string;
  jobId: number;
  label: string;
  relabeled?: string;
  result?: string;
};

/*
  Creates a map with the  <rowId, BucketFeedbackRow>. It will expand previousBucketsMap.
  rowId is kept for backwards compatibility with the old antd table.
  Needs to be replaces simply by the bucket id (column.name == 'id') in BucketFeedbackRow.
*/
export function splitBucketRowsByJobId(
  previousBucketsMap: Map<string, BucketFeedbackRow>,
  editLabel: string,
  sourceData: FeedbackBatchBucketRow[]
) {
  const newEditLabels = new Map<string, BucketFeedbackRow>(previousBucketsMap);
  sourceData.forEach((feedback: FeedbackBatchBucketRow) => {
    let jobId = 0;
    let result: string | undefined;
    let relabeled: string | undefined;
    let bucketId: string | undefined;
    if (feedback.columns != undefined) {
      feedback.columns.forEach((column: FeedbackBatchBucketColumn) => {
        if (column.name == 'job_id') {
          jobId = Number(column.value);
        }
        if (column.name == 'label') {
          result = column.value;
        }
        if (column.name == 'relabeled') {
          relabeled = column.value;
        }
        if (column.name == 'id') {
          bucketId = column.value;
        }
      });
    }
    const rowId = feedback.row_id ? feedback.row_id : bucketId;
    if (rowId) {
      newEditLabels.set(rowId, {
        rowId: rowId,
        jobId: Number(jobId),
        label: editLabel,
        relabeled: relabeled,
        result: result
      });
    }
  });
  return newEditLabels;
}

import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { AccuracyPerModelRequest } from '../../../components/shared/models/impact-dashboard/AccuracyPerModelRequest';
import { AccuracyPerModelResponse } from '../../../components/shared/models/impact-dashboard/AccuracyPerModelResponse';
import { getJsonConvert } from '../../../components/shared/utilities/json-convert';
import { config } from '../../../config';

type UseAccuracyPerModel = {
  jobIds: number[];
  startDate: Date;
  endDate: Date;
};
export function useAccuracyPerModel({ jobIds, startDate, endDate }: UseAccuracyPerModel) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const isoStartDate = startDate.toISOString();
  const isoEndDate = endDate.toISOString();

  return useQuery(
    [config.api.jobStatisticsModelAccuracy, { startDate: isoStartDate, endDate: isoEndDate, jobIds }],
    () =>
      axios
        .post<{
          resource: AccuracyPerModelResponse;
        }>(config.api.jobStatisticsModelAccuracy, new AccuracyPerModelRequest(jobIds, isoStartDate, isoEndDate))
        .then((responseData: AxiosResponse<{ resource: AccuracyPerModelResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, AccuracyPerModelResponse)
        ),
    {
      enabled: Boolean(jobIds.length > 0 && startDate && endDate)
    }
  );
}

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CustomLabel } from '../../../../shared/models/job/AdvancedJobSettings';
import { PeriodOptions } from '../../../../shared/models/filters/period';
import { useStartDateByPeriod } from '../../../../shared/hooks/useStartDateByPeriod';

export type JobOverviewPeriod = 'last24h' | 'last7days' | 'last30days' | 'alltime';

// eslint-disable-next-line
export const JobOverviewContext = createContext<JobOverviewContextType>(null as any);

export type JobOverviewStateType = {
  loading: boolean;
  period: PeriodOptions;
  startDate: Date;
  intialisedSelectedLabels: boolean;
  selectedLabels: CustomLabel[];
  editTimeMetrics: boolean;
};

export type JobOverviewContextType = {
  state: JobOverviewStateType;
  setPeriod: (period: PeriodOptions) => void;
  setSelectedLabels: (selectedLabels: CustomLabel[]) => void;
  setEditTimeMetrics: (editTimeMetrics: boolean) => void;
};

const JobOverviewInitialState: JobOverviewStateType = {
  loading: false,
  period: PeriodOptions.LAST_24H,
  startDate: new Date(Date.now() - 24 * 3600 * 1000),
  intialisedSelectedLabels: false,
  selectedLabels: [],
  editTimeMetrics: false
};

// eslint-disable-next-line
export function JobOverviewProvider(props: any) {
  const [state, setState] = useState<JobOverviewStateType>(JobOverviewInitialState);

  const setPeriod = useCallback((period: PeriodOptions) => {
    setState((current: JobOverviewStateType) => ({
      ...current,
      period
    }));
  }, []);

  const startDate = useStartDateByPeriod(state.period);
  useEffect(
    function setStartDateByPeriod() {
      setState((current: JobOverviewStateType) => ({
        ...current,
        startDate
      }));
    },
    [startDate]
  );

  const setSelectedLabels = useCallback((selectedLabels: CustomLabel[]) => {
    setState((current: JobOverviewStateType) => ({
      ...current,
      intialisedSelectedLabels: true,
      selectedLabels
    }));
  }, []);

  const setEditTimeMetrics = useCallback((editTimeMetrics: boolean) => {
    setState((current: JobOverviewStateType) => ({
      ...current,
      editTimeMetrics
    }));
  }, []);

  const value: JobOverviewContextType = useMemo(
    () => ({
      state,
      setPeriod,
      setSelectedLabels,
      setEditTimeMetrics
    }),
    [state, setPeriod, setSelectedLabels, setEditTimeMetrics]
  );

  const { children } = props;
  return <JobOverviewContext.Provider value={value}>{children}</JobOverviewContext.Provider>;
}

export function useJobOverviewContext(): JobOverviewContextType {
  return useContext(JobOverviewContext);
}

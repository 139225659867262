import { useTranslation } from 'react-i18next';
import { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { Row, Col, Form, Input, Checkbox, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserHook } from './User.hook';
import Button from '../../../shared/components/Button/Button';
import { UserRoleNamesRecord } from '../../../shared/models/user/UserRoleNamesRecord';
import { getJsonConvert } from '../../../shared/utilities/json-convert';
import { useAddUser, useRoles } from '../../../../data-access';
import style from './User.module.css';
import cx from 'classnames';
import { UserAddRequest } from '../../../shared/models/user/UserAddRequest';

export function UserAdd() {
  const { t } = useTranslation(['common', 'user']);
  const { Option } = Select;
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
  const [passwordGenerated, setPasswordGenerated] = useState<string>(null);
  const [passwordLabel, setPasswordLabel] = useState<string>('Password');
  const [userRoleNames, setUserRoleNames] = useState<UserRoleNamesRecord[]>([]);
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 23 }
  };

  const { navigateToList } = useUserHook();
  const addUserMutation = useAddUser();

  const rolesQuery = useRoles();

  useEffect(() => {
    if (rolesQuery.data !== undefined) {
      // @ts-expect-error TS(2345): Argument of type 'UserRoleNamesRecord[] | undefine...
      setUserRoleNames(rolesQuery.data.entries);
    }
  }, [rolesQuery.data, jsonConvert]);

  const generatePassword = useCallback(() => {
    const length = 8 + Math.random() * 6;
    const charset = 'abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789~!@#$%^&*()|[]{}';
    let password = '';
    for (let i = 0, n = charset.length; i < length; i += 1) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    setPasswordGenerated(password);
    formRef.current.setFieldsValue({
      currentPassword: password
    });
    setPasswordLabel('Password (this is shown only once)');
  }, []);

  const handleUserAdd = useCallback(
    // eslint-disable-next-line
    (values: any) => {
      const request = new UserAddRequest();
      request.username = values.currentUsername;
      request.password = values.currentPassword;
      request.email = values.currentEmail;
      request.display_name = values.currentDisplayname;
      request.force_password_change = values.forcePasswordChange;
      request.roles = values.roles;
      addUserMutation.mutate(request, {
        onSuccess: navigateToList
      });
    },
    [addUserMutation, navigateToList]
  );

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <h2 className={cx([style.headline, 'm-b-40 headline'])}>{t('user:addNewUser')}</h2>
        </Col>
        <Col className="flex flex-align-center">
          <Button
            title={t('common:back')}
            type="secondaryNew"
            size="small"
            icon={<FontAwesomeIcon icon="arrow-left" />}
            onClick={() => navigateToList()}
          />
        </Col>
      </Row>
      <Form
        {...formItemLayout}
        form={form}
        layout="vertical"
        initialValues={{ forcePasswordChange: true }}
        onFinish={handleUserAdd}
      >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Form.Item
                  label={t('user:username')}
                  labelCol={{ span: 24 }}
                  name="currentUsername"
                  rules={[{ required: true, message: t('common:required') }, { min: 5 }]}
                >
                  <Input maxLength={100} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Form.Item
                  label={t('user:displayName')}
                  labelCol={{ span: 24 }}
                  name="currentDisplayname"
                  rules={[{ required: true, message: t('common:required') }, { min: 5 }]}
                >
                  <Input maxLength={100} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Form.Item
                  label={t('user:email')}
                  labelCol={{ span: 24 }}
                  name="currentEmail"
                  rules={[
                    { required: true, message: t('common:required') },
                    { type: 'email', message: t('common:validEmail') }
                  ]}
                >
                  <Input maxLength={100} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Form.Item
                  label={t('user:selectRole')}
                  labelCol={{ span: 24 }}
                  name="roles"
                  rules={[{ required: true, message: t('common:required') }]}
                >
                  <Select mode="multiple" style={{ width: '100%' }} placeholder={t('user:selectRole')} size="large">
                    {userRoleNames.map((role: UserRoleNamesRecord) => (
                      <Option key={role.id} value={role.id}>
                        {role.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Form.Item
                  label={passwordLabel}
                  labelCol={{ span: 24 }}
                  name="currentPassword"
                  rules={[{ required: true, message: t('common:required') }, { min: 8 }]}
                >
                  {(() => {
                    if (!passwordGenerated) {
                      return (
                        <Button type="secondaryNew" size="middle" onClick={generatePassword}>
                          <span>{t('user:generatePassword')}</span>
                        </Button>
                      );
                    }

                    return <Input />;
                  })()}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={8} className="p-r-10">
                <Form.Item labelCol={{ span: 24 }} name="forcePasswordChange" valuePropName="checked">
                  <Checkbox>{t('user:forcePasswordChange')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={4} className="p-r-10">
            <Button
              type="primaryNew"
              disabled={passwordGenerated == null || addUserMutation.isLoading}
              className="m-t-60"
              size="middle"
              htmlType="submit"
            >
              <span>{t('common:create')}</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

import * as React from 'react';
import { useQuery } from 'react-query';
import { JobInfoResponse } from '../../components/shared/models/job/JobInfoResponse';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { fetchJobInfo } from './useJobInfo';
import { CloneTask } from '../../components/shared/models/job/JobCloningStatus';
import { JobStateEnum } from '../../components/shared/models';

const BULK_UPDATE_REFRESH_INTERVAL_SECONDS = 10;
const MILLISECONDS_FACTOR = 1000;

class CloningStatus {
  progress: number;
  jobStatus: string;
  constructor(progress: number, jobStatus: string) {
    this.progress = progress;
    this.jobStatus = jobStatus;
  }
}

type UseCalculateCloneProgressProps = {
  jobId: number;
};
export function useCalculateCloneProgress({ jobId }: UseCalculateCloneProgressProps) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobInfoResponse, Error, CloningStatus | null>(
    [config.api.jobInfo.replace(':id', `${jobId}`) + '-calculate-progress'],
    () => fetchJobInfo(jobId, jsonConvert),
    {
      // @ts-expect-error TS(2322): Type '(cloningStatus: CloningStatus) => number | f...
      refetchInterval: (cloningStatus: CloningStatus) => {
        if (cloningStatus?.jobStatus === JobStateEnum.CLONING) {
          return BULK_UPDATE_REFRESH_INTERVAL_SECONDS * MILLISECONDS_FACTOR;
        }
        return false;
      },
      select: (data: JobInfoResponse) => {
        let totalProgress = 0;
        let nr_of_cloning_operations = 0;
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        Object.values(data.info.status.cloningStatus).forEach((cloneTask: CloneTask) => {
          if (cloneTask.progress === null) {
            return;
          } else {
            nr_of_cloning_operations += 1;
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            totalProgress += cloneTask.progress;
          }
        });
        if (!nr_of_cloning_operations) {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          return new CloningStatus(0, data.info.status.state);
        }

        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return new CloningStatus(totalProgress / nr_of_cloning_operations, data.info.status.state);
      },
      enabled: Boolean(jobId)
    }
  );
}

import { SVGProps } from 'react';

export type DeleteArrowIconProps = SVGProps<SVGSVGElement>;

export function DeleteArrowIcon({ ...props }: DeleteArrowIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        /* eslint-disable-next-line max-len */
        d="M11.3333 5.99996L7.33333 9.99996M7.33333 5.99996L11.3333 9.99996M1.81333 8.63996L4.69333 12.48C4.928 12.7928 5.04533 12.9493 5.19403 13.0621C5.32574 13.1621 5.47488 13.2366 5.63385 13.282C5.81334 13.3333 6.00889 13.3333 6.4 13.3333H11.4667C12.5868 13.3333 13.1468 13.3333 13.5746 13.1153C13.951 12.9236 14.2569 12.6176 14.4487 12.2413C14.6667 11.8134 14.6667 11.2534 14.6667 10.1333V5.86663C14.6667 4.74652 14.6667 4.18647 14.4487 3.75864C14.2569 3.38232 13.951 3.07636 13.5746 2.88461C13.1468 2.66663 12.5868 2.66663 11.4667 2.66663H6.4C6.00889 2.66663 5.81334 2.66663 5.63385 2.71789C5.47488 2.76329 5.32574 2.83786 5.19403 2.93779C5.04533 3.05063 4.928 3.20707 4.69333 3.51996L1.81333 7.35996C1.6412 7.58946 1.55514 7.70422 1.52196 7.83025C1.49268 7.94149 1.49268 8.05842 1.52196 8.16967C1.55514 8.2957 1.6412 8.41045 1.81333 8.63996Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

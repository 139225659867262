import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ELinkGeneratorPages } from '../RedirectLinkGenerator.types';
import {
  constructExplorerBucketIdRedirectURL,
  constructExplorerEventIdRedirectURL,
  constructFeedbackBucketIdRedirectURL
} from '../RedirectLinkGenerator.utils';
import { Spinner } from '@arcanna/generic';
import { useEventBucketId } from '../hooks/useEventBucketId';

type TRedirectDIFromJobIdAndEventIdProps = {
  pageId: ELinkGeneratorPages;
  eventId: string;
  jobId: string;
};

function RedirectDIFromJobIdAndEventId({ pageId, eventId, jobId }: TRedirectDIFromJobIdAndEventIdProps) {
  const history = useHistory();

  const { bucketId } = useEventBucketId(eventId, jobId);

  useEffect(() => {
    if (!bucketId) {
      return;
    }

    if (pageId === ELinkGeneratorPages.feedbackWithBucketId) {
      return history.replace(constructFeedbackBucketIdRedirectURL({ jobId: jobId, bucketId }));
    }

    if (pageId === ELinkGeneratorPages.explorerWithBucketId) {
      return history.replace(constructExplorerBucketIdRedirectURL({ jobId: jobId, bucketId }));
    }

    if (pageId === ELinkGeneratorPages.explorerWithEventId) {
      return history.replace(constructExplorerEventIdRedirectURL({ jobId: jobId, eventId }));
    }
  }, [pageId, jobId, history, bucketId, eventId]);

  return <Spinner isOverlay />;
}

export default RedirectDIFromJobIdAndEventId;

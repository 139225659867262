import { useFeatureSelectionForTransfer } from '../../FeatureSelection/hooks/useFeatureSelectionForTransfer';
import { useEffect, useState } from 'react';

type useActiveDecisionPointsProps = {
  jobId: number;
};

export default function useActiveDecisionPoints({ jobId }: useActiveDecisionPointsProps) {
  const featureSelectionSummaryQuery = useFeatureSelectionForTransfer({
    jobId
  });

  const [activeDecisionPoints, setActiveDecisionPoints] = useState([]);

  useEffect(() => {
    if (featureSelectionSummaryQuery.data) {
      // eslint-disable-next-line
      setActiveDecisionPoints((featureSelectionSummaryQuery.data as any).filter((feature: any) => feature.active));
    }
  }, [featureSelectionSummaryQuery.data]);

  return { activeDecisionPoints, loading: featureSelectionSummaryQuery.isLoading };
}

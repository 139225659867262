import { InlineNotification } from '@arcanna/components';
import { useRetrainState } from '../../../../../../../data-access';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { isRetrainInProgress } from '../../../Retrain/Retrain.utils';
import { EJobTrainingState } from '../../../../../../shared/models/job/EJobTrainingState';
import { useJobNotificationsContext } from '../JobNotifications.context';
import { showInfoNotification } from '../../../../../../shared/utilities/notification';
import { JobCategory } from '../../../helper';

type TTrainingNotificationProps = {
  jobId: number;
  jobCategory?: number;
};

export function TrainingNotification({ jobId, jobCategory }: TTrainingNotificationProps) {
  const { t } = useTranslation(['job']);

  const [hasTrainingFinished, setHasTrainingFinished] = useState<boolean>(false);
  const [hasTrainingStarted, setHasTrainingStarted] = useState<boolean>(false);
  const retrainStateQuery = useRetrainState({ jobId, retrainMutationStatus: 'none' });
  const { setBucketsModelUpdateStarted } = useJobNotificationsContext();

  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'boolean | "" | undefined' is not...
    if (isRetrainInProgress(retrainStateQuery?.data) && !hasTrainingStarted) {
      setHasTrainingStarted(true);
    }
    if (retrainStateQuery?.data === EJobTrainingState.FINISHED && hasTrainingStarted && !hasTrainingFinished) {
      setHasTrainingFinished(true);
      setBucketsModelUpdateStarted(true);
      if (jobCategory === JobCategory.DECISION_INTELLIGENCE) {
        showInfoNotification(t('job:bucketUpdater.started'), t('job:bucketUpdater.modelUpdateDescription'));
      }
    }
    // eslint-disable-next-line
  }, [retrainStateQuery.data]);

  if (hasTrainingStarted && !hasTrainingFinished) {
    return <InlineNotification type="loading" title={t('job:notifications:trainingInProgress')} />;
  }
  return null;
}

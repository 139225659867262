export function SearchIcon({ color }: { color?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line
        d="M4.6998 10.5C4.6998 7.29675 7.29655 4.7 10.4998 4.7C13.7031 4.7 16.2998 7.29675 16.2998 10.5C16.2998 13.7033 13.7031 16.3 10.4998 16.3C7.29655 16.3 4.6998 13.7033 4.6998 10.5ZM10.4998 3.3C6.52335 3.3 3.2998 6.52355 3.2998 10.5C3.2998 14.4764 6.52335 17.7 10.4998 17.7C12.2359 17.7 13.8286 17.0855 15.072 16.0622L18.5048 19.495C18.7782 19.7683 19.2214 19.7683 19.4948 19.495C19.7681 19.2216 19.7681 18.7784 19.4948 18.505L16.062 15.0722C17.0853 13.8288 17.6998 12.2361 17.6998 10.5C17.6998 6.52355 14.4763 3.3 10.4998 3.3Z"
        fill="#8592A2"
        stroke={color ? color : 'var(--secondary-color-nb-300)'}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

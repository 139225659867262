import { BucketDrawerContext } from './BucketDrawerContext';
import { useContext } from 'react';
import { TBucketDrawerContext } from './BucketDrawerContext.type';

export function useBucketDrawerContext(): TBucketDrawerContext {
  const context = useContext(BucketDrawerContext);

  if (context === undefined) {
    throw new Error('useBucketDrawerContext must be used within BucketDrawerProvider');
  }

  return context;
}

import { FeedbackQuickFilterMetadata } from 'src/components/shared/models/feedback/FeedbackQuickFilterMetadata';
import Button from 'src/components/shared/components/Button/Button';
import { DeleteArrowIcon } from 'src/themes/icons/DeleteArrowIcon';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { Checkbox, TCheckboxState } from '@arcanna/generic';
import { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';

type QuickFiltersGroupProps = {
  quickFiltersList: FeedbackQuickFilterMetadata[];
  filterGroup: string;
  onChangeHandler: (selectedValues: string[] | string | undefined) => void;
  groupSelectedOption: string[];
  showDeleteIcon: boolean;
  stateHandler?: () => void;
  isMultipleChoice: boolean;
  checkAllHandler?: (shouldCheckAll: boolean) => void;
};

export function QuickFiltersGroup({
  quickFiltersList,
  filterGroup,
  onChangeHandler,
  groupSelectedOption,
  showDeleteIcon,
  stateHandler,
  isMultipleChoice,
  checkAllHandler
}: QuickFiltersGroupProps) {
  const [checkAll, setCheckAll] = useState(false);
  const { t } = useTranslation(['feedback']);

  const qfGroup = useMemo(() => {
    return quickFiltersList?.filter((q_filter: FeedbackQuickFilterMetadata) => q_filter.filter_group === filterGroup)[0];
  }, [quickFiltersList, filterGroup]);

  const qfOptions = useMemo(() => {
    return qfGroup?.filters?.map((filter) => ({ value: filter, label: t(`feedback:quickFilters.${filter}`) })) ?? [];
  }, [qfGroup, t]);

  const handleCheckAllChange = useCallback(
    (state: TCheckboxState) => {
      checkAllHandler?.(state === 'checked');
      setCheckAll(state === 'checked');
    },
    [checkAllHandler]
  );

  if (qfGroup.filter_group !== undefined) {
    return (
      <>
        <h4 className={styles.quickFiltersTitle}>
          {isMultipleChoice ? (
            <Box height={'22px'}>
              <Checkbox
                label={t(`feedback:quickFilters:${qfGroup.filter_group}`)}
                state={checkAll ? 'checked' : 'default'}
                onChange={handleCheckAllChange}
              />
            </Box>
          ) : (
            <span>{t(`feedback:quickFilters:${qfGroup.filter_group}`)}</span>
          )}
          {showDeleteIcon ? (
            <Button
              type="secondaryNew"
              style={{ border: 'none', padding: '2px 0 0 0', background: 'transparent' }}
              size="small"
              icon={<DeleteArrowIcon stroke={styles.deleteArrow} />}
              dataTestId="remove-applied-filter"
              title="Remove applied filter"
              onClick={() => stateHandler?.()}
            />
          ) : null}
        </h4>
        <Checkbox.Group
          onChange={onChangeHandler}
          defaultValues={groupSelectedOption}
          isMultipleChoice={isMultipleChoice}
          options={qfOptions}
        />
      </>
    );
  }

  return <></>;
}

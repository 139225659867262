import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('UserAddRequest')
export class UserAddRequest extends CommonRequest {
  @JsonProperty('username', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  username: string = undefined;

  @JsonProperty('password', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  password: string = undefined;

  @JsonProperty('force_password_change', Boolean)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'boolea...
  force_password_change: boolean = undefined;

  @JsonProperty('email', String)
  email?: string = undefined;

  @JsonProperty('display_name', String)
  display_name?: string = undefined;

  @JsonProperty('roles', Array<string>())
  roles?: Array<string> = undefined;
}

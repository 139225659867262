import { TablePaginationConfig } from 'antd';
import { useFeedbackUnifiedContext } from '@arcanna/pages/FeedbackUnified/FeedbackUnifiedProvider/FeedbackUnifiedProvider';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useCallback } from 'react';
import { FeedbackBatchBucketRow } from 'src/components/shared/models/feedback/FeedbackBatchBucketRow';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import { useParams } from 'react-router-dom';
import { FeedbackUpdateRecord } from 'src/components/shared/models/feedback/FeedbackUpdateRecord';
import { FeedbackUpdateRequest } from 'src/components/shared/models/feedback/FeedbackUpdateRequest';
import useUpdateBucketFeedback from 'src/data-access/feedback/useUpdateBucketFeedback';
import { ARCANNA_NO_DECISION } from 'src/components/pages/JobEventExplorer/utils';
import { TFeedbackTableRowData } from '@arcanna/pages/Feedback/FeedbackTable/FeedbackTable.types';
import { Table } from '@tanstack/react-table';
import { getMappedSortDirection } from 'src/components/pages/Main/Jobs/Feedback/Flow/helper';
import { useLabelContext } from '@arcanna/pages/Feedback/FeedbackContext/useLabelContext';

function useFeedbackUnified() {
  const feedbackState = useFeedbackUnifiedContext((context) => context.state);
  const setIsLoading = useFeedbackUnifiedContext((context) => context.setLoading);
  const setTablePage = useFeedbackUnifiedContext((context) => context.setTablePage);
  const setTablePageSize = useFeedbackUnifiedContext((context) => context.setTablePageSize);
  const setSelectedRowKeys = useFeedbackUnifiedContext((context) => context.setSelectedRowKeys);
  const setSelectedRows = useFeedbackUnifiedContext((context) => context.setSelectedRows);
  const setSelectedFeedback = useFeedbackUnifiedContext((context) => context.setSelectedFeedback);
  const setReloadFeedbackData = useFeedbackUnifiedContext((context) => context.setReloadFeedbackData);
  const setTableSort = useFeedbackUnifiedContext((context) => context.setTableSort);
  const setTableFilters = useFeedbackUnifiedContext((context) => context.setTableFilters);
  const setEditAll = useFeedbackUnifiedContext((context) => context.setEditAll);
  const { editLabelOnSelected, setEditLabelOnSelected } = useLabelContext((context) => context);

  const { ids } = useParams<{ ids: string }>();
  const jobIds = ids.split(',').map((id: string) => Number(id));

  const rowSelection = {
    selectedRowKeys: feedbackState.selectedRowKeys,
    selectedRows: feedbackState.selectedRows,
    // eslint-disable-next-line
    onChange: (selectedRowKeysChanged: any, selectedRowsChanged: any) => {
      setSelectedRowKeys(selectedRowKeysChanged);
      setSelectedRows(selectedRowsChanged);
      setSelectedFeedback(selectedRowsChanged);
    },
    // eslint-disable-next-line
    getCheckboxProps: (record: any) => ({
      disabled: record.enabled !== 'true'
    })
  };

  const getFilterSource = useCallback((name: string, filters: FeedbackFiltersFieldsRecord[]) => {
    const associatedFilter = filters.find((filter) => filter.name === name || filter.source === name);
    if (associatedFilter != null) {
      return associatedFilter.source;
    }
    return '';
  }, []);

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<FeedbackBatchBucketRow> | SorterResult<FeedbackBatchBucketRow>[]
    ) => {
      let sortValues: SorterResult<FeedbackBatchBucketRow>;
      setTableFilters(filters);

      if (Array.isArray(sorter)) {
        [sortValues] = sorter;
      } else {
        sortValues = sorter;
      }

      let sortField = sortValues.columnKey?.toString();
      if (sortField != 'timestamp' && sortField !== undefined) {
        sortField = getFilterSource(sortField, feedbackState.filtersFields);
      }

      if (sortField != null && sortValues.order != null) {
        setTableSort({
          column: sortField,
          order: getMappedSortDirection(sortValues.order)
        });
      }

      if (pagination.current != null && pagination.pageSize != null) {
        setTablePage(pagination.current);
        setTablePageSize(pagination.pageSize);
      }
      setReloadFeedbackData(true);
    },
    [
      setTableSort,
      setReloadFeedbackData,
      setTablePage,
      setTablePageSize,
      setTableFilters,
      getFilterSource,
      feedbackState.filtersFields
    ]
  );

  const updateBucketFeedbackMutation = useUpdateBucketFeedback(undefined, jobIds[0]);

  const getFeedbackUpdateRecordsPerJob = useCallback(
    (rowData: TFeedbackTableRowData[], confirmResult = false, skipNotRelabeled = false) => {
      // update records split by job_id (number)
      const feedbackUpdateRecords = new Map<number, FeedbackUpdateRecord[]>();
      for (const jobId of jobIds) {
        feedbackUpdateRecords.set(jobId, []);
      }

      for (const feedbackRow of rowData) {
        if ((feedbackRow.relabeled == null || feedbackRow.relabeled == '') && skipNotRelabeled) {
          continue;
        }

        const bucketId = feedbackRow.id;
        const jobId = Number(feedbackRow.job_id);

        let newLabel = null;
        const selectedLabelId = editLabelOnSelected?.id;
        const selectedLabelName = editLabelOnSelected?.name;
        if (confirmResult) {
          newLabel = feedbackRow.result;
        } else if (selectedLabelId != undefined) {
          // detect if matching label id and label name, otherwise don't save
          const matchingLabel = feedbackState.jobCustomLabels.find(
            (customLabelByJob) =>
              customLabelByJob.jobIds?.includes(jobId) &&
              customLabelByJob.id == selectedLabelId &&
              customLabelByJob.name == selectedLabelName
          );
          if (matchingLabel !== undefined) {
            newLabel = selectedLabelId;
          }
        }

        if (newLabel != null && newLabel != ARCANNA_NO_DECISION) {
          feedbackUpdateRecords.get(Number(feedbackRow.job_id))?.push(new FeedbackUpdateRecord(bucketId, newLabel));
        }
      }
      return feedbackUpdateRecords;
    },
    [jobIds, feedbackState.jobCustomLabels, editLabelOnSelected]
  );

  const reloadTable = useCallback(() => {
    setIsLoading(false);
    setEditAll(false);
    setReloadFeedbackData(true);
    setSelectedFeedback([]);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  }, [setIsLoading, setEditAll, setReloadFeedbackData, setSelectedFeedback, setSelectedRows, setSelectedRowKeys]);

  const allLabelsOnChangeConfirm = useCallback(
    (tableInstance: Table<TFeedbackTableRowData>) => {
      setIsLoading(true);
      const rowData = tableInstance.getSelectedRowModel().rows.map((row) => row.original);
      const feedbackUpdateRecords = getFeedbackUpdateRecordsPerJob(rowData);

      Promise.all(
        jobIds.map(async (jobId) => {
          const currentJobFeedbackRecords = feedbackUpdateRecords.get(jobId);
          if (currentJobFeedbackRecords && currentJobFeedbackRecords.length > 0) {
            await updateBucketFeedbackMutation.mutateAsync(new FeedbackUpdateRequest(jobId, currentJobFeedbackRecords));
          }
        })
      ).finally(() => {
        reloadTable();
      });
    },
    [setIsLoading, getFeedbackUpdateRecordsPerJob, updateBucketFeedbackMutation, jobIds, reloadTable]
  );

  const allLabelsOnChangeCancel = useCallback(
    (tableInstance: Table<TFeedbackTableRowData>) => {
      tableInstance.resetRowSelection();
      setEditLabelOnSelected(null);
    },
    [setEditLabelOnSelected]
  );

  return {
    rowSelection,
    reloadTable,
    handleTableChange,
    getFilterSource,
    allLabelsOnChangeConfirm,
    allLabelsOnChangeCancel
  };
}

export default useFeedbackUnified;

import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { TopJobsRequest } from '../../../components/shared/models/impact-dashboard/TopJobsRequest';
import { TopJobsResponse } from '../../../components/shared/models/impact-dashboard/TopJobsResponse';
import { getJsonConvert } from '../../../components/shared/utilities/json-convert';
import { config } from '../../../config';

type UseTopJobs = {
  startDate: Date;
  endDate: Date;
};
export function useTopJobs({ startDate, endDate }: UseTopJobs) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const isoStartDate = startDate.toISOString();
  const isoEndDate = endDate.toISOString();

  return useQuery([config.api.jobStatisticsTopJobs, { startDate: isoStartDate, endDate: isoEndDate }], () =>
    axios
      .post<{ resource: TopJobsResponse }>(config.api.jobStatisticsTopJobs, new TopJobsRequest(isoStartDate, isoEndDate))
      .then((responseData: AxiosResponse<{ resource: TopJobsResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, TopJobsResponse)
      )
  );
}

import { Box, BoxProps, styled } from '@mui/material';

const StyledAIExplainabilityBody = styled(Box)<BoxProps>(({ theme }) => ({
  '.customLabelHeader .MuiTypography-subtitle1': {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600
  },

  '.tableHeaderCell': {
    width: '40%',
    verticalAlign: 'top',
    minHeight: '405px'
  },

  '.currentBucketHeader': {
    lineHeight: '36px',
    paddingLeft: '8px'
  },

  '.currentBucketLabelWrapper': {
    padding: '20px 0px',
    borderTop: `1px solid ${theme.palette.secondary[700]}`
  },

  '.similarBucketLabelWrapper': {
    padding: '20px 0px'
  },

  '.featureName': {
    color: theme.palette.secondary[300],
    fontWeight: 500
  },

  '.featureValue': {
    color: theme.palette.secondary[100],
    fontWeight: 400
  }
}));

export default StyledAIExplainabilityBody;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Modal, Tooltip } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useUserKeyContext } from './UserKey.context';
import { getJsonConvert } from '../../../shared/utilities/json-convert';
import { useFetch } from '../../../shared/hooks/useFetch';
import { getBackendEndpoint } from '../../../shared/utilities/api';
import { UserKeyCreateRequest } from '../../../shared/models/user-key/UserKeyCreateRequest';
import { UserKeyCreateResponse } from '../../../shared/models/user-key/UserKeyCreateResponse';
import { showErrorNotification, showSuccessNotification } from '../../../shared/utilities/notification';
import { UserKeyListRequest } from '../../../shared/models/user-key/UserKeyListRequest';
import { UserKeyListResponse } from '../../../shared/models/user-key/UserKeyListResponse';
import { config } from '../../../../config';
import { UserKeyDeleteRequest } from '../../../shared/models/user-key/UserKeyDeleteRequest';
import { UserKeyRecord } from '../../../shared/models/user-key/UserKeyRecord';
import { UserKeyDeleteResponse } from '../../../shared/models/user-key/UserKeyDeleteResponse';
import { permissions } from '../../../shared/static/ComponentsPermissions';
import PermissionsCheck from '../../../shared/components/Permissions/Permissions';

export function useUserKeyHook() {
  const {
    state: userKeyState,
    setTableData,
    setInitialTableData,
    setReloadUserKeyData,
    setUserKeyCreateResponse: setUserKeyCreate
  } = useUserKeyContext();

  const { t } = useTranslation(['common', 'user']);
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const history = useHistory();

  const [userKeyCreateErrorMessage, setUserKeyCreateErrorMessage] = useState('');

  const {
    post: postCreate,
    loading: loadingCreateUserKey,
    error: errorCreateUserKey
  } = useFetch({
    path: getBackendEndpoint(config.api.userKeyCreate),
    initialResponseData: null,
    load: false
  });

  const handleUserKeyCreate = useCallback(
    // eslint-disable-next-line
    (values: any) => {
      const request = new UserKeyCreateRequest();
      request.name = values.name;
      postCreate(request)
        // eslint-disable-next-line
        .then((userKeyCreateResponseData: any) => {
          const userKeyCreate = jsonConvert.deserializeObject(userKeyCreateResponseData.data.resource, UserKeyCreateResponse);
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          if (userKeyCreate.request.status === 'OK') {
            showSuccessNotification(t('common:actionCompleted'), t('common:createdSuccessfully'));
            setUserKeyCreate(userKeyCreate);
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          } else if (userKeyCreate.request.status === 'NOK') {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            setUserKeyCreateErrorMessage(userKeyCreate.request.reason);
          }
        })
        .catch(() => {
          setUserKeyCreateErrorMessage(errorCreateUserKey.resource?.request?.reason || t('user:userKey.createdError'));
        });
    },
    // eslint-disable-next-line
    [postCreate, setUserKeyCreateErrorMessage, t, jsonConvert, setUserKeyCreate]
  );

  const navigateToCreate = useCallback(() => {
    const route = config.routes.userKeyCreate;
    history.push(route);
  }, [history]);

  const navigateToList = useCallback(() => {
    const route = config.routes.userKey;
    history.push(route);
  }, [history]);

  const {
    responseData: userKeyDeleteResponseData,
    post: postDeleteUserKey,
    error: errorDelete
  } = useFetch({
    path: getBackendEndpoint('/user-key/delete'),
    initialResponseData: null,
    load: false
  });

  const getDeleteModalContent = () => <div>{t('common:confirmDelete')}</div>;

  const onDeleteUserKey = useCallback(
    (id: string) => {
      postDeleteUserKey(new UserKeyDeleteRequest(id));
    },
    [postDeleteUserKey]
  );

  useEffect(() => {
    if (userKeyDeleteResponseData !== null) {
      const reportDeleteResponse = jsonConvert.deserializeObject(userKeyDeleteResponseData.resource, UserKeyDeleteResponse);
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (reportDeleteResponse.request.status === 'OK') {
        showSuccessNotification(t('common:actionCompleted'), t('common:deletedSuccessfully'));
        setTimeout(() => {
          setReloadUserKeyData(true);
        }, 1000);
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      } else if (reportDeleteResponse.request.status === 'NOK') {
        showErrorNotification(
          t('common:actionCompleted'),
          `${t('reporting:deleteFailed')} Message: ${
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            reportDeleteResponse.request.reason
          }`
        );
      }
    }
  }, [userKeyDeleteResponseData, setReloadUserKeyData, jsonConvert, t]);

  const getUserKeyColumns = () => [
    {
      title: t('user:userKey.keyName'),
      width: 250,
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: t('user:userKey.createdAt'),
      width: 100,
      key: 'created_at',
      dataIndex: 'created_at'
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 50,
      render: (item: UserKeyRecord) => (
        <div>
          <Tooltip key={`user-key-tooltip-delete-${item._id}`} placement="top" title={t('common:delete')} className="m-l-6">
            <PermissionsCheck
              permissions={[permissions.userKeyDelete]}
              action={permissions.action.disabledAction}
              testId={`user-key-delete-${item.name}`}
            >
              <Button
                className="pointer color-danger"
                icon={<DeleteOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: t('common:confirmationNeeded'),
                    icon: <ExclamationCircleOutlined />,
                    content: getDeleteModalContent(),
                    onOk() {
                      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
                      onDeleteUserKey(item._id);
                    },
                    // eslint-disable-next-line
                    onCancel() {}
                  });
                }}
              />
            </PermissionsCheck>
          </Tooltip>
        </div>
      )
    }
  ];

  const {
    responseData: responseDataSummary,
    post: postSummary,
    loading: loadingSummary
  } = useFetch({
    path: getBackendEndpoint(config.api.userKeyList),
    initialResponseData: null,
    load: false
  });

  useEffect(() => {
    if (userKeyState.reloadUserKeyData) {
      postSummary(new UserKeyListRequest());
      setReloadUserKeyData(false);
    }
  }, [postSummary, userKeyState.reloadUserKeyData, setReloadUserKeyData]);

  useEffect(() => {
    if (responseDataSummary !== null) {
      const userSummaryResponse = jsonConvert.deserializeObject(responseDataSummary.resource, UserKeyListResponse);
      const { entries } = userSummaryResponse;
      // @ts-expect-error TS(2345): Argument of type 'UserKeyRecord[] | undefined' is ...
      setInitialTableData(entries);

      // @ts-expect-error TS(2345): Argument of type 'UserKeyRecord[] | undefined' is ...
      setTableData(entries);
    }
  }, [responseDataSummary, jsonConvert, setInitialTableData, setTableData]);

  useEffect(() => {
    if (!errorDelete) {
      return;
    }
    showErrorNotification(t('common:actionCompleted'), errorDelete.resource.request.reason);
    setReloadUserKeyData(true);
    // eslint-disable-next-line
  }, [t, jsonConvert, errorDelete]);

  return {
    navigateToCreate,
    navigateToList,
    handleUserKeyCreate,
    loadingSummary,
    loadingCreateUserKey,
    userKeyCreateErrorMessage,
    errorCreateUserKey,
    getUserKeyColumns
  };
}

import { JsonObject, JsonProperty } from 'json2typescript';
import { FeedbackUpdateRecord } from './FeedbackUpdateRecord';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('FeedbackUpdateRequest')
export class FeedbackUpdateRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number;

  @JsonProperty('updates', [FeedbackUpdateRecord])
  updates?: FeedbackUpdateRecord[] = [];

  constructor(job_id: number, updates: FeedbackUpdateRecord[]) {
    super();
    this.job_id = job_id;
    this.updates = updates;
  }
}

const palette = {
  main: {
    navyBlue: {
      0: '#fff',
      100: '#eee',
      200: '#ddd',
      300: '#ccc',
      400: '#999',
      500: '#666',
      600: '#595959',
      700: '#444',
      800: '#333',
      900: '#1a1a1a',
      1000: '#000000'
    },
    turquoise: {
      100: '#dafcf7',
      200: '#c2fbf2',
      300: '#85f6e5',
      400: '#f16e00',
      500: '#00d9b7',
      600: '#00c5a3',
      700: '#00b18f',
      800: '#009d7b',
      900: '#008967'
    },
    ceruleanBlue: {
      100: '#ebecfa',
      200: '#ccd0f3',
      300: '#adb3eb',
      400: '#8f97e4',
      500: '#707bdd',
      600: '#525ed5',
      700: '#3342ce',
      800: '#2b38af',
      900: '#242e90'
    }
  },
  utilitarian: {
    blue: {
      100: '#dbeafe',
      200: '#bfdbfe',
      300: '#93c5fd',
      400: '#60a5fa',
      500: '#3b82F6',
      600: '#444',
      700: '#1d4ed8',
      800: '#1e40af',
      900: '#1e3a8a'
    },
    green: {
      0: '#d1fae5', // not used, I think it can be removed from figma
      100: '#a7f3d0',
      200: '#6ee7b7',
      300: '#34d399',
      400: '#10b981',
      500: '#059669',
      600: '#047857',
      700: '#065F46',
      800: '#064e3b',
      900: '#113838'
    },
    orange: {
      100: '#fef3c7',
      200: '#fde68a',
      300: '#fcd34d',
      400: '#fbbf24',
      500: '#f59e0b',
      600: '#d97706',
      700: '#b45309',
      800: '#92400e',
      900: '#78350f'
    },
    red: {
      100: '#ffe4e6',
      200: '#fecdd3',
      300: '#fda4af',
      400: '#fb7185',
      500: '#f43f5e',
      600: '#e11d48',
      700: '#be123c',
      800: '#9f1239',
      900: '#881337'
    }
  },
  base: {
    white: '#ffffff',
    black: '#000000',

    success: '#10b981',
    info: '#2563eb',
    warning: '#e97826',
    error: '#ed0a2c',

    accent: '#cb0aed'
  }
};

export default palette;

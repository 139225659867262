import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('FeedbackOverwriteRequest')
export class FeedbackOverwriteRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number;

  @JsonProperty('entry_id', String)
  entry_id?: string;

  @JsonProperty('label', String)
  label?: string;

  @JsonProperty('revert', Boolean)
  revert?: boolean;

  constructor(job_id: number, entry_id: string, label: string, revert: boolean) {
    super();
    this.job_id = job_id;
    this.entry_id = entry_id;
    this.label = label;
    this.revert = revert;
  }
}

import { Checkbox, Space } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxOptionType, CheckboxValueType } from 'antd/es/checkbox/Group';
import styles from './styles.module.css';

type FiltersCheckboxGroupProps = {
  checkAllTitle: string;
  handleCheckAll: (e: CheckboxChangeEvent) => void;
  checkedAllState: boolean;
  handleCheckGroup: (list: CheckboxValueType[]) => void;
  checkboxGroupOptions: CheckboxOptionType[];
  checkedGroupList: CheckboxValueType[];
  indeterminate: boolean;
};

export function FiltersCheckboxGroup({
  checkAllTitle,
  handleCheckAll,
  checkedAllState,
  handleCheckGroup,
  checkboxGroupOptions,
  checkedGroupList,
  indeterminate
}: FiltersCheckboxGroupProps) {
  return (
    <Space direction={'vertical'}>
      <Checkbox
        className={styles.checkboxGroupTitle}
        indeterminate={indeterminate}
        onChange={handleCheckAll}
        checked={checkedAllState}
      >
        {checkAllTitle}
      </Checkbox>
      <Checkbox.Group className={'m-l-8'} value={checkedGroupList} onChange={handleCheckGroup}>
        <Space direction={'vertical'}>
          {checkboxGroupOptions.map((option) => (
            <Checkbox className={styles.checkboxGroupText} key={`group-item-${option.value}`} value={option.value}>
              {option.label}
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    </Space>
  );
}

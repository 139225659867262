import { JsonObject, JsonProperty } from 'json2typescript';
import { UserUpdateRecord } from './UserUpdateRecord';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('UserUpdateRequest')
export class UserUpdateRequest extends CommonRequest {
  @JsonProperty('updates', [UserUpdateRecord])
  updates?: UserUpdateRecord[] = [];

  constructor(updates: UserUpdateRecord[]) {
    super();
    this.updates = updates;
  }
}

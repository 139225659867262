import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserRoleNamesRecord')
export class UserRoleNamesRecord {
  @JsonProperty('id', String)
  id: string | undefined;

  @JsonProperty('name', String)
  name: string | undefined;

  @JsonProperty('hierarchy', Number)
  hierarchy: number | undefined;
}

import FeedbackPageContent from '@arcanna/pages/Feedback/FeedbackPageContent';
import FeedbackSharedContextProvider from './FeedbackContext/FeedbackSharedContextProvider';

function Feedback() {
  return (
    <FeedbackSharedContextProvider>
      <FeedbackPageContent />
    </FeedbackSharedContextProvider>
  );
}

export default Feedback;
